.modal-form{
    border-radius: 10px;
    max-width: 600px ;
}

.modal-form .modal-title{
    /*padding-bottom: 30px !important;
    position: relative;*/
}

.modal-sub-title{

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #A4A4A4;
    text-align: center;

    margin-top: 1em;
    margin-bottom: 3em!important;
}

.buttons-selection{
    width: 100%;
    justify-content: center;
    margin: 0px !important;
}


.button-select{
    width: 100%;
    padding-bottom: 95%;
    position: relative;
    display: flex;
    justify-content: center;
    border: 2px solid #00CD98!important;
    box-sizing: border-box;
    border-radius: 20px!important;


}

.button-select-content{
    position: absolute;
    top: 15%;
}
.border-none{
    border: none!important;
}

.transition{
    transition: all 0.5s ease;
}


.buttons-selection-group{
    width: 100%;
}


.image-content{
    display: block;
    margin: 30px auto;
}


.continue,.continue:focus, .continue:hover{


    background: #0047FF;
    border: none;
    color: white!important;
    border-radius: 8px;
    padding: 6px 15px;
    margin: 4em auto 0 auto;
    display: block;
    outline: none!important;
    transition: all 1s ease-in-out;
    cursor: pointer;
}

.continue a{
    color: white!important;
}

.text-content{
    text-align: center;
    font-size: 18px;
}


.form-input{
    background: #E1E4ED;
    border-radius: 8px;

}
.label-question{
    width: 100%;
    text-align: center;


    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    color: #A4A4A4;

    padding-top: 2em;


}

.back-button{
    position: absolute;
    left: 16%;
    top: 2%;
}


.input-answer{
    width: calc(100% - 1.3em);
}


.input-answer-delete{
    font-size: 1.3em;
    margin-left: 0.5em;
    position: relative;
    top:  0.7em;
}

.icon{
    margin-top:30%;
}

/*.continue:hover{*/

/*    background: linear-gradient(90deg, #9A4F91 0%, #76236C 102.04%);*/
/*    border: none;*/
/*    color: white;*/
/*    border-radius: 8px;*/

/*}*/

.modal-form .modal-form-content .ant-select-selection-item,
.modal-form .modal-form-content .ant-select-selection-placeholder
{
    margin-top: 5px;
}

.modal-form .modal-form-content .arrow-select{
    position: relative;
    top: 15px !important;
}


.modal-form  .slick-list{
    height: auto!important;    
}