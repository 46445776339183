



/*CSS PARA DASHBOARD DE VENTAS --------------------------------------------------------------------------*/

.ventas-dashboard-ctn .card-bienvenido{
	background: transparent;
	background-image: url("/images/bgBienvenido.png"); 
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 25px;
}


.ventas-dashboard-ctn .card-bienvenido .ant-card-body{
    padding: 0px;
}

.ventas-dashboard-ctn .card-bienvenido .main-dashboard-title{
	font-weight: bold;
	font-size: 30px;
	color: #333539;
}

.ventas-dashboard-ctn .card-bienvenido .card-text{
	font-weight: 600;
	font-size: 15px;
	color: #333539;
    margin-bottom: 1rem;
}


.ventas-dashboard-ctn  .sub-title{
	margin: 0px;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 0.166667px;
	color: #333539;
	margin: 1rem 0;
}

.ventas-dashboard-ctn .btn-verMas{
	height: 30px;
	width: 100px;
	background: #A9C6FF;
	border-radius: 9px;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
}

.ventas-dashboard-ctn .btn-verMas span{
	color: white;
	margin-right: 5px;
	margin-bottom: 2px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.062857px;
}

.ventas-dashboard-ctn .card-pie,
.ventas-dashboard-ctn .card-calendario-sm{
	border-radius: 10px;
	padding: 0.5rem;
}

.ventas-dashboard-ctn .card-calendario-sm .card-title{
	margin: 0;
	font-weight: bold;
	font-size: 20px;
	line-height: 16px;
	letter-spacing: 0.146429px;
	color: #333539;
}

.cardCalendario .badge-calendario,
.cnt-calendario .badge-calendario{
    position: absolute;
    top: 16px;
    height: 10px;
    left: 40%;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-radio-group{
    display: none;
}

.card-calendario-sm  .ant-picker-calendar-header {
   padding: 0 !important;
   border-radius: 10px;
   background: #A9C6FF;
   margin: 0.5rem 0.5rem 1rem 0.5rem;
   display: flex;
   justify-content: center;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-select{
    background: #456EFF;
    height: 32px;
    width: 110px;
    border: none;
    border-radius: 10px;
    color: white;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-select .ant-select-selection-item{
    text-align: center;
    line-height: 29px !important;
    padding: 0 !important;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-select .ant-select-selector{
    background: transparent !important;
    height: 31px !important;
    min-height: unset !important;
    border: none;
}

.card-calendario-sm  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background: #456EFF;
    border-radius: 5px;
}

.card-calendario-sm  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border-radius: 5px;
    border-color: #456EFF;
}

.card-calendario-sm .ant-select-arrow{
	color: white;
}

/*CSS DE LOS CARD DE EVENTOS*/
.card-evento{
    border-radius: 10px;
    margin-bottom:5px ;
    margin :0 0 0.5rem 0;
}
.card-evento.error{
    border-left: solid 10px #E8523F;
}
.card-evento.warning{
    border-left: solid 10px #faad14;
}
.card-evento.success{
    border-left: solid 10px #52c41a;
}

.card-evento h2{
    margin:0px;
    padding: 0px;
    margin-bottom: 10px;
    
}

.card-evento .btn-opcion-menuDot,
.card-evento .btn-opcion-menuDot:hover,
.card-evento .btn-opcion-menuDot:active,
.card-evento .btn-opcion-menuDot:focus
{
    color:#4D4D4D;
    margin-right: 10px;
    border: none;
}

.card-evento p{
    margin:0px;
    padding: 0px;
    margin-bottom: 6px;
}

.card-evento .ant-card-body
{
    padding: 10px;
}


/*CSS PARA CRUD DE PROSPECTOS --------------------------------------------------------------------------*/


.span-estatus,
.span-proyecto-blue,
.span-proyecto{
    min-width: 120px;
    padding: 1px 8px;
    border-radius: 20px;
    color: white;
    line-height: 22px;
}

.span-proyecto{
    background-color: #18BF3D; 
}

.span-proyecto-blue{
    background-color: #0047FF;
    margin-left: 1rem;
}


/*CSS PARA MODAL DE PROSPECTOS --------------------------------------------------------------------------*/
.form-info-prospectos label,
.form-info-prospectos .text-label{
    font-size: 13px;
    color: #A4A4A4;
}

.form-info-prospectos .text-label-bold{
    font-weight: bold;
    font-size: 13px;
    color: #A4A4A4;
}

.form-info-prospectos .text-info{
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

/*CSS PARA LA VISTA DE LOS MENSAJES DE WHATSAPP --------------------------------------------------------------------------*/

.mensajes-ctn .radio-chat{
    width: 100%;
    margin: 0 1rem 1.5rem 0;
}

.radio-chat  {
    width: 65%;
    max-width: 400px;
    margin: auto;
    margin: 20px auto;
}

.radio-chat .ant-radio-button-wrapper:first-child{
    border-radius: 10px 0px 0px 10px;
}
.radio-chat .ant-radio-button-wrapper:last-child{
    border-radius: 0px 10px 10px 0px;
}
 
.radio-chat .ant-radio-button-wrapper{
    width: 50%;
    text-align: center;
    height: 40px;
    padding-top: 3px;
    font-size: 12pt;
    background: #f4f8fb;
    border: solid 1.5px #CAD7DF;;
}


.radio-chat .ant-radio-button-wrapper:hover{
    color: #737588;


}


.radio-chat .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    background: #fff;
    color: #555;
    border-color: #CAD7DF;
}

.radio-chat .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #CAD7DF;
}

.radio-chat .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    border-color: #CAD7DF !important;
}

.radio-chat  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color : #CAD7DF !important;
}

.mensajes-ctn .col-users .div-user-cards{
    max-height: 700px;
    overflow-y: auto;
    padding-left: 1rem; 
}

.mensajes-ctn .card-mensaje .ant-card-body{
    padding: 0px;
}

.mensajes-ctn .card-mensaje{
    border-radius: 10px;
    margin: 0 1rem 1.5rem 0;
    box-shadow: 0px 15px 28px rgba(25, 29, 56, 0.16);
}

.mensajes-ctn .card-mensaje .row-top{
    margin: 1rem 1rem 0.5rem 1rem;
    height: 50px;
}

.mensajes-ctn .card-mensaje .row-bottom{
    margin: 0.5rem 1rem 1rem 1rem;
    height: 50px;
}

.mensajes-ctn .card-mensaje .card-mensaje-br{
    background-color: #E6EDF1;
    border-top: solid 1px #E6EDF1;
    width: 100%;
}

.mensajes-ctn .card-chat .ant-card-body {
    padding: 1rem;
}

.mensajes-ctn .card-chat{
    border-radius: 10px;
    height: 700px;
}

.mensajes-ctn .card-chat .chat-title{
    font-weight: 600;
    font-size: 22px;
    color: #161A35;
}

.mensajes-ctn  .card-chat .col-body-chat{
    height: 520px;
    overflow-y: auto; 
}

.mensajes-ctn .comment-chat .ant-comment-content-author{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 0 12.5% 
}

.mensajes-ctn .comment-chat .col-avatar{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
}

.mensajes-ctn .comment-chat .ant-comment-content-author-name{
    font-weight: bold;
    font-size: 14px;
    color: #737588;
}

.mensajes-ctn .comment-chat .ant-comment-content-author-time{
    font-weight: normal;
    font-size: 12px;
    text-align: right;
    color: #737588;
}

.mensajes-ctn .mensajes-opciones{
    height: 70px;
    margin: 1rem 2rem;
    border-radius: 10px;
    box-shadow: 0px 15px 28px rgba(25, 29, 56, 0.16);
}

.mensajes-ctn .mensajes-opciones .mensajes-opciones-btns{
    display: flex;
    align-items: center;
    justify-content: center;
}

.mensajes-ctn .mensajes-opciones .ant-input{
    border: none;
}

.mensajes-ctn .mensajes-opciones .mensajes-opciones-btns .ant-btn{
    width: 60px;
    margin-left: 0.2rem;
    border: none;
}


/*---------------------------------RESPONSIVE-----------------------------------*/
@media(max-width: 576px)   {
    .mensajes-ctn .mensajes-opciones{
        margin: 1rem 0;
    }
}