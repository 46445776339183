/*CSS PARA LOS CARDS DE EL DASHBOARD DE PRESUPUESTOS*/
.presupuesto-ctn {
	/*overflow-x: auto;
	scroll-behavior: smooth;*/
}

.presupuesto-ctn .ant-table-has-fix-left{
	width: max-content;
}

.presupuesto-ctn .card{
	box-shadow: 0px 5px 25px rgba(25, 29, 56, 0.16);
}

.presupuesto-ctn .card.med{
	min-height: 150px;
	text-align: center;
}

.presupuesto-ctn .card.sm{
	min-height: 66px;
}

.presupuesto-ctn .card img{
	width: 100%;
}

.presupuesto-ctn .card.med .card-title{
	font-weight: 600;
	font-size: 24px;
	color: #858997;
	display: block !important;
}
.presupuesto-ctn .card.med .card-data{
	font-weight: bold;
	font-size: 20px;
	color: #FFB800;
	display: block !important;
	padding: 1.4rem 1rem 1rem 1rem;
}

.presupuesto-ctn .card.sm .ant-row{
	width: 100%;
}

.presupuesto-ctn .card.sm .card-title{
	font-weight: normal;
	font-size: 17px;
	color: #858997;
}
.presupuesto-ctn .card.sm .card-data{
	font-weight: normal;
	font-size: 17px;
	color: #BD30FF;
}

.presupuesto-ctn .card-grafica{
	min-height: 60vh;
}


/*CSS PARA LA TABLA DE PRESUPUESTOS*/

.presupuesto-table .ant-table{
	background: transparent;	
}

.super-scroll{
	min-width: 1000px;
}

.text-blue,
.presupuesto-ctn .text-blue,
.presupuesto-proforma-ctn .text-blue{
	color: #0047FF !important;
	font-weight: bold;
}
.presupuesto-ctn .text-green,
.presupuesto-proforma-ctn .text-green{
	color: #009420;
	font-weight: bold;
}

.presupuesto-ctn .text-greenLigth,
.presupuesto-proforma-ctn .text-greenLigth{
	color: #00FF75;
}

.presupuesto-ctn .text-yellow,
.presupuesto-proforma-ctn .text-yellow,
.presupuesto-proforma-ctn .card input{
	color: #9C9F0C;
}

.presupuesto-ctn .text-purple,
.presupuesto-proforma-ctn .text-purple{
	color: #9E00FF;
}

.presupuesto-table .ant-table-thead > tr > th, 
.presupuesto-table .ant-table-cell-fix-left, 
.presupuesto-table .ant-table-cell-fix-right{
	background: #f0f2f5
}

.presupuesto-table .ant-table-thead > tr > th {
	border: none; 
}

.presupuesto-table .ant-table-cell{
	padding: 0;
}

.presupuesto-table .ant-table-tbody{
	font-weight: 500;
	font-size: 16px;
}

.presupuesto-table .ant-table-cell span{
	text-transform: none;
	display: block;
	background: white;
	border-radius: 5px;
	padding: 5px 7px;
	margin: 3px 7px;
	text-align: center;
	min-height: 35px;
}

/*FOOTER TABLA*/
.presupuesto-table .ant-table-footer{
	padding: 0;
	background: none;
}

.presupuesto-ctn .table-footer{
	min-height: 30px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.presupuesto-ctn .table-footer span{
	background: white;
	border-radius: 5px;
	padding: 5px 7px;
	margin: 3px 7px;
	text-align: center;
	min-height: 35px;
	font-size: 16px;
	box-shadow: 0px 5px 25px rgba(25, 29, 56, 0.16);
}

/*CCS PARA PRESUPUESTO PROFIRMA*/

.presupuesto-proforma-ctn .card{
	padding: 5px 7px;
	height: 35px;
	margin:5px 7px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

}

.presupuesto-proforma-ctn .card-img{
	padding: 5px 7px;
	padding: 1rem 0;
	text-align: center;
	min-height: 35px;
	margin:3px 7px;
	background: white;
	border-radius: 10px;
}

.presupuesto-proforma-ctn .footer-list .card{
	height: 40px;
	font-size: 20px;
}

.presupuesto-proforma-ctn .ant-row{
	min-width: 700px;
}

.presupuesto-proforma-ctn .scrll{
	overflow: auto;
	scroll-behavior: smooth;
}

.presupuesto-proforma-ctn .card input{
	text-align: center;
}

.presupuesto-proforma-ctn .ant-input-number{
	border: none;
}

.hover-effect-all:hover * {
	background: #bfbfbf !important;
	cursor: pointer;
}

.hover-effect-all:hover *{
	color: white !important;
}

.hover-effect-all-2:hover *{
	background: #bfbfbf !important;
	color: white !important;
}

.presupuesto-ctn .hover-info{
	border: solid 1px red;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
