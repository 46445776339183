.tabs-proveedor .ant-tabs-tab-active{
	font-weight: bold !important;
}

.tabs-proveedor .ant-tabs-tab-btn{
    font-size: 20px;
    color: #0047FF !important;
    font-weight: lighter !important;
}

.scroll-proveedores{
	max-height: 80vh;
	overflow-x: auto;
	margin-top: 0px !important;
}

.tag-proyecto{
	border-radius: 10px;
	padding: 0 10px;
	color:  white;
	max-width: 100%;
	margin: 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.tag-proyecto-small{
	font-size: 9px !important;
	border-radius: 10px;
	padding: 0 10px;
	color:  white;
}

.div-row{
	display: flex;
}

.div-obj{
	/*padding: 0 5px 10px 5px;*/
	
}

.div-row .div-key{
	width: 25%;
	border-bottom: solid 1px black;
	background-color: #90A7F8;
	border-right: solid 1px black;
	border-left: solid 1px black;
	padding-left: 0.5rem;
	min-width: 120px;
}

.div-title{
	width: 100%;
	background-color:#0047FF;
	color:  white;
	border-bottom: solid 1px black;
	padding-left: 1rem;
}

.div-string{
	border-bottom: solid 1px black;
	border-right: solid 1px black;
	width: 75%;
	padding-left: 1rem;
	min-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-height: 200px;
}

.factura-xml-table{
	border-left: solid 1px black;
	border-right: solid 1px black;
	border-bottom: solid 1px black;
}

.table-container{
	width: 100%;
	overflow: auto;
	max-height: 500px;
}
.table-container thead th { position: sticky; top: 0; z-index: 1; }

.factura-xml-table th{
	text-transform: capitalize;
	padding-right: 2rem;
	border-right: solid 1px black;
	border-bottom: solid 1px black;
	background-color: #cacaca;
}

.factura-xml-table td{
	text-transform: capitalize;
	border-right: solid 1px black;
	border-bottom: solid 1px black;
}

.hr{
	border-bottom: solid 1px #dbdbdb;
}

.dropdown-filtro {
	color: #0047ff;
	margin: 0;
}

.dropdown-filtro:hover{
	cursor: pointer;
}

.dropdown-filtro svg {
	fill:  #0047ff;
	margin: 0 0 2px 10px;
}

.select-filtro .ant-select-selector{
	border-color: #426bd7 !important;
	min-width: 200px;

}

.select-filtro .ant-select-selection-placeholder,
.select-filtro .ant-select-arrow{
	color:  #426bd7 !important;
}

/*-----------------CSS PARA EL MODALFACTURA-----------------------------*/

.radio-group-modal{
	width: 100%;
	margin-bottom: 5rem;
	padding-top: 100px;
}

.radio-group-modal .radio-btn-modal{
	height: 200px;
	width: 200px;
	border-radius: 20px !important;
	border:solid 3px #909091; 
}

.radio-group-modal .radio-btn-inner{
	display: flex !important;
	flex-direction: column !important;
	align-items: center;
	justify-content: center;
	height: 200px;
	width: 200px;
	margin-left: -17px;
	position: relative;
	padding-bottom: 2rem;
}

.radio-group-modal .radio-btn-inner .radio-btn-text{
	position: absolute;
	bottom: 8%;
	font-weight: 600;
	font-size: 16px;
	color: #909091;
}

.radio-group-modal .ant-radio-button-wrapper-checked{
	background: linear-gradient(180deg, #0047FF 0%, rgba(0, 71, 255, 0.71) 100%);
	border:  none !important;
}

.radio-group-modal .ant-radio-button-wrapper-checked .radio-btn-inner svg,
.radio-group-modal .ant-radio-button-wrapper-checked .radio-btn-inner span{
	fill: white;
	color: white !important;
	
}
.scroll-modal{
	height: 552px;
	overflow-y: auto;
}


.btn-reportes{
	padding: 0 !important;
	color: white !important;
	background: transparent !important;
	width: 130px;
	height: 40px;
	line-height: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;

}
.btn-reportes img{
	margin-left: 10px;
}

.menu-dropdown{
	padding-top: 0px;
	padding-bottom: 20px;
	min-width: 230px;
	border-radius: 5px;
}

.menu-dropdown .header-dropdown{
	min-height: 100px;
	padding-top: 0.5rem;
	background-color: rgba(0, 71, 255, 0.07);
}

.menu-dropdown .header-dropdown .ant-typography{
	font-weight: bold;
	color: #0047ff;
}

.menu-dropdown .header-dropdown .ant-btn{
	font-weight: bold;
	background-color: #0047ff;
	color: white;
	border: none;
	
	border-radius: 10px;
}

.menu-dropdown .ant-dropdown-menu-submenu-title{
	display: flex;
	justify-content: center;
	padding:22px 12px 0 12px;
}

.menu-dropdown .ant-dropdown-menu-submenu-expand-icon{
	display: none;
}

.menu-dropdown  .ant-dropdown-menu-title-content{
	background: rgba(0, 71, 255, 0.07);
	max-width: 150px !important;
	display: block;
	border-radius: 10px;
	text-align: center;
	color: #0047ff;
	height: 25px;
	line-height: 25px;
}