.pInfo.document{


    height: 90px;
    min-width: 200px;
    overflow: auto;
    /* border-left: 1px solid rgba(0,0,0,0.2);
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.2); */
    padding-left: 11px;

}

.link-document{
    display: block;
    margin-bottom: 5px;
    font-size: 13px!important;
    letter-spacing: 1px;
    font-weight: 400;

}

.icon-element {
    position: relative;
    left: 10px;
    top: 5px;
}

.icon-element svg{
    width: 14px!important;
 
}

.btn-add-propiedad,
.btn-add-propiedad:hover,
.btn-add-propiedad:focus{
    background: #096DD9;
    border: 1px solid #096DD9;
    box-sizing: border-box;
    border-radius: 2px;
    color:  white;
}

.btn-add-propiedad *{
    color:  white;
}



@media (min-width: 1200px) and (max-width: 1420px) {

    .card-proyeccionesDetalle .pInfo {
        font-size: 12px !important;
        letter-spacing: 1px !important;
    }    
}


