.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after
{
 border-left: 1px solid transparent;
 display: none;
}
.ant-menu-item:hover, 
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover{
    color: transparent;
    
}

aside{
    width: auto !important;
    max-width: none !important;
    flex: initial !important;
}

.sidebar{
    background-color: #FFFFFF !important;
    /*border: solid 1px gray;*/
    width: auto !important;
    min-width: none !important;
    flex:0 0 350px !important;
    transition-duration: 1s !important;
    overflow-y: auto;
    z-index: 5 !important;

}

.ant-layout-sider-collapsed{
    flex:initial !important;
}

.sidebar .trigger{
    color:#0047FF;
    font-size: 20pt;
    margin: 15px 0px 0px 25px;
}

.sidebar > .ant-layout-sider-children >ul {
    margin-top: 15px;
}


.sidebar .sidebar-menu .ant-menu-item span,
.sidebar .sidebar-menu .ant-menu-submenu span,
.sidebar .sidebar-menu .ant-menu-item-only-child span{
    margin-left: 10px;
}


.sidebar .sidebar-menu  .ant-menu-sub .ant-menu-item span:nth-child(2) {
    margin-left: 5px;
    padding: 0;
}




.sidebar li.ant-menu-submenu.ant-menu-submenu-inline {
    color:rgba(0, 0, 0, 0.85) ;
}



.sidebar .ant-menu-submenu-title:hover,
.sidebar .ant-menu-submenu-open,
.sidebar .sidebar-menu .ant-menu-item:hover,
.sidebar .sidebar-menu .ant-menu-item-selected {
    background: rgba(34, 35, 118, 0.1);
    border-left: 3px solid #0047FF;
    color:#0047FF !important;
}
.sidebar .sidebar-menu .ant-menu-item-selected span a{
    color:#0047FF !important;
}

.sidebar .sidebar-menu .ant-menu-item-selected span svg{
    /* background:#0047FF !important; */
    text-shadow: 2px 2px 5px red !important;
}



.sidebar .sidebar-menu .ant-menu-item-selected span svg:hover{
    /* background:#0047FF !important; */
    color:black;
    opacity: 1;
}


.sidebar .ant-menu-item span{
    padding-left: 20px;
}

.sidebar .ant-menu-submenu-title a {
    color: rgba(0,0,0,0.85);
}

.responsive-menu-button{
    display: none;
}
.sidebar-responsive .ant-drawer-body{
    padding: 0;
}


.sidebar-responsive .ant-drawer-body .sidebar-menu{
    padding: 1em 0;
}



.sidebar-responsive .ant-drawer-body .sidebar-menu .ant-menu-item svg{
    position: relative;
    top: 3px;
    right: 5px;
}

.icon-sidebar svg{
    width: 20px;
    height: 20px;
}
.new-icon .anticon{
    padding: 0 0 0 10px !important;
    margin-left: -5px !important;
    margin-right: 0 !important;
    font-size: 20px;

}
/**

**/
@media (max-width: 576px) {
    .sidebar{
        display: none !important;
    }
}


@media (max-width: 768px) {
    .sidebar{
        display: none !important;
    }
    .responsive-menu-button{
        display: inline-block;
        float: left;
        font-size: 2em;
        color: white;
        position: relative;
        top: 20px;
    }

}


@media (max-width: 1200px) {
    .sidebar{
        flex: 0 0 250px !important;
    }

}
