.index .header{
    background: #0047ff;
    width: 100% !important;
    float: left;
    display: block !important;
    padding: 5px 0px !important;
}

.index .header-logo{
    width: auto;
    float: left;
    height: auto;
   
}

.index .header-logo .logo{
    width: 250px !important;
}

.index .header-options-session{
    width: calc(100% - 320px);
    float: right;
}

.btn-session,
.btn-session:active,
.btn-session:hover,
.btn-session:focus
{
    width: auto;
    height: 50px;
    border-radius: 0px;
    font-size: 12pt;
    margin: 5px;
    display: flex;
    margin-top: 20px;
    word-spacing: 6px !important;
    float: right !important;
    border: none;
    background:transparent !important;
    color:#FFF;
}

.btn-session:active,
.btn-session:hover,
.btn-session:focus
{
     border: solid 1px #FFF;
}
.btn-session span{
    display: flex;
    margin-top: 10px;
}
.contenedor-enc{
            height: auto !important  ;
            min-height: calc(100vh - 108px);
            padding: 50px 10px !important;
            background-image: url('/images/fondo-index.PNG');
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            display: block;
            background-position-y: 75%;
}

.btn-ERP{
    width: auto;
    height: 50px;
    border-radius: 0px;
    font-size: 12pt;
    margin: 5px;
    word-spacing: 6px !important;
    border: none;
    color:#FFF;
    padding: 5px 5% ;
    margin-top: 20VH;
    margin-bottom: 50px;
}
