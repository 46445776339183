@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body, html {
    font-family: 'Montserrat', sans-serif;
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;
}

.layout {
    height: auto;
    width: 100%;
    min-height: 100vh;
    min-width: 100vw;
    /* max-height: 100vh;
    max-width: 100vw; */
    background-color: #F5F6F9;
}

.content {
    width: 100%;
    height: auto;
    display: block;
}

.header {
    width: 100%;
    background-color: #FFFFFF;
}


.row-encabezado {
    width: 100% !important;
    background: #0047FF;
    display: inline-flex;
    padding: 10px 10px 10px 20px;
    min-height: 75px;
}

.row-encabezado .view-title {
    margin: 0;
    color: #FFF
}


.row-encabezado .ant-picker-range {
    background: transparent !important;
}


/*-----breadcrumb del encabezado----*/
.row-encabezado .breadcrumb * {
    color: #e8e8f1;
}

.row-encabezado .breadcrumb {
    font-size: 1.2em;
}

.row-encabezado .breadcrumb .breadcrumb-actual {
    color: #fff;
    font-weight: 800;
}


.div-enc-filtros {
    width: auto;
    min-width: calc(100% - 360px);
    display: inline-block;
    text-align: center;
}


.div-enc-filtros .ant-row {
    width: 100%;
    max-width: calc(100% - 60px);
    float: left;
    text-align: left;
}


.div-enc-filtros a {
    min-width: 120px;
    float: left;
    padding: 5px 1% 0px 5px;
    font-size: 12pt;
    margin: 0px;
}

.elm-download {
    float: right !important;
    min-width: 50px !important;
}

.enc-downIco {
    font-size: 15pt;
}

.row-encabezado h2,
.div-enc-filtros .anticon-calendar,
.div-enc-filtros .ant-dropdown-link,
.div-enc-filtros .enc-downIco {
    color: #FFF !important;
    padding-top: 10px;
}

.text-white * {
    color: #FFF !important;
}
.text-green {
    color: green !important;
}
.text-red{
    color: #F00D0D!important;
}

.div-enc-filtros .anticon-calendar,
.div-enc-filtros .ant-dropdown-link,
.div-enc-filtros .enc-downIco {
    margin-right: 5px;

}

.div-enc-filtros .ant-dropdown-link {
    text-align: left;
}

.text-white {
    color: #FFF !important;
}

.elm-4 {
    width: 23% !important;
    background: blue;
    float: left;
}

th {
    font-weight: 700 !important;
    text-transform: uppercase;
}

/*Scroll*/
::-webkit-scrollbar {
    width: 8px;
    /* TamaÃ±o del scroll en vertical */
    height: 8px;
    /* TamaÃ±o del scroll en horizontal */
    background-color: #92a0b1;
    background-color: #000;

    border-radius: 5px;

}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
::-webkit-scrollbar-thumb {
    background-color: #969595;
    background-color: #5b5e5e;
    background-color: #0231aa;
    border-radius: 5px;
}

/* Cambiamos el fondo y agregamos una sombra cuando estÃ© en hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #5b5e5e;
    background-color: #0047ff;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    padding: 0.5px;
}

/* Cambiamos el fondo cuando estÃ© en active */
::-webkit-scrollbar-thumb:active {
    background-color: #5b5e5e;
    background-color: #0047ff;
}

::-webkit-scrollbar-track {
    background: #090a0a;
    background: #131414;
    background: #4e4e53;
    background: #AAA;
    border-radius: 5px;
}


/*Button Add*/
.btnAddPlus {
    border: none;
    display: block;
    position: fixed;
    bottom: 30px;
    right: 10px;
    z-index: 10;
    border-radius: 100px;
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%) !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 80px;
    height: 80px;

}

.btnAddPlus:hover {
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%);
}

.btnCheckGreen {
    border: none;
    display: block;
    position: fixed;
    bottom: 30px;
    right: 10px;
    z-index: 10;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btnCheckGreen:hover,
.btnCheckGreen:active,
.btnCheckGreen:focus {
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%) !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.btnSaveBlue {
    border: none;
    display: block;
    position: fixed;
    bottom: 30px;
    right: 110px;
    z-index: 10;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btnSaveBlue:hover,
.btnSaveBlue:active,
.btnSaveBlue:focus {
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%) !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}


/*Button Add*/
.btn-save {
    display: block;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    border-radius: 100px;


    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);

    width: 80px;
    height: 80px;
}

.btn-save:hover {
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%) !important;
}

.btn-save:focus {
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%) !important;
}

.btn-save svg {
    position: relative;
    top: 5px
}

.sub-section-title {
    font-weight: 600 !important;
    font-size: 22px !important;
    color: #858997 !important;
}

.title-card {
    margin: 0 !important;
    color: #858997 !important;
    font-weight: 600;
    font-size: 20px !important;
}


/*--------------------------CLASES GENERALES PARA TODOS LOS MODALES------------------------*/
.modal-form {
    width: 90% !important;
    max-width: 800px;
}

.modal-form .modal-header {
    margin-bottom: 3rem;
}

.modal-form .btn-close-modal {
    height: 40px;
    border: none;
    -webkit-box-shadow: none;
}

.modal-form .modal-title {
    text-align: center;
    width: 100%;
    /*padding:  0px 40px;*/
    /*float: inline-start;*/
}

.modal-det {
    max-width: 700px !important;
}

.modal-form .closable-button {
    position: relative;
    left: 5px;
    top: -5px;
    border: none;
    box-shadow: none;
    min-height: 40px;
    padding: 0px;
    float: left;
}


.modal-form .ant-row {
    width: 100%;
}

.modal-form .ant-form-item {
    margin-bottom: 0px;
}

.modal-form .ant-col {
    /*margin: 5px; no deja usar offset y deforma otros-------------------------------------------------------------------*/
    margin-bottom: 15px;
}

.modal-form .ant-form-item-label {
    margin-bottom: 5px !important;
}



.modal-form .ant-form-item-control-input-content>input,
.modal-form .ant-form-item-control-input-content>input:focus,
.modal-form .ant-form-item-control-input-content>input:active,
.modal-form .ant-form-item-control-input-content>input:hover,
.modal-form .ant-input-number,
.modal-form input,
.modal-form .ant-picker,
.modal-form .ant-input,
.itemSelect .ant-select-selector,
.modal-form .ant-select-selector {

    height: auto !important;
    min-height: 40px ;
    max-height: unset !important;
   
    border-radius: 10px !important;
    color: rgb(0, 0, 0) !important;
    background: transparent !important;
    background: #eff3fb46 !important;
    background: #eff3fb !important;
    border: none !important;
    width: 100%;
}
.modal-form .ant-select-selection-placeholder::after{
    line-height: 40px!important;
}
.modal-form .ant-picker {
    padding-top: 0px;
    padding-bottom: 0px;
}

.modal-form .ant-input.text-area {
    height: 80px !important;
}

.modal-form .ant-input-number {
    border: none;
}

.modal-form .ant-select-arrow {
    color: #0047ff !important;
    font-size: 12pt !important;
    font-weight: 800;
}

.itemSelect .arrow-select,
.modal-form .arrow-select {
    float: right;
    position: relative;
    display: block;
    margin: -40px 10px 0px 0px !important;
    z-index: 1;
    top: 10px;
}

.modal-proveedor {
    width: 90% !important;
    max-width: 1000px !important;
}

.frm-proveedor .ant-col {
    padding: 5px 10px;
}

.ant-upload-list-item-error .ant-upload-text-icon>.anticon,
.ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    display: block;
}

.colDocsComprobantes label {
    display: block;
    text-align: center;
    margin-bottom: -10px !important;
}


/*Botones*/
.btn-azul {
    width: 160px;
    height: 45px;
    background: #0047FF;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;

}

.btn-morado {
    background: #6100FF;
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}

.btn-morado svg {
    width: 20px;
    position: absolute;
    top: -5px;
    left: 5px;
}

.btn-minus {
    background: #FF0000;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}


.btn-view {
    background-color: #ADFF45;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}

.btn-view:hover {
    background-color: #ADFF45 !important;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}



.btn,
.btn-edit,
.btn-delete,
.btn-mail,
.btn-pdf,
.btn-copy,
.btn-material {
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px !important;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
    color: white !important;
}

.btn-edit,
.btn-edit:hover,
.btn-edit:focus {
    background-color: #456EFF;
}

.btn-delete,
.btn-delete:hover,
.btn-delete:focus {
    background-color: #FF547D;
    min-width: 24px;
}

.btn-mail,
.btn-mail:hover,
.btn-mail:focus {
    background-color: #B845FF;
    color: white;
}

.btn-pdf,
.btn-pdf:hover,
.btn-pdf:focus {
    background-color: #5465FF;
}

.btn-eye,
.btn-eye:hover,
.btn-eye:focus {
    background-color: #ADFF45;
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px !important;
    margin-left: 5px;
    padding: 0px;
    padding-top: 2px;
    color: white !important;
}

.btn-copy,
.btn-copy:hover,
.btn-copy:focus {
    background-color: #FFD954;
}

.btn-material,
.btn-material:hover,
.btn-material:focus {
    background-color: #63D9B8;
}

.btn-plus,
.btn-plus:hover,
.btn-plus:focus {
    background-color: #ADFF45;
}

.btn-whatsapp-2,
.btn-whatsapp-2:hover,
.btn-whatsapp-2:focus {
    background-color: #2FC10B;
}



.btn-whatsapp-1,
.btn-whatsapp-1:hover,
.btn-whatsapp-1:focus {
    background-color: #AD45FF;
}

.btn-mail-1,
.btn-mail-1:hover,
.btn-mail-1:focus {
    background-color: #AD45FF;
}

.btn-mail-2,
.btn-mail-2:hover,
.btn-mail-2:focus {
    background-color: #2FC10B;
}

.btn-money,
.btn-money:hover,
.btn-money:focus {
    background: #FF45F8;
    color: white;
}

.btn-check,
.btn-check:hover,
.btn-check:focus {
    background: #06CA26;

}

.btn-complete,
.btn-complete:hover,
.btn-complete:focus {
    color: white;
    background: #FF8245;
}

.btn-printed-qr,
.btn-printed-qr:hover,
.btn-printed-qr:focus {
    padding-top: 6px !important;
    background: #B454FF;
}

.btn-ghost svg {
    color: transparent;
}


.lblTitulo {
    font-weight: 700;
}


.list-title-blue {
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 2px;
    color: #0047FF !important;
}

.textGreen {
    color: #02C845 !important;
}

.textRed {
    color: #FF0000 !important;
}


.ant-popover,
.ant-popover-placement-top,
.pop-over {
    z-index: 5 !important;
}

.nView {
    display: none !important;
    visibility: hidden !important;
}

.radioTipo {
    background: rosybrown;
}

.radioTipo .ant-radio-button-wrapper {
    background: #0047FF;
    color: #FFF;
    border: none;
}

.radioTipo .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
}

.radioTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none;
    border-bottom: solid 1px #FFF;
}

.tag-normal{
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bookmark-1{
    width: 4px;
    height: 60px;
    background-color: #34C800;
    position: absolute;
    z-index: 1000;
}

.bookmark-2{
    width: 4px;
    height: 60px;
    background-color: #C80000;
    position: absolute;
    z-index: 1000;
}

/*
.radioTipo .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {

    background: #9bb5f6 !important;
    border:none;
} */

.timer-container {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 230px;
    z-index: 10;
    border-radius: 100px;
    width: 180px;
    height: 80px;
    background: #FF5E5E;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top: 25px !important;
}

/*
 * @component CardCuenta
 * @description limita el tamaño de cada contenedor principal del carrousel
 */
.carousel-cards .react-multi-carousel-item {
    max-width: 387px;
}

.timer-container:hover,
.timer-container:active,
.timer-container:focus {
    background: #FF5E5E;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.factura-tag{
    border-radius: 8px !important;
}


@media (max-width: 992px) {}


@media (max-width: 1200px) {}



/*-------------------------------CLASES GENERALES--------------------------------------*/
.black * {
    color: black !important;
}

.hover:hover {
    cursor: pointer;
}

.black {
    color: black !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.flex-column {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.flex-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-left-column {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    flex-direction: column !important;
}

.flex-right-column {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-end !important;
    flex-direction: column !important;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-left-column-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.flex-left-column-around {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.heigth-100 {
    height: 100% !important;
}

.text-gray {
  color: #858997 !important;
}

.border {
    border: solid 1px red !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 1rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pt-05 {
    padding-top: 0.5rem !important;
}

.pt-2 {
    padding-top: 2rem !important;
}


.pl-1 {
    padding-left: 1.5rem !important;
}

.pl-2 {
    padding-left: 2rem !important;
}

.pl-3 {
    padding-left: 3rem !important;
}

.pr-1 {
    padding-right: 1rem !important;
}

.pb-2 {
    padding-bottom: 2rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.m-0,
.m-0 .ant-form-item-control {
    margin: 0 !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.font-20 {
    font-size: 20px !important;
}

.font-16 {
    font-size: 16px !important;
}


.w100 {
    width: 100%;
}

/*--------------------------------------------------------------------------------------*/

/*GRUPOS.js*/

/* SOLAMENTE PARA LA ANIMACION DEL ICONO DE COLLAPSE*/
.bar {
    position: absolute;
    right: 22px;
    top: 9px;
    /* bottom: -82px; */
}

.bar .plus_icon {
    position: absolute;
    right: 22px;
    bottom: -82px;
}

.plus_icon {
    position: relative;
    width: 5px;
    height: 5px;
    margin: 12px auto;
    z-index: 400;
}

.plus_icon:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon:after {
    content: "";
    position: absolute;
    margin-top: -5px;
    margin-left: 5px;
    width: 2px;
    height: 12px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon.open:after {
    margin-top: 0px;
    width: 2px;
    height: 2px;
}

.plus_icon:before,
.plus_icon:after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}


.cuenta-select {
    width: 100%;
}

.select-input select {
    width: 100%;
}

.select-input .ant-select-selector, .select-input {
    background-color: transparent !important;
    border: 1px solid #fff;
}

.select-input .ant-select-selector .ant-select-selection-placeholder, .select-input .ant-select-selector .ant-select-selection-item {
    color: #fff;
}

.select-input .ant-select-arrow svg {
    color: #fff;
}

.erp-breadcrumb a,
.erp-breadcrumb .ant-breadcrumb-separator,
.erp-breadcrumb strong {
    color: white !important;

}

.div-acrTran .ant-table-pagination-right {
    justify-content: none !important;
}

.ant-table-pagination {
    display: inline-block !important;
}


.card-container {
    padding-bottom: 1.1em !important;
}

/* Background */
.bg-transparent {
    background: transparent;
}

.bg-white {
    background: #fff;
}


.info-card-fh {
    margin: 10px 0px !important;
    background: white !important;
    border-radius: 10px;

}

.card-info-dsh-fh .PM-card-title {
    color: rgba(0, 0, 0, 0.425) !important;
}

.text-black-tag {
    color: #000000 !important;
    position: relative;
}

.text-black-tag .point {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 3px;
    right: 3px;
}

.panel-list .ant-collapse-extra {
    width: 100%;
}

.collapse-list .ant-collapse-extra {

    width: 100%;

}