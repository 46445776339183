
.content{
    width: 100%;
    margin: auto;
  
}
.login{
    width: 100vw;
    overflow: auto;
    text-align: center;

}



.login .ant-col{
    padding: 10px;
}

.titulo-login{

font-style: normal;
font-weight: 600;
font-size: 35px;
line-height: 48px;
color: #233B58;
width: 100%;
text-align: left;
}

.contenedor-login{
    height: 100vh   ;
    padding: 20px 10px !important;
    background-image: url('/images/splash1.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: block;
    background-position-y: 75%;
    border-radius: 10px;
}

.contenedor-reset{
    height: auto !important  ;
    
    padding: 50px 10px !important;
    background-image: url('/images/fondo-reset.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: block;
    background-position-y: 75%;
    background-position-x: 85%;
    border-radius: 10px;
}

.contenedor-login img,
.contenedor-reset img
{
    border-radius: 10px;
    width: 40%;
    margin-top: 40%;
    margin-bottom: 200px;
}

.btn-login,
.btn-login:active,
.btn-login:hover,
.btn-login:focus
{
    height: 50px;
    border-radius: 5px;
    font-size: 12pt;
    width: 90%;
    margin: 10px 5%;
    margin-top: 20px;
    word-spacing: 6px !important;
    border: none;
    background: #0541DD;
     
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 5px;

    color: #FFFFFF;


}


.login-form{
    padding: 10px;
    margin-top: 30px;
}

.login-form .ant-form-item-label
{
    width: 100%;
    float: left;
    text-align: left;
}

.login-form label
{
    color:#A4A4A4;
}

.login-form .ant-form-item-control-input-content > input,
.login-form .ant-input-password
{
    height: 50px;
    background: #e8ebf3;
    border-radius: 10px;
    border: none;
    color: #000;
}

.login-form .ant-form-item-control-input-content > input:focus,
.login-form .ant-input-password:focus,

.login-form .ant-form-item-control-input-content > input:hover,
.login-form .ant-input-password:hover
{
    background: #fff;
}

.login-form input
{
    background: transparent; 
}

.fondo-login{
    background: red;
}


@media(max-width:1200px){
    .login
    {
        height: 80vh !important;
    }
    .contenedor-login{
        height: 90vh !important;
    }
    .login-form .ant-form-item{
        margin:0;
    }
}