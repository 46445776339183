.component-list-video{
    float: left;
    margin:10px;
    width: 100%;
    height: 90% !important;
    max-height: 95% !important;
 
}


.component-list-video .ant-list-items{
    display: list-item;
    width: 100%;
    float: left;
    height: 100% !important;
    max-height: 90% !important;
    overflow: auto;
    
}


.video-react{
    min-width: 220px !important;
    margin:10px;
}

.row-video{
    float: left;
    max-width: 30%;
    margin: 1.66%;
    min-width: 180px;
    padding: 10px;
    max-height: 225px !important;
    display: inline-grid;
    float: left;
}

.row-video .ant-image {
    margin: auto;
}

.modal-videos{
    width: 80% !important;
    max-width: 1200px !important;

    height: 90% !important;
    max-height: 85vh;
}


 

.modal-videos  .ant-spin-nested-loading{
    height: 100%;
    max-height: 90% !important;
}


.modal-videos  .ant-spin-container{
    height: 100%;
    max-height: 100% !important;
}

 


.item-image img{
    height: 150px !important;
    width: auto !important;
}