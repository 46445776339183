.btn-proCal 
  {
    width:  30px !important;
    height: 30px !important;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
    border:solid 1px rgba(255, 255, 255, 0.164);
}

.btn-proCal,
.btn-proCal:hover,
.btn-proCal:focus {
    background-color: #492AF9 !important;
}

.lblProyecto{
    font-weight: 700;
    margin-left: 10px;
    font-size: 12pt;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 65px;
}



.ant-popover{
    z-index: 3000 !important;
}

.events{
    padding: 0;
}
