.crm-card {
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 10px;
}

.crm-card.card-ventas-totales{
    background: linear-gradient(275.68deg, #39D0BB -41.13%, #00e431 100.89%);
}

.crm-card.card-ventas-cobrar{
    background: linear-gradient(275.72deg, #7B39D0 15.67%, #AD00FF 100.87%);

}

.crm-card.card-cobrado{
    background: linear-gradient(275.68deg, #5404FF -41.13%, #00A3FF 100.89%);

}

.crm-card.card-montos-vender{
    background: linear-gradient(275.68deg, #C50F9D -41.13%, #FF00C7 100.89%);

}

.crm-card .crm-stats .ant-statistic-title{
    color: white;
    font-size: 22px;
    font-weight: bold;
}


.crm-card .ant-card-head{
    border: none !important;
    font-weight: 600;
    font-size: 25px;
    line-height: 24px;
    color: #858997;
    margin-top: 0.5em;
}