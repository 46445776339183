
.print-source {
    display: none !important;  
  }

@media print {

body {
    background-color: white;
}
.print-source {
      display: block !important;  
    width: 100%;
    padding:15px 20px !important;
    background-color: white;
}

.header-logo{
    background: white  !important;
    margin-top: 25px;
    text-align: center !important; 
}

body > .print-source {
    display: block;
}

 

.sec-encabezado .ant-row{
    padding:5px;
    height: auto;
    min-height: 70px;
    margin-top: 5px;
    margin-bottom: 0px !important;
}

.sec-encabezado h2{
    width: 100%;
    padding:5px;
    margin-top: 10px;
    margin-bottom: 0px;
    height: 30px;
    
    font-weight: bolder;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 1.5px;
    color: #000000; 
}

.sec-encabezado h3{
    width: 100%;
    margin: 0px;
    padding:5px;
    padding: 5px;
    height: 25px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #000000; 
}

.row-grupo{
    width: 100%;
    padding: 20px 10px;
    margin-top: 10px;
    margin-bottom: 40px;
}

.div-Enc{
    display: inline-flex;
    width: 100%;
    color:#FFF;
    background: #0029FF;
}

.div-Enc .ant-col{
    padding:12px;
    font-size: 10pt;
    vertical-align: middle;
    top: 10px;
    width: 20%;
}

.div-Enc .div-nombre{
    font-size: 15pt;
    font-weight: 800;
    top: 2px;
    min-width: 180px;
}

.div-Emp{
background: rgba(189, 0, 255, 0.11);
margin-bottom: 1px;
padding: 11px;
border-bottom: solid 1px #FFF;;
 
}


.div-Emp-nombre  , .div-Emp-monto  {
   font-size: 9pt;
   }

.div-Emp-nombre {
 width: calc(100% - 160px);
 font-weight: 500;

}
 
.div-Emp-monto  {
    width: 150px; 
    text-align: right;
    font-weight: bolder;
} 

.div-totalNomina,
.div-totalTran,
.div-total{
    background: #0029FF !important;
    width: auto !important;
    float: right;
    padding: 10px;
    margin-right: 15px;
}

.div-totalNomina h2, 
.div-total h2 {
    color:#FFF;
    text-align: right;
    margin:0px;
    padding: 0px;
  
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    /* identical to box height */
    letter-spacing: 0.2em;
}


.div-totalNomina h3,
.div-total h3 {
    color:#FFF;
    text-align: left;
    margin:0px;
    padding: 0px;
  
    font-style: normal;
    font-weight: 900;
    font-size: 10pt;
    line-height: 12px;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
}


.div-totalNomina  {
    margin: 100px 0px;
    width: 300px;
}
 
table,
.blankTheme{
    width: auto !important;
    padding:5px 5px 5px 0px;
    max-width: none !important;
}

.ant-table table {
    table-layout: fixed !important ;
    width: 100%;
    text-align: center;
    margin:0px;
    float: left;
    margin-left: 0px !important;

}

th.ant-table-cell
{
    margin-top: 20px;
    padding-top: 20px !important;
    background: #0029FF !important;
    color:#FFF !important;
    text-align: center !important;

    font-size: 12pt !important;

}

td.ant-table-cell
{
        padding:4px !important;
        font-size: 10pt !important;
}

td.ant-table-cell p 
{
        margin-bottom: 1px !important;
}

td > .lblTitulo{
    min-width: 250px !important;
}


.ant-table-tbody  td {
    background: #F0F2F7;
    margin-bottom: 1px;
    border-bottom: 2px solid #FFF !important;
}


.tagTotal{
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 18px; 
text-align: center;
letter-spacing: 2px;
color: #000000;
margin-right: 5px;
}

.div-totalTran h1,
.div-totalTran h2,
.div-totalTran h3{
    color:#FFF;
    width: auto;
    float: left;
}


.div-totalTran h1{
 margin: 0px !important;
 font-size:10pt !important;
}

.div-totalTran h3{
    margin: 10px !important;
    margin-top: 14px !important;
    font-size: 20pt !important;
}
.div-totalTran h2{
    margin-top: 22px !important;
    font-size: 10pt !important;
   }

   
.row-filtros{
    width: 100%;
    height: auto !important;
    display: inline-flex;
}

.sec-encabezado {
   margin-bottom: 20px;
   height: auto !important;
   min-height: 80px !important ;
}


.sec-encabezado .ant-row {
    height: 70px !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}
.row-filtros h3 {
    text-align: center;
}

.row-filtros .ant-col{
    height: auto !important;
    min-height: 50px !important;
    text-align: center !important;
    padding: 5px 10px !important;
}

.row-filtros small{
    font-weight: bold;
    font-size: 10pt !important;

}

.row-filtros p{
    font-size: 9pt !important;
    margin: 0px !important;
}

.row-filtros ul{
    list-style: none;
    display: block;
    margin: 0px;
    padding: 0px;
    text-align: center !important;
}

.row-filtros li{
    margin: auto;
    padding: 0px;
   font-size: 9pt !important;
   text-align: left;
}


  /* 
  <h1>$</h1>
  <h3>{this.state.totalTran}</h3>
  <h2> MXN</h2> 
  */


    .print-source {
        display: block;
        /* 
        background-color: green; */
    }



} 
/* 
*/

.tablaRpt th{
background: #10DB48 !important;
}

.tablaRpt td{
    padding: 20px 2px !important;
    }
    

.secTotalCliente .div-totalNomina,
.secTotalCliente .div-totalTran,
.secTotalCliente .div-total{
    background: #10DB48 !important;
    min-width: 225px;
    margin-left: 10px;
    
}

.secTotalCliente {
    float: right !important;
    text-align: right;
    padding-left: 200px;
}



.secTotalCliente .div-totalNomina h1,
.secTotalCliente .div-total h1 {
    font-size: 16px !important;
    font-weight: 900;
}

.secTotalCliente .div-totalNomina h2,
.secTotalCliente .div-total h2 {
    font-weight: bolder;
    font-size: 18px !important;
    margin-top: 16px !important;
}


.secTotalCliente .div-totalNomina h3, 
.secTotalCliente .div-total h3 {
    font-size: 18pt !important;
    font-weight: 900;
    min-width: 120px;
    text-align: right;

}

.secTotalCliente .tagTotal{
    margin-top: 3px;
}