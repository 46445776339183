@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body, html {
    font-family: 'Montserrat', sans-serif;
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;
}

.layout {
    height: auto;
    width: 100%;
    min-height: 100vh;
    min-width: 100vw;
    /* max-height: 100vh;
    max-width: 100vw; */
    background-color: #F5F6F9;
}

.content {
    width: 100%;
    height: auto;
    display: block;
}

.header {
    width: 100%;
    background-color: #FFFFFF;
}


.row-encabezado {
    width: 100% !important;
    background: #0047FF;
    display: inline-flex;
    padding: 10px 10px 10px 20px;
    min-height: 75px;
}

.row-encabezado .view-title {
    margin: 0;
    color: #FFF
}


.row-encabezado .ant-picker-range {
    background: transparent !important;
}


/*-----breadcrumb del encabezado----*/
.row-encabezado .breadcrumb * {
    color: #e8e8f1;
}

.row-encabezado .breadcrumb {
    font-size: 1.2em;
}

.row-encabezado .breadcrumb .breadcrumb-actual {
    color: #fff;
    font-weight: 800;
}


.div-enc-filtros {
    width: auto;
    min-width: calc(100% - 360px);
    display: inline-block;
    text-align: center;
}


.div-enc-filtros .ant-row {
    width: 100%;
    max-width: calc(100% - 60px);
    float: left;
    text-align: left;
}


.div-enc-filtros a {
    min-width: 120px;
    float: left;
    padding: 5px 1% 0px 5px;
    font-size: 12pt;
    margin: 0px;
}

.elm-download {
    float: right !important;
    min-width: 50px !important;
}

.enc-downIco {
    font-size: 15pt;
}

.row-encabezado h2,
.div-enc-filtros .anticon-calendar,
.div-enc-filtros .ant-dropdown-link,
.div-enc-filtros .enc-downIco {
    color: #FFF !important;
    padding-top: 10px;
}

.text-white * {
    color: #FFF !important;
}
.text-green {
    color: green !important;
}
.text-red{
    color: #F00D0D!important;
}

.div-enc-filtros .anticon-calendar,
.div-enc-filtros .ant-dropdown-link,
.div-enc-filtros .enc-downIco {
    margin-right: 5px;

}

.div-enc-filtros .ant-dropdown-link {
    text-align: left;
}

.text-white {
    color: #FFF !important;
}

.elm-4 {
    width: 23% !important;
    background: blue;
    float: left;
}

th {
    font-weight: 700 !important;
    text-transform: uppercase;
}

/*Scroll*/
::-webkit-scrollbar {
    width: 8px;
    /* TamaÃ±o del scroll en vertical */
    height: 8px;
    /* TamaÃ±o del scroll en horizontal */
    background-color: #92a0b1;
    background-color: #000;

    border-radius: 5px;

}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
::-webkit-scrollbar-thumb {
    background-color: #969595;
    background-color: #5b5e5e;
    background-color: #0231aa;
    border-radius: 5px;
}

/* Cambiamos el fondo y agregamos una sombra cuando estÃ© en hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #5b5e5e;
    background-color: #0047ff;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    padding: 0.5px;
}

/* Cambiamos el fondo cuando estÃ© en active */
::-webkit-scrollbar-thumb:active {
    background-color: #5b5e5e;
    background-color: #0047ff;
}

::-webkit-scrollbar-track {
    background: #090a0a;
    background: #131414;
    background: #4e4e53;
    background: #AAA;
    border-radius: 5px;
}


/*Button Add*/
.btnAddPlus {
    border: none;
    display: block;
    position: fixed;
    bottom: 30px;
    right: 10px;
    z-index: 10;
    border-radius: 100px;
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%) !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 80px;
    height: 80px;

}

.btnAddPlus:hover {
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%);
}

.btnCheckGreen {
    border: none;
    display: block;
    position: fixed;
    bottom: 30px;
    right: 10px;
    z-index: 10;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btnCheckGreen:hover,
.btnCheckGreen:active,
.btnCheckGreen:focus {
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%) !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.btnSaveBlue {
    border: none;
    display: block;
    position: fixed;
    bottom: 30px;
    right: 110px;
    z-index: 10;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btnSaveBlue:hover,
.btnSaveBlue:active,
.btnSaveBlue:focus {
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%) !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}


/*Button Add*/
.btn-save {
    display: block;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    border-radius: 100px;


    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);

    width: 80px;
    height: 80px;
}

.btn-save:hover {
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%) !important;
}

.btn-save:focus {
    background: linear-gradient(126.63deg, #61F5C0 11.98%, #00FF38 83.35%) !important;
}

.btn-save svg {
    position: relative;
    top: 5px
}

.sub-section-title {
    font-weight: 600 !important;
    font-size: 22px !important;
    color: #858997 !important;
}

.title-card {
    margin: 0 !important;
    color: #858997 !important;
    font-weight: 600;
    font-size: 20px !important;
}


/*--------------------------CLASES GENERALES PARA TODOS LOS MODALES------------------------*/
.modal-form {
    width: 90% !important;
    max-width: 800px;
}

.modal-form .modal-header {
    margin-bottom: 3rem;
}

.modal-form .btn-close-modal {
    height: 40px;
    border: none;
    -webkit-box-shadow: none;
}

.modal-form .modal-title {
    text-align: center;
    width: 100%;
    /*padding:  0px 40px;*/
    /*float: inline-start;*/
}

.modal-det {
    max-width: 700px !important;
}

.modal-form .closable-button {
    position: relative;
    left: 5px;
    top: -5px;
    border: none;
    box-shadow: none;
    min-height: 40px;
    padding: 0px;
    float: left;
}


.modal-form .ant-row {
    width: 100%;
}

.modal-form .ant-form-item {
    margin-bottom: 0px;
}

.modal-form .ant-col {
    /*margin: 5px; no deja usar offset y deforma otros-------------------------------------------------------------------*/
    margin-bottom: 15px;
}

.modal-form .ant-form-item-label {
    margin-bottom: 5px !important;
}



.modal-form .ant-form-item-control-input-content>input,
.modal-form .ant-form-item-control-input-content>input:focus,
.modal-form .ant-form-item-control-input-content>input:active,
.modal-form .ant-form-item-control-input-content>input:hover,
.modal-form .ant-input-number,
.modal-form input,
.modal-form .ant-picker,
.modal-form .ant-input,
.itemSelect .ant-select-selector,
.modal-form .ant-select-selector {

    height: auto !important;
    min-height: 40px ;
    max-height: unset !important;
   
    border-radius: 10px !important;
    color: rgb(0, 0, 0) !important;
    background: transparent !important;
    background: #eff3fb46 !important;
    background: #eff3fb !important;
    border: none !important;
    width: 100%;
}
.modal-form .ant-select-selection-placeholder::after{
    line-height: 40px!important;
}
.modal-form .ant-picker {
    padding-top: 0px;
    padding-bottom: 0px;
}

.modal-form .ant-input.text-area {
    height: 80px !important;
}

.modal-form .ant-input-number {
    border: none;
}

.modal-form .ant-select-arrow {
    color: #0047ff !important;
    font-size: 12pt !important;
    font-weight: 800;
}

.itemSelect .arrow-select,
.modal-form .arrow-select {
    float: right;
    position: relative;
    display: block;
    margin: -40px 10px 0px 0px !important;
    z-index: 1;
    top: 10px;
}

.modal-proveedor {
    width: 90% !important;
    max-width: 1000px !important;
}

.frm-proveedor .ant-col {
    padding: 5px 10px;
}

.ant-upload-list-item-error .ant-upload-text-icon>.anticon,
.ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    display: block;
}

.colDocsComprobantes label {
    display: block;
    text-align: center;
    margin-bottom: -10px !important;
}


/*Botones*/
.btn-azul {
    width: 160px;
    height: 45px;
    background: #0047FF;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;

}

.btn-morado {
    background: #6100FF;
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}

.btn-morado svg {
    width: 20px;
    position: absolute;
    top: -5px;
    left: 5px;
}

.btn-minus {
    background: #FF0000;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}


.btn-view {
    background-color: #ADFF45;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}

.btn-view:hover {
    background-color: #ADFF45 !important;
    border-radius: 5px;
    width: 29px;
    height: 29px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
}



.btn,
.btn-edit,
.btn-delete,
.btn-mail,
.btn-pdf,
.btn-copy,
.btn-material {
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px !important;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
    color: white !important;
}

.btn-edit,
.btn-edit:hover,
.btn-edit:focus {
    background-color: #456EFF;
}

.btn-delete,
.btn-delete:hover,
.btn-delete:focus {
    background-color: #FF547D;
    min-width: 24px;
}

.btn-mail,
.btn-mail:hover,
.btn-mail:focus {
    background-color: #B845FF;
    color: white;
}

.btn-pdf,
.btn-pdf:hover,
.btn-pdf:focus {
    background-color: #5465FF;
}

.btn-eye,
.btn-eye:hover,
.btn-eye:focus {
    background-color: #ADFF45;
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px !important;
    margin-left: 5px;
    padding: 0px;
    padding-top: 2px;
    color: white !important;
}

.btn-copy,
.btn-copy:hover,
.btn-copy:focus {
    background-color: #FFD954;
}

.btn-material,
.btn-material:hover,
.btn-material:focus {
    background-color: #63D9B8;
}

.btn-plus,
.btn-plus:hover,
.btn-plus:focus {
    background-color: #ADFF45;
}

.btn-whatsapp-2,
.btn-whatsapp-2:hover,
.btn-whatsapp-2:focus {
    background-color: #2FC10B;
}



.btn-whatsapp-1,
.btn-whatsapp-1:hover,
.btn-whatsapp-1:focus {
    background-color: #AD45FF;
}

.btn-mail-1,
.btn-mail-1:hover,
.btn-mail-1:focus {
    background-color: #AD45FF;
}

.btn-mail-2,
.btn-mail-2:hover,
.btn-mail-2:focus {
    background-color: #2FC10B;
}

.btn-money,
.btn-money:hover,
.btn-money:focus {
    background: #FF45F8;
    color: white;
}

.btn-check,
.btn-check:hover,
.btn-check:focus {
    background: #06CA26;

}

.btn-complete,
.btn-complete:hover,
.btn-complete:focus {
    color: white;
    background: #FF8245;
}

.btn-printed-qr,
.btn-printed-qr:hover,
.btn-printed-qr:focus {
    padding-top: 6px !important;
    background: #B454FF;
}

.btn-ghost svg {
    color: transparent;
}


.lblTitulo {
    font-weight: 700;
}


.list-title-blue {
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 2px;
    color: #0047FF !important;
}

.textGreen {
    color: #02C845 !important;
}

.textRed {
    color: #FF0000 !important;
}


.ant-popover,
.ant-popover-placement-top,
.pop-over {
    z-index: 5 !important;
}

.nView {
    display: none !important;
    visibility: hidden !important;
}

.radioTipo {
    background: rosybrown;
}

.radioTipo .ant-radio-button-wrapper {
    background: #0047FF;
    color: #FFF;
    border: none;
}

.radioTipo .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
}

.radioTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none;
    border-bottom: solid 1px #FFF;
}

.tag-normal{
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bookmark-1{
    width: 4px;
    height: 60px;
    background-color: #34C800;
    position: absolute;
    z-index: 1000;
}

.bookmark-2{
    width: 4px;
    height: 60px;
    background-color: #C80000;
    position: absolute;
    z-index: 1000;
}

/*
.radioTipo .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {

    background: #9bb5f6 !important;
    border:none;
} */

.timer-container {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 230px;
    z-index: 10;
    border-radius: 100px;
    width: 180px;
    height: 80px;
    background: #FF5E5E;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top: 25px !important;
}

/*
 * @component CardCuenta
 * @description limita el tamaño de cada contenedor principal del carrousel
 */
.carousel-cards .react-multi-carousel-item {
    max-width: 387px;
}

.timer-container:hover,
.timer-container:active,
.timer-container:focus {
    background: #FF5E5E;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.factura-tag{
    border-radius: 8px !important;
}


@media (max-width: 992px) {}


@media (max-width: 1200px) {}



/*-------------------------------CLASES GENERALES--------------------------------------*/
.black * {
    color: black !important;
}

.hover:hover {
    cursor: pointer;
}

.black {
    color: black !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.flex-column {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.flex-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-left-column {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    flex-direction: column !important;
}

.flex-right-column {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-end !important;
    flex-direction: column !important;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-left-column-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.flex-left-column-around {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.heigth-100 {
    height: 100% !important;
}

.text-gray {
  color: #858997 !important;
}

.border {
    border: solid 1px red !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 1rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pt-05 {
    padding-top: 0.5rem !important;
}

.pt-2 {
    padding-top: 2rem !important;
}


.pl-1 {
    padding-left: 1.5rem !important;
}

.pl-2 {
    padding-left: 2rem !important;
}

.pl-3 {
    padding-left: 3rem !important;
}

.pr-1 {
    padding-right: 1rem !important;
}

.pb-2 {
    padding-bottom: 2rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.m-0,
.m-0 .ant-form-item-control {
    margin: 0 !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.font-20 {
    font-size: 20px !important;
}

.font-16 {
    font-size: 16px !important;
}


.w100 {
    width: 100%;
}

/*--------------------------------------------------------------------------------------*/

/*GRUPOS.js*/

/* SOLAMENTE PARA LA ANIMACION DEL ICONO DE COLLAPSE*/
.bar {
    position: absolute;
    right: 22px;
    top: 9px;
    /* bottom: -82px; */
}

.bar .plus_icon {
    position: absolute;
    right: 22px;
    bottom: -82px;
}

.plus_icon {
    position: relative;
    width: 5px;
    height: 5px;
    margin: 12px auto;
    z-index: 400;
}

.plus_icon:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon:after {
    content: "";
    position: absolute;
    margin-top: -5px;
    margin-left: 5px;
    width: 2px;
    height: 12px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon.open:after {
    margin-top: 0px;
    width: 2px;
    height: 2px;
}

.plus_icon:before,
.plus_icon:after {
    transition: all 0.3s ease;
}


.cuenta-select {
    width: 100%;
}

.select-input select {
    width: 100%;
}

.select-input .ant-select-selector, .select-input {
    background-color: transparent !important;
    border: 1px solid #fff;
}

.select-input .ant-select-selector .ant-select-selection-placeholder, .select-input .ant-select-selector .ant-select-selection-item {
    color: #fff;
}

.select-input .ant-select-arrow svg {
    color: #fff;
}

.erp-breadcrumb a,
.erp-breadcrumb .ant-breadcrumb-separator,
.erp-breadcrumb strong {
    color: white !important;

}

.div-acrTran .ant-table-pagination-right {
    justify-content: none !important;
}

.ant-table-pagination {
    display: inline-block !important;
}


.card-container {
    padding-bottom: 1.1em !important;
}

/* Background */
.bg-transparent {
    background: transparent;
}

.bg-white {
    background: #fff;
}


.info-card-fh {
    margin: 10px 0px !important;
    background: white !important;
    border-radius: 10px;

}

.card-info-dsh-fh .PM-card-title {
    color: rgba(0, 0, 0, 0.425) !important;
}

.text-black-tag {
    color: #000000 !important;
    position: relative;
}

.text-black-tag .point {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    top: 3px;
    right: 3px;
}

.panel-list .ant-collapse-extra {
    width: 100%;
}

.collapse-list .ant-collapse-extra {

    width: 100%;

}
.index .header{
    background: #0047ff;
    width: 100% !important;
    float: left;
    display: block !important;
    padding: 5px 0px !important;
}

.index .header-logo{
    width: auto;
    float: left;
    height: auto;
   
}

.index .header-logo .logo{
    width: 250px !important;
}

.index .header-options-session{
    width: calc(100% - 320px);
    float: right;
}

.btn-session,
.btn-session:active,
.btn-session:hover,
.btn-session:focus
{
    width: auto;
    height: 50px;
    border-radius: 0px;
    font-size: 12pt;
    margin: 5px;
    display: flex;
    margin-top: 20px;
    word-spacing: 6px !important;
    float: right !important;
    border: none;
    background:transparent !important;
    color:#FFF;
}

.btn-session:active,
.btn-session:hover,
.btn-session:focus
{
     border: solid 1px #FFF;
}
.btn-session span{
    display: flex;
    margin-top: 10px;
}
.contenedor-enc{
            height: auto !important  ;
            min-height: calc(100vh - 108px);
            padding: 50px 10px !important;
            background-image: url('/images/fondo-index.PNG');
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            display: block;
            background-position-y: 75%;
}

.btn-ERP{
    width: auto;
    height: 50px;
    border-radius: 0px;
    font-size: 12pt;
    margin: 5px;
    word-spacing: 6px !important;
    border: none;
    color:#FFF;
    padding: 5px 5% ;
    margin-top: 20VH;
    margin-bottom: 50px;
}

/**
Codigo CSS de Manuel

*/
.logo{
    height: 90px;
}
.header{
    display: flex;
    width: 100vw !important;
    max-width: 100% !important;
    height: 96px;
}

.header-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    min-height: 50px;
}

.header-logo{
    background: #0047ff !important;
    height: 110px;
    /*display: inline-table;*/
}

.header-logo .logo{
  /*  float: left;*/
    margin: 0px;
    height: auto;
    max-width: 350px;
}

.header-search
{
width: 50%;
max-width: 600px;
}
.search-header   .ant-input-group ,.search-header .ant-input-affix-wrapper{
    height: 100% !important;
}

.search-header .ant-input-affix-wrapper{
    margin-top: 5px;
}

.search-header,
.search-header:hover,
.search-header:active,
.search-header:focus
{
    width: 100% !important;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.54px;
    color: #757994;
    mix-blend-mode: normal;
    border-radius: 15px;
    background: #EFF3FB;
    margin-left: 5%;
}

.search-header input{
    height: 100%;

}
.search-header .ant-input-group-addon .ant-input-search-button{
    mix-blend-mode: normal;
    border-radius: 10px !important;
    width: 40px;
    height: 40px;
    float: left;
    margin-left: -10px;
    background: #d3dff7;
    border-color: transparent!important;
    color: #6672FB;
    font-size: 11.5pt;
}
.search-header .ant-input-group-addon .ant-input-search-button:hover,
.search-header .ant-input-group-addon .ant-input-search-button:focus{
    /* border-color: transparent!important; */
    background: #d3dff784;
}
.search-header .ant-input-group-addon span{
    color:#6672FB
}
.search-header .ant-input-suffix{
    display: none;
}

.header-options{
    display: flow-root;
    width: 250px;
    margin-right: 20px;
    align-items: center;
    justify-content: space-around;
    text-align: right;
}

.header-options div{
    width: auto !important;
    display: inline-block;
}

.header-options .icon-Notification
{

    color:#cccccc;
    font-size: 17pt;
    margin-bottom: -7PX;
}

.header-options .ant-badge-count{
    background-color: #5e45d7;
}

.avatar-user{
    margin-right: 10px;
}

.header-user-drop{
    color: #000000 !important;
    font-weight:bold;
    font-size: 10pt;
    letter-spacing: 1px;
}

.btnSearchClear,
.btnSearchClear:active,
.btnSearchClear:hover{
    mix-blend-mode: normal;
    border-radius: 10px;
    background: #d3dff7;
    color: #6672FB;
    margin-left: 0px;
    float: right;
    width: 70px;
    height: 40px;
    margin-top: -40px;
    margin-right: -24px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 10pt;
    display: none;
}


/* xs */
@media (max-width: 480px){
    .logo{
        height: 60px!important;
        margin-top: 13px;
    }
    .header-logo{
        background: #0047ff !important;
        width: 100%;
        height: auto;
        position: relative;
    } 

    .header-logo .logo{
        display:block;
        margin:auto;
    }

    .header-logo .responsive-menu-button{
        position: absolute;
        left:0;
        top: 35% !important; 
        -webkit-transform: translateY(-40%); 
                transform: translateY(-40%);
        font-size: 1.5rem;
    }

    .header-content{
        display:flex;
        flex-flow: column;
        align-items: flex-end;
        height: auto !important;
        padding-top: 1rem;
    }

    .header-content .header-search{
        order: 2;
        width: 100%;
        padding: 1rem 2.5rem 1rem 0;
    }

    .header-content .header-options{
        order: 1;
    }
}

/* sm  */
@media (max-width: 768px){
    .logo{
        height: 80px!important;
        margin-top: 13px;
    }
    .header-logo{
        background: #0047ff !important;
        width: 100%;
        height: auto;
        position: relative;
    } 

    .header-logo .logo{
        display: block;
        margin:auto;
    }

    .header-logo .responsive-menu-button{
        position: absolute;
        left:0;
        top: 35% !important; 
        -webkit-transform: translateY(-40%); 
                transform: translateY(-40%);
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .header{
        display: contents !important;
    }
    .header-content{
        height: 80px;
        margin-top: 0px !important;
    }
}

@media (max-width: 1200px) and (min-width: 768px){
    .header .header-logo{
        /*max-width: 250px!important;*/
        transition-duration: 1s !important;
    }

    .header .header-logo .logo{
        max-width: 250px;
        transition-duration: 1s !important;
    }
}

@media (max-width: 1200px) and  (min-width: 769px) {
    .header .header-logo .logo{
        margin-top: 2em;
    }
}

.div_contenido{
    width: 100%;
}






.avatar-uploader > .ant-upload {
    width: 128px;
    /*height: 128px;*/
}

.avatar-uploader.ant-upload-picture-card-wrapper{
    display: flex;
    justify-content: center;
}


.content{
    width: 100%;
    margin: auto;
  
}
.login{
    width: 100vw;
    overflow: auto;
    text-align: center;

}



.login .ant-col{
    padding: 10px;
}

.titulo-login{

font-style: normal;
font-weight: 600;
font-size: 35px;
line-height: 48px;
color: #233B58;
width: 100%;
text-align: left;
}

.contenedor-login{
    height: 100vh   ;
    padding: 20px 10px !important;
    background-image: url('/images/splash1.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: block;
    background-position-y: 75%;
    border-radius: 10px;
}

.contenedor-reset{
    height: auto !important  ;
    
    padding: 50px 10px !important;
    background-image: url('/images/fondo-reset.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: block;
    background-position-y: 75%;
    background-position-x: 85%;
    border-radius: 10px;
}

.contenedor-login img,
.contenedor-reset img
{
    border-radius: 10px;
    width: 40%;
    margin-top: 40%;
    margin-bottom: 200px;
}

.btn-login,
.btn-login:active,
.btn-login:hover,
.btn-login:focus
{
    height: 50px;
    border-radius: 5px;
    font-size: 12pt;
    width: 90%;
    margin: 10px 5%;
    margin-top: 20px;
    word-spacing: 6px !important;
    border: none;
    background: #0541DD;
     
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 5px;

    color: #FFFFFF;


}


.login-form{
    padding: 10px;
    margin-top: 30px;
}

.login-form .ant-form-item-label
{
    width: 100%;
    float: left;
    text-align: left;
}

.login-form label
{
    color:#A4A4A4;
}

.login-form .ant-form-item-control-input-content > input,
.login-form .ant-input-password
{
    height: 50px;
    background: #e8ebf3;
    border-radius: 10px;
    border: none;
    color: #000;
}

.login-form .ant-form-item-control-input-content > input:focus,
.login-form .ant-input-password:focus,

.login-form .ant-form-item-control-input-content > input:hover,
.login-form .ant-input-password:hover
{
    background: #fff;
}

.login-form input
{
    background: transparent; 
}

.fondo-login{
    background: red;
}


@media(max-width:1200px){
    .login
    {
        height: 80vh !important;
    }
    .contenedor-login{
        height: 90vh !important;
    }
    .login-form .ant-form-item{
        margin:0;
    }
}
.avatar-group-card .ant-avatar-string{
    margin-top: 15px;
}

/*para los card de project manager*/
.avatar-info-card .ant-avatar-string{
    margin-top: 5px;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after
{
 border-left: 1px solid transparent;
 display: none;
}
.ant-menu-item:hover, 
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover{
    color: transparent;
    
}

aside{
    width: auto !important;
    max-width: none !important;
    flex: initial !important;
}

.sidebar{
    background-color: #FFFFFF !important;
    /*border: solid 1px gray;*/
    width: auto !important;
    min-width: none !important;
    flex:0 0 350px !important;
    transition-duration: 1s !important;
    overflow-y: auto;
    z-index: 5 !important;

}

.ant-layout-sider-collapsed{
    flex:initial !important;
}

.sidebar .trigger{
    color:#0047FF;
    font-size: 20pt;
    margin: 15px 0px 0px 25px;
}

.sidebar > .ant-layout-sider-children >ul {
    margin-top: 15px;
}


.sidebar .sidebar-menu .ant-menu-item span,
.sidebar .sidebar-menu .ant-menu-submenu span,
.sidebar .sidebar-menu .ant-menu-item-only-child span{
    margin-left: 10px;
}


.sidebar .sidebar-menu  .ant-menu-sub .ant-menu-item span:nth-child(2) {
    margin-left: 5px;
    padding: 0;
}




.sidebar li.ant-menu-submenu.ant-menu-submenu-inline {
    color:rgba(0, 0, 0, 0.85) ;
}



.sidebar .ant-menu-submenu-title:hover,
.sidebar .ant-menu-submenu-open,
.sidebar .sidebar-menu .ant-menu-item:hover,
.sidebar .sidebar-menu .ant-menu-item-selected {
    background: rgba(34, 35, 118, 0.1);
    border-left: 3px solid #0047FF;
    color:#0047FF !important;
}
.sidebar .sidebar-menu .ant-menu-item-selected span a{
    color:#0047FF !important;
}

.sidebar .sidebar-menu .ant-menu-item-selected span svg{
    /* background:#0047FF !important; */
    text-shadow: 2px 2px 5px red !important;
}



.sidebar .sidebar-menu .ant-menu-item-selected span svg:hover{
    /* background:#0047FF !important; */
    color:black;
    opacity: 1;
}


.sidebar .ant-menu-item span{
    padding-left: 20px;
}

.sidebar .ant-menu-submenu-title a {
    color: rgba(0,0,0,0.85);
}

.responsive-menu-button{
    display: none;
}
.sidebar-responsive .ant-drawer-body{
    padding: 0;
}


.sidebar-responsive .ant-drawer-body .sidebar-menu{
    padding: 1em 0;
}



.sidebar-responsive .ant-drawer-body .sidebar-menu .ant-menu-item svg{
    position: relative;
    top: 3px;
    right: 5px;
}

.icon-sidebar svg{
    width: 20px;
    height: 20px;
}
.new-icon .anticon{
    padding: 0 0 0 10px !important;
    margin-left: -5px !important;
    margin-right: 0 !important;
    font-size: 20px;

}
/**

**/
@media (max-width: 576px) {
    .sidebar{
        display: none !important;
    }
}


@media (max-width: 768px) {
    .sidebar{
        display: none !important;
    }
    .responsive-menu-button{
        display: inline-block;
        float: left;
        font-size: 2em;
        color: white;
        position: relative;
        top: 20px;
    }

}


@media (max-width: 1200px) {
    .sidebar{
        flex: 0 0 250px !important;
    }

}

.main, .main-content {
    width: 100%;
}

.main-dashboard{
    position: relative;
    /* background: url(/images/dashboard_background.jpg) center top no-repeat fixed rgb(24, 126, 236); */
    /* background-image: url(/images/dashboard_background.jpg);*/

}

.main-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position-x: 180px;
    background-position-y: center;
    background-size: 100% auto;
    background-attachment: fixed;
    background-origin: initial;
    background-clip: initial; 
    -webkit-filter: blur(5px); 
            filter: blur(5px);

    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}


.main-content {
    padding: 2em;
}


.title {
    text-align: left;
    margin-bottom: 40px !important;
}

.sample-data {
    text-align: center;
}

.sample-data .sample-data-title {
    font-size: 12px;
    color: #222222;
}

.sample-data .sample-data-content {
    font-size: 30px;
    margin-bottom: 0;
}

.colour1 {
    color: #9013FE;
}

.colour2 {
    color: #FF0165;
}

.colour3 {
    color: #1630F4;
}

.sample-data-card {
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.0296649);
    border-radius: 12px;
}


.sample-data-card .ant-card-body {
    padding: 12px !important;
}


.Encuestas {
    height: 100px;
}


.sample-data-card2 {
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.0296649);
    border-radius: 12px;
}

.sample-data2 {
    text-align: center;
}

.encuesta {

    height: 30px;
}

.Report1 {
    height: 30px;

}

.simple-data-linea {


    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.1px;

    color: #222222;

    mix-blend-mode: normal;
    opacity: 0.6;

}

.sample-data-table {
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.0296649);
    border-radius: 12px;
    color: #9013FE;

}

/*-------CARDS DASHBOARD------*/
.div_contenido {
    background: #FFF;
    border-radius: 10px;
    padding: 10px;
   
}

.card-ingresos{
    background: linear-gradient(153.59deg, #23C496 21.59%, #28C771 67.61%);
    border-radius: 10px;
    min-height: 150px;
}

.card-egresos{
    margin-top: 1rem;
    background: linear-gradient(180deg, #007CE4 17.29%, #0267BC 100%);
    border-radius: 10px;
    min-height: 150px;
}

.card-saldos{
    background: white;
    border-radius: 10px;
    padding: 0.5rem;
}

.card-transacciones-d,
.card-revenue,
.card-last-transactions{
    background: white;
    border-radius: 10px;
    padding: 0.5rem;
}

.card-revenue{
    height: 100%;
}

.card-last-transactions{
    float: left;
    width: 100%;
    min-height: 100%;
}

.gf-Egresos {
    color:'#FFFFFF' !important;
      background: linear-gradient(108.42deg, #d855d8 -16.67%,  #df4efc 78.2%);
      background: linear-gradient(180deg, #007CE4 17.29%, #0267BC 100%);

}

.div-titulo {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.titulo-divCont {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #858997;
    float: left;
    width: calc(100% - 60px) !important;
}


.div-transaccion {
    display: flex;
}

.div-transaccion .ant-col {
    padding: 10px 5px;

}

.div-transaccion .ant-avatar {
    width: 50px;
    height: 50px;
    margin-top: 0px;
}

.div-transaccion h2 {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 0.95rem;
    width: calc(100% - 60px);
}

.div-transaccion span {
    font-weight: 600;
    font-size: 1rem;
    margin-top: 10px;
    display: block;
}


.div-transaccion .div-info {
    min-width: calc(100% - 200px);
}

.div-info-tran{margin-bottom: 10px !important;}

.div-info-tran .ant-avatar-string{
    margin-top: 6px;
}

.spn-red {
    color: #FF0000;
}

.spn-green {
    color: #00FF19;
}


.btn-goto {
    background: #0047ff;
    color: #FFF;
    border-radius: 10px;
    font-weight: 600;
    font-size: 15pt;
    width: 40px;
    padding-left: 10px;
    float: right;
}

.div_transacciones {
    float: left !important;
    width: 100%;
    display: block;
}

.statistic .ant-statistic-content-prefix,
.statistic .ant-statistic-content-value,
.statistic .ant-statistic-content-value-int,
.statistic .ant-statistic-content-value-decimal
{
    display: unset;
}


.color-balance-red span{
    color: red !important;
}

.color-balance-green span{
    color: green !important;
}

.record{
    width: 100% ;
    padding: 3px 10px;
    text-align: center;

    font-weight: bold;


    border-radius: 150px;
    box-shadow: 2px 2px 3px 0px rgba(201,201,201,1);

}


.arrow{
    position: relative;
    left: 0.3em;
    bottom: 2px
}

.filter-element{
    display: block;
    margin: 5px auto;
    text-align: center;
    color: white;
    cursor: pointer;
}

.mt10{
    margin-top: 1rem;
}
.pr10{
    padding-right: 0.5rem;
}


/** NUEVAS CARTAS TRANSPARENTES **/

.card-dashboard{
    width: 100%;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.7);
    border: none ;
    max-height: 500px;
    height: 100%;
}

.card-dashboard{
    width: 100%;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.7);
    border: none ;

    max-height: 450px;
}


.card-dashboard .select-project .ant-select-selector{
    background: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.list-projects{
    overflow: auto;
    height: 350px; 
}

/*
* Pone un borde a un elemento para separar del resto
*/
.border-bottom{
    border-bottom:solid 1px rgba(0, 0, 0, 0.1);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}

/*
*  limita la altura de un div para que haga scroll
*/
.card .scroll-body{
    max-height: 80%;
    overflow: hidden;
    overflow-y: auto;
}

/*
*  Estilo del titulo de un row dentro de los card
*/
.card .col-title{
    font-size: 18px;
    font-weight: bold;
    color: black;
}

/*
*  Estilo standar para los tags de los cards
*/
.card .status-tag{
    border-radius: 12px;
    padding: 0 9px;
    color:  black;
}

.sec-carousel {
    background: repeating-linear-gradient();
    min-width: 200px;
    width: auto;
    max-width: 100%;
}

.sec-carousel .ant-col {
    /*padding: 0px 1rem 0px 0px !important;*/
}

/*Mas ajustes tamaños*/
.react-multi-carousel-item {
    min-width: 177px !important;
}

.sec-carousel .react-multi-carousel-item {
    display: flex;
}

.sec-carousel .ant-card {
    min-width: 165px !important;
    border-radius: 10px;
}

.sec-carousel .ant-card .ant-card-body {
    padding: 0.5rem;
}

.sec-carousel .divInfo {
    float: left;
    width: calc(100% - 25px);
}

.sec-carousel .divInfo h2,
.sec-carousel .divInfo h3,
.sec-carousel .divInfo h4 {
    float: left;
    width: auto;

}


.sec-carousel .divInfo h4 {
    margin-left: 10px;
    margin-top: 11px;
    font-size: 9pt;
}

.sec-carousel .anticon-ellipsis {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.sec-carousel .noCuenta {
    /*margin: 5px 0px 25px 0px;*/
    font-size: 10pt;
    font-weight: 600;
    color: #979797;
}

.card-saldo {
    min-height: 241px !important;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.sec-carousel .card-saldo .text-banco {
    font-weight: 600;
}

.card {
    background: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
}

.div-titulo {
    padding: 0px 0px 15px 0px;
    /*float: left;*/
}

.spnProyecto {
    background: #c4c4c4;
    padding: 10px 15px;
    border-radius: 50px;
}

.tbl-opciones {
    display: inline-flex;
    float: left;
}

.detTable-icon {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    padding: 0px;
    padding-top: 5px;
    margin-left: 5px;

}


.ant-popover {
    margin-right: 20px;
}

.ant-popover-title {
    font-size: 15pt;
    font-weight: 700;
}

.div-menuplus {
    display: inline-grid;
}


.div-menuplus .btn-azul {
    width: auto;
    height: 40px;
    background: #0047FF;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.div-menuBack .btn-azul {
    width: 100%;
    height: 35px;
    background: #0047FF;
    border-radius: 10px;
    font-weight: 200;
    font-size: 14px;
    line-height: 10px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.readOnlyFiles .ant-btn {
    display: none;
    visibility: hidden;
}

.readOnlyFiles .ant-upload-list {
    display: flex;
}

.frm-transacciones .titulo-divCont {
    font-size: 10pt;

}

.frm-transacciones .lblTitulo {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #A4A4A4;
    width: 100%;

    display: block;
}


.frm-transacciones p {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 2px;
    color: #000000;
}




.frm-transacciones .row-transaccion h2 {
    margin: 2px;
    font-weight: 700;
    font-size: 13pt;
}


.frm-transacciones .row-transaccion p {
    margin-top: 9px;
    font-size: 8pt;
}

.item-divider {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #C4C4C4;

}

.readOnlyFiles .lblTitulo {
    margin-bottom: 0px;
}

.modalEncOpciones {
    width: auto !important;
    float: right;
    margin-top: -46px;
}

.project-span {
    border-radius: 5px;
    background: red;
}

.readOnlyFiles .ant-upload-list.ant-upload-list-text {
    display: block;
}

.readOnlyFiles .ant-upload-list.ant-upload-list-text>* {
    display: block;

}

form#formulario-transacciones {
    position: relative;
    left: 5px;
}

.modal-transaccion .modal-form .ant-col {
    margin: unset;
}

.modal-transaccion .modal-form .arrow-select {
    position: relative;
    top: 13px;
}

.modal-transaccion .content-uploader {
    display: flex;
    justify-content: center;
}

.modal-transaccion .avatar-uploader {
    display: flex;
    justify-content: center;
    margin: 1em auto;
}

.modal-transaccion .avatar-uploader>.ant-upload {
    height: auto !important;
}





/**
Hay una discrepancia en los filtros. Agregué esta regla para controlarla
*/
@media only screen and (max-width: 1599px) and (min-width: 1200px) {

    .row-filter:nth-child(0) {
        width: 20%;
    }
}

/*row-filter*/

.flex-left .ant-statistic-content-prefix,
.flex-left .ant-statistic-content-value {
    margin: 0px !important;
}

.table-transacciones .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-header table thead tr th {
    font-size: 12px;
}

.table-transacciones-row td {
    font-size: 12px !important;
}

.table-transacciones-column {
    font-size: 15px;
    padding: 12px !important;
}

@media (max-width: 550px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 550px) and (max-width: 600px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.035rem !important;
    }

    .titleMxn {
        font-size: 0.65rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 600px) and (max-width: 650px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.15rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 650px) and (max-width: 750px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.3rem !important;
    }

    .titleMxn {
        font-size: 0.7rem !important;
        margin-top: 3px;
    }
}




@media (min-width: 770px) and (max-width: 850px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.035rem !important;
    }

    .titleMxn {
        font-size: 0.65rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 850px) and (max-width: 950px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.1rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.3rem !important;
    }

    .titleMxn {
        font-size: 0.7rem !important;
        margin-top: 3px;
    }
}




@media (min-width: 1200px) and (max-width: 1250px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.035rem !important;
    }

    .titleMxn {
        font-size: 0.65rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 1250px) and (max-width: 1350px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.1rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 1350px) and (max-width: 1450px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.3rem !important;
    }

    .titleMxn {
        font-size: 0.7rem !important;
        margin-top: 3px;
    }
}

/*-------------------------------------------------------------------------------------*/
/*---------------------------------CSS PARA FINZNAS BELVO------------------------------*/
/*-------------------------------------------------------------------------------------*/
.component-list .component-list-item-small {
    padding: 0 0 4px 0 !important;
}

.card-list-small {
    width: 100%;
    border: none !important;
    margin: 0.3rem 0;
    border-radius: 8px;
}

.card-list-small .ant-card-body {
    padding: 0.4rem !important;
}

.card-cuenta {
    min-height: 213px;
    height: 100%;
}


.card-cuenta .ant-card-body {
    padding: 10px 0.9rem !important;
}

.card-cuenta .tag-transaccionesSC {
    padding: 4px 5px 4px 2px;
    border: none;
    font-size: 10px;
    background: #A634FF;
    border-radius: 5px;
    color: white;
    width: 100%;
    text-align: right;
    position: relative;
}

.card-cuenta .tag-transaccionesSC svg {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    left: 5px;
    top: 2px;
}

.card-cuenta .tag-transaccionesSC-y {
    padding: 4px 2px;
    border: none;
    font-size: 10px;
    background: #F59606;
    border-radius: 5px;
    color: white;
    width: 100%;
    text-align: center;
    position: relative;
}


.card-cuenta .tag-transaccionesSC-y svg {
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    left: 5px;
    top: 4px;
}


/* Botones de los cards */
.btn-container {
    width: 100%;
}

.btn-container .btn-transacciones {
    width: 100%;
    background: #A906F5 !important;
    border: none !important;
    margin-bottom: 0.5em;
}

.btn-container .btn-belvo-link {
    width: 100%;
    border: none !important;
    margin-bottom: 0.5em;
}

.btn-container .btn-belvo-link.btn-transacciones.clasificar {
    background: #a906f5af !important;
}

.btn-container .btn-belvo-link.btn-transacciones.sin-clasificar {
    background: #A906F5 !important;
}

.btn-container .btn-belvo-link.btn-facturas.clasificar {
    background: #f595069f !important;
}

.btn-container .btn-belvo-link.btn-facturas.sin-clasificar {
    background: #F59606 !important;
}




/* sin-clasificar */

/* clasificar */

.filter-purple {
    background: #a906f5 !important;
    border-radius: 10px 10px 0 0;
    border: none;
    color: white;
    width: 100%;
    max-width: 250px;
    ;
    line-height: 25px;
    text-align: center;
    padding: 0 15px;
}



.filter-purple span {
    margin-right: 5px;
}

.filter-purple .btn-icon {
    float: right;
}



.title-divider {
    display: inline;
    border-left: 1px solid white;
    margin-left: 5px;
    float: right;
}

.title-divider svg {
    position: relative;
    top: 3px;
}



.filter-yellow {
    background: #F59606 !important;
    border-radius: 10px 10px 0 0;
    border: none;
    color: white;
    min-width: 60%;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}


.filter-yellow span {
    margin-right: 5px;
}

.filter-yellow .btn-transparent {
    background: #F59606 !important;
    border: none;
    width: 20px;
    margin-right: 7px;
    padding: 7px 4px
}

.divider {
    width: 2px;
    border-right: solid 0.5px white;
    height: 25px;
    display: inline-block;
}

.float-right {
    float: right;
}

.radio-purple .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(0deg, #BD00FF, #BD00FF) !important;
    border: none;
}

.radio-purple .ant-radio-inner {
    box-shadow: inset 3px 3px 5px rgba(213, 230, 245, 0.4), inset 2px 2px 3px rgba(79, 98, 120, 0.9);
}

.radio-purple .ant-radio-checked .ant-radio-inner::after {
    background: linear-gradient(0deg, #BD00FF, #BD00FF);
    top: 0px;
    left: 0px;
    width: 14px;
    height: 14px;
}

.radio-purple .ant-radio-checked .ant-radio-inner {
    border: none !important;
}

.radio-yellow .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(25.48deg, #FF9A00 41.93%, #FF9A00 64.11%) !important;
    border: none;
}

.radio-yellow .ant-radio-inner {
    box-shadow: inset 3px 3px 5px rgba(213, 230, 245, 0.4), inset 2px 2px 3px rgba(79, 98, 120, 0.9);
}

.radio-yellow .ant-radio-checked .ant-radio-inner::after {
    background: linear-gradient(25.48deg, #FF9A00 41.93%, #FF9A00 64.11%);
    top: 0px;
    left: 0px;
    width: 14px;
    height: 14px;
}

.radio-yellow .ant-radio-checked .ant-radio-inner {
    border: none !important;
}

.text-grey-sm {
    font-size: 12px;
    color: #858997;
}

.text-money {
    font-size: 20px;
    color: #0A0A0A;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.text-money small {
    font-size: 13px;
    color: #0A0A0A;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.card-cuenta .text-banco {
    font-weight: bold;
    font-size: 19px;
}

.modal-divider span{
    font-style: normal;
    font-weight: 600!important;
    font-size: 22px!important;
    line-height: 18px!important;
    color: #0047FF!important;


}

.print-source {
    display: none !important;  
  }

@media print {

body {
    background-color: white;
}
.print-source {
      display: block !important;  
    width: 100%;
    padding:15px 20px !important;
    background-color: white;
}

.header-logo{
    background: white  !important;
    margin-top: 25px;
    text-align: center !important; 
}

body > .print-source {
    display: block;
}

 

.sec-encabezado .ant-row{
    padding:5px;
    height: auto;
    min-height: 70px;
    margin-top: 5px;
    margin-bottom: 0px !important;
}

.sec-encabezado h2{
    width: 100%;
    padding:5px;
    margin-top: 10px;
    margin-bottom: 0px;
    height: 30px;
    
    font-weight: bolder;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 1.5px;
    color: #000000; 
}

.sec-encabezado h3{
    width: 100%;
    margin: 0px;
    padding:5px;
    padding: 5px;
    height: 25px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #000000; 
}

.row-grupo{
    width: 100%;
    padding: 20px 10px;
    margin-top: 10px;
    margin-bottom: 40px;
}

.div-Enc{
    display: inline-flex;
    width: 100%;
    color:#FFF;
    background: #0029FF;
}

.div-Enc .ant-col{
    padding:12px;
    font-size: 10pt;
    vertical-align: middle;
    top: 10px;
    width: 20%;
}

.div-Enc .div-nombre{
    font-size: 15pt;
    font-weight: 800;
    top: 2px;
    min-width: 180px;
}

.div-Emp{
background: rgba(189, 0, 255, 0.11);
margin-bottom: 1px;
padding: 11px;
border-bottom: solid 1px #FFF;;
 
}


.div-Emp-nombre  , .div-Emp-monto  {
   font-size: 9pt;
   }

.div-Emp-nombre {
 width: calc(100% - 160px);
 font-weight: 500;

}
 
.div-Emp-monto  {
    width: 150px; 
    text-align: right;
    font-weight: bolder;
} 

.div-totalNomina,
.div-totalTran,
.div-total{
    background: #0029FF !important;
    width: auto !important;
    float: right;
    padding: 10px;
    margin-right: 15px;
}

.div-totalNomina h2, 
.div-total h2 {
    color:#FFF;
    text-align: right;
    margin:0px;
    padding: 0px;
  
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    /* identical to box height */
    letter-spacing: 0.2em;
}


.div-totalNomina h3,
.div-total h3 {
    color:#FFF;
    text-align: left;
    margin:0px;
    padding: 0px;
  
    font-style: normal;
    font-weight: 900;
    font-size: 10pt;
    line-height: 12px;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
}


.div-totalNomina  {
    margin: 100px 0px;
    width: 300px;
}
 
table,
.blankTheme{
    width: auto !important;
    padding:5px 5px 5px 0px;
    max-width: none !important;
}

.ant-table table {
    table-layout: fixed !important ;
    width: 100%;
    text-align: center;
    margin:0px;
    float: left;
    margin-left: 0px !important;

}

th.ant-table-cell
{
    margin-top: 20px;
    padding-top: 20px !important;
    background: #0029FF !important;
    color:#FFF !important;
    text-align: center !important;

    font-size: 12pt !important;

}

td.ant-table-cell
{
        padding:4px !important;
        font-size: 10pt !important;
}

td.ant-table-cell p 
{
        margin-bottom: 1px !important;
}

td > .lblTitulo{
    min-width: 250px !important;
}


.ant-table-tbody  td {
    background: #F0F2F7;
    margin-bottom: 1px;
    border-bottom: 2px solid #FFF !important;
}


.tagTotal{
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 18px; 
text-align: center;
letter-spacing: 2px;
color: #000000;
margin-right: 5px;
}

.div-totalTran h1,
.div-totalTran h2,
.div-totalTran h3{
    color:#FFF;
    width: auto;
    float: left;
}


.div-totalTran h1{
 margin: 0px !important;
 font-size:10pt !important;
}

.div-totalTran h3{
    margin: 10px !important;
    margin-top: 14px !important;
    font-size: 20pt !important;
}
.div-totalTran h2{
    margin-top: 22px !important;
    font-size: 10pt !important;
   }

   
.row-filtros{
    width: 100%;
    height: auto !important;
    display: inline-flex;
}

.sec-encabezado {
   margin-bottom: 20px;
   height: auto !important;
   min-height: 80px !important ;
}


.sec-encabezado .ant-row {
    height: 70px !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}
.row-filtros h3 {
    text-align: center;
}

.row-filtros .ant-col{
    height: auto !important;
    min-height: 50px !important;
    text-align: center !important;
    padding: 5px 10px !important;
}

.row-filtros small{
    font-weight: bold;
    font-size: 10pt !important;

}

.row-filtros p{
    font-size: 9pt !important;
    margin: 0px !important;
}

.row-filtros ul{
    list-style: none;
    display: block;
    margin: 0px;
    padding: 0px;
    text-align: center !important;
}

.row-filtros li{
    margin: auto;
    padding: 0px;
   font-size: 9pt !important;
   text-align: left;
}


  /* 
  <h1>$</h1>
  <h3>{this.state.totalTran}</h3>
  <h2> MXN</h2> 
  */


    .print-source {
        display: block;
        /* 
        background-color: green; */
    }



} 
/* 
*/

.tablaRpt th{
background: #10DB48 !important;
}

.tablaRpt td{
    padding: 20px 2px !important;
    }
    

.secTotalCliente .div-totalNomina,
.secTotalCliente .div-totalTran,
.secTotalCliente .div-total{
    background: #10DB48 !important;
    min-width: 225px;
    margin-left: 10px;
    
}

.secTotalCliente {
    float: right !important;
    text-align: right;
    padding-left: 200px;
}



.secTotalCliente .div-totalNomina h1,
.secTotalCliente .div-total h1 {
    font-size: 16px !important;
    font-weight: 900;
}

.secTotalCliente .div-totalNomina h2,
.secTotalCliente .div-total h2 {
    font-weight: bolder;
    font-size: 18px !important;
    margin-top: 16px !important;
}


.secTotalCliente .div-totalNomina h3, 
.secTotalCliente .div-total h3 {
    font-size: 18pt !important;
    font-weight: 900;
    min-width: 120px;
    text-align: right;

}

.secTotalCliente .tagTotal{
    margin-top: 3px;
}
.mdlFrm-logo {
    display: inline-block;
}


.mdlFrm-logo .ant-form-item-label {
    text-align: center;
}

.mdlFrm-logo   > .ant-form-item-label{
    width: 100% !important;
}
.mdlFrm-logo .ant-upload.ant-upload-select-picture-card{
    text-align: center;
}

.modal-rubro{
    max-width: 500px !important;
}

.modal-empleado-tajos{
    max-width: 700px !important;
}

.txt-descripcion{
    min-height: 120px !important;
}
.info-empleado-pago{
    position: relative !important;
    bottom: 5px !important;
}


.frm-cliente .ant-row > .ant-col{
    margin-top: 30px;
}

.frm-cliente .ant-form-item-label,
.frm-cliente .ant-form-item-control
{
    margin: 0px !important;
    padding: 0px !important;
    margin-bottom: 10px !important;
}

.modal-form .arrow-select{
    position: relative;
    top: 0px;
}

.modal-form .ant-input-group-addon{
    border: none !important;
    width: 43%;
    text-align: left;
    background-color: transparent;
    padding-top: 4px;
}

.modal-form .ant-input-group-addon .ant-select{
    width: 115%;
}

.modal-form .ant-input-group-addon .ant-select-selector{
    padding-right: 0px;
    width:100%;
}

.modal-form .ant-input-group-addon .ant-select-selection-item{
    min-width: 90px;
    padding-right: 0px;
}



.modal-recordatorio .lblTitulo {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #A4A4A4;
}

.modal-recordatorio .pInfo {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 3px;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    color: #000000;
}


.modal-recordatorio .pUser {
    margin-top: 25px !important;
    margin-bottom: 15px !important;
    font-size: 15px;
}

.modal-detCalendario .ant-modal-header,
.modal-detCalendario .ant-modal-close,
.modal-detCalendario .ant-modal-footer
{
    display: none;
    visibility: hidden;
}


.modal-detCalendario .closable-button{
    left: 12px !important;
}

.modal-detCalendario .modal-title  {
     left: 50px;
     width: calc(100% - 100px);
     margin:0px;
     padding-bottom: 15px;
}


.modal-recordatorio,
.modal-cliente,
.modal-detCalendario
{
    width: 90% !important;
    max-width: 1200px !important;
    z-index: 100 !important;
}

#formulario-recibo .ant-col {
    margin-bottom: 25px;
}


.modal-form .lblTitulo{
    margin-bottom: 6px;
}

#formulario-recibo .lblTitulo {
    color: #AAA;
}


#formulario-recibo .pInfo {
    margin-top: 30px !important;
    margin-bottom: 50px;
    font-weight: bold;
}

#formulario-recibo  .ant-form-item-control {
    margin-bottom: 0px;
}

.modal-recibo   .modal-title {
    width: 78%;
    margin-left: 70px;
}

/* --------------------MODAL PARA GRUPOS POR NOMINA------------------------- */

.modal-grupos .modal-header{
    display: flex;
}

.modal-grupos .modal-header .modal-title{
    display: block;
    margin: auto;
}

.modal-grupos .modal-header .closable-button{
    border:solid 1px red;
    height: 80%;
    border:none;
}

.modal-grupos .modal-info-responsable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}
.modal-grupos .modal-row-header{
    background-color: #cccccc;
    text-align: center;
    border-radius: 8px;
    margin-top: 15px;
    padding-top: 0.5rem;
}

.modal-grupos .modal-row-body{
    text-align: center;
    margin-top: 15px;
}

.modal-grupos .modal-row-footer{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.modal-grupos hr{
    border:none;
    border-top: solid 1px #aaabbb;
}

/* --------------------MODAL ADMINISTRAR TAJOS------------------------- */

.modal-form-large {
    max-width: 900px !important;
}

/* --------------------MODAL  PONDERACION ------------------------------ */

.modal-form .row .ant-form-item-control-input-content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-form .row .ant-col{
    margin:0;
}

.modal-form  .form-select .ant-select-selection-item{
   padding-top: 5px !important;
}

.modal-form  .form-select .ant-select-selection-item{
    max-height: 70px;
}

.modal-form  .form-select.sm .ant-select-selection-item{
    max-height: 50px;
}

.modal-form  .form-select .ant-select-selector{
    max-height: 40px !important;
}

.modal-form .form-select .ant-select-selection-placeholder{
    z-index: 100;
}

.modal-form .tiny-input{
    max-width: 40%;
    margin-right: 1rem;
}

.modal-form .modal-hr-blue{
    border-color: #0047ff;
    width: 80%
}

.modal-tajo .ant-modal-content{
    max-width: 600px;
    display: block;
    margin: 0px auto;
}
#formulario-tajo{
    position: relative;
    left: 8px
}


.modal-obras{
    -webkit-animation-duration: 0.45s;
            animation-duration: 0.45s;
    
}


/*Modal QR*/
.modal-qr .modal-header{
    margin-bottom: 25px !important;
}

.modal-qr .modal-title{
    width: 300px;
    margin: auto;
    margin-top: -40px !important;
    margin-bottom: 10px !important;
}

.modal-qr .ant-result-success{
    padding-top: 0px;
}
.modal-qr .ant-result-icon{
    margin: 30px !important;
}
.modal-qr .ant-result-subtitle{
    font-size: 15pt !important;
    width: 80%;
    margin: auto;
    margin-bottom: 30px !important;
}

.modal-qr .btn-azul{
    height: 40px !important;
    font-size: 13pt !important;
}




/*Modal ordenes material*/
.modal-ordenMaterial{
    /* max-width: 1000px !important; */
}

.modal-ordenMaterial .title-date{
     float: left;    
     margin-right: 10px;
}

.modal-ordenMaterial .col-date strong{
    margin-top: 3px;
    float: left;
    font-size: 13pt;
}

.modal-ordenMaterial .component-list .ant-typography-secondary{
    color:#777 !important;
    font-size: 12pt !important;
    font-weight: bolder;
}


.modal-ordenMaterial .row-itemList-ordenesMaterial{
    padding-top: 5px;
}

.modal-ordenMaterial .ant-modal-body{
    height: auto;
    overflow: auto;
    padding-bottom: 0px;
}

.modal-ordenMaterial{
    background: white;
    padding-bottom: 0px !important;
}

.modal-ordenMaterial .ant-modal-content{
    border-radius: 5px !important;
    padding-bottom: 5px;
}

.modal-ordenMaterial .ant-list-items{
    max-height: 580px !important;
    overflow: auto !important;
}

/*MODAL CARROUSEL DE TRANSACCIONES BELVO*/

.modal-form .btn-carrousel{
    width: 231px;
    height: 38px;
    background: linear-gradient(91.52deg, rgba(0, 71, 255, 0.62) 27.94%, rgba(0, 70, 250, 0.62) 92.2%);
    box-shadow: 0px 10px 30px rgba(15, 19, 26, 0.3);
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
}

.modal-form .btn-carrousel .ant-typography{
    color: white;
    font-weight: bold;
}

.modal-form .btn-carrousel .btn-arrow{
    background: transparent;
    padding-left: 4px;
    padding-right: 4px;
    color: white;
    border: none;
}

.modal-form .btn-carrousel .ant-carousel{
    height: 38px;
    width: 130px;
    text-align: center;
    padding-top: 0.5rem;
}

.pb-w-sync_modal-container{
    z-index: 10000000 !important;
}


.btn-minusModal {
margin-top: 40px !important;    
margin-left: 10px;
}

.btn-minusModal svg{
    color: #FF547D !important;
    width:  20px;
    height: 20px;
    float: right;
    right: 0;
    display: block;
    position: relative;
}

/*--------------------------------------------MODAL REQUESISION DETALLE (CHAT)-----------------------------------------------------*/
.modal-form.modal-detalle-chat{
    max-width: 1000px;
}

.modal-form.modal-detalle-chat .ant-modal-content{
    border-radius: 22px;
}

.modal-form.modal-detalle-chat .ant-modal-body{
    padding: 0;
    min-height: 400px;

}

.modal-form.modal-detalle-chat .chat-side{
   height: 100%;
   border: solid 2px #0246F4;
   border-radius: 0 22px 22px 0;
   overflow: hidden;
}

.modal-form.modal-detalle-chat .chat-side .title-chat{
   background: #0246F4;
   border-radius: 0 15px 0 0;
   padding: 18px 0;
   color:  white;
}

.modal-form.modal-detalle-chat .col-text{
    padding: 2rem;   
}

.modal-form.modal-detalle-chat .col-text .ant-col{
    margin-bottom: 10px !important;   
}

.modal-form.modal-detalle-chat .col-text .tag{
    min-width: 114px;
    height: 34px;
    text-align: center; 
    border-radius: 11px;
    line-height: 33px;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
}

.modal-form.modal-detalle-chat .col-text .modal-title{
    font-weight: 500;
    font-size: 20px;
}

.modal-form.modal-detalle-chat .col-text .ant-form-item-label{
    margin-bottom: 0px !important;
    padding-bottom: 0px;
}

.modal-form.modal-detalle-chat .col-text .ant-form-item-label label{
    font-weight: 400;
    font-size: 12px;
    color: #696969;
}

.modal-form.modal-detalle-chat .col-text .ant-form-item-control-input-content .ant-typography{
    font-weight: 500;
    font-size: 14px;
   
}

.button-syncfy{
    width: 14px!important;
    height: 14px!important;
    min-width: unset !important;
    position: absolute;


}
.button-syncfy .icon {
    font-size: 10px;
    position: relative;
    bottom: 6px;
}

.button-delete-syncfy{
    right: 10px;
    top: 7px;
}

.button-update-syncfy{
    right: 30px;
    top: 7px;
}


.ant-select-selector .button-syncfy {
    display: none;
}

/* .button-delete-syncfy {

} */

.card-proyecciones{
    margin:10px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 20px;
}

.btn-bag, .btn-sales{
    border: solid 1px rgba(255, 255, 255, 0.5) !important;
    height: 50px;
    width:  50px;
    border-radius: 100px;
    padding: 0px;
    float: right;
}

.btn-bag{
    padding-top: 8px;
    background: #A233F9 ;
}

.btn-sales{
    padding-top: 5px;
    background: #00FF57;
}

.btn-bag:active,.btn-bag:focus, .btn-bag:hover {
    background: #c482f7 ;
}

.btn-sales:active,.btn-sales:focus, .btn-sales:hover {
    background: #77f8a2;
}


.card-proyecciones h4 {
    width: calc(100% - 40px);
    color:#858997;
}

.card-proyecciones .IconMenuDot{
    background: #FFF;
    border: none;
    width: 30px;
    height: 25px;
    padding: 0px;
    display: block;
}

.card-proyecciones h2 {
    width: calc(100% - 55px) !important;
    color:#000;
    font-weight: bolder;
    float: left;
}

.card-proyecciones .ant-row{
    margin:15px
}

.IconArrowBack{
    background:transparent;
    border: none;
    width: 50px;
    height: 30px;
    padding: 0px;
    display: block;
    float: left;
    margin-right: 10px;
}
.IconArrowBack:active,.IconArrowBack:focus, .IconArrowBack:hover{
    background:transparent;
    border: none;
}

.blue-title{
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 3px;
    color: #0047FF;
}

.card-proyeccionesDetalle{
    border-radius: 15px;
}

.card-proyeccionesDetalle h1{
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 3px;
    color: #0047FF;
}

.card-proyeccionesDetalle .ant-col{
    margin: 35px 0px;
}

.card-proyeccionesDetalle .lblTitulo{
    width: 100%;
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #A4A4A4;
    margin-bottom: 10px;
}

.card-proyeccionesDetalle .pInfo{
    
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 3px;
    color: #000000;
    width: auto;
    float: left;
    margin-top: 10px;
    cursor: default;
}

.card-proyeccionesDetalle .ant-avatar{
    float: left;
    margin-right: 20px;
}

.card-proyeccionesDetalle .card-svg-open{
    margin-top: 8px;
    margin-left: 8px;
    cursor: pointer;
}


.ant-table-cell .lblTitulo{
    font-weight: 800 !important;
    color: #000;
}
.drawer-reminders .ant-drawer-content-wrapper{
    width: 350px !important;
}


.drawer-reminders .drawer-title{
    width: -webkit-min-content!important;
    width: min-content!important;
    display: inline;
    position: relative;
}


.drawer-reminders .drawer-subtitle{
    position: absolute;
    left: 0;
    bottom: -1.8em;
    font-size: 14px;
}



.drawer-reminders .closable-button{

    position: relative;
    bottom: 8px;
    float: right

}


.drawer-reminders .reminder-item .ant-tag-magenta{
    float: right;
    min-width: 64.5px;
    position: relative;
    left: 7px;
    text-align: center;
}


.icon-button{
    position: absolute;
    top: 3em;
    right: 1.8em;
    margin-left: 3px ;
}

.drawer-reminders .reminder-item .ant-btn{
    margin-left: 4px;
}
.icon-button-container{

    position: absolute;
    top: 3em;
    right: 1.8em;

}

.drawer-reminders .ant-list-item{
    position: relative;
}
.btn-proCal 
  {
    width:  30px !important;
    height: 30px !important;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0px;
    padding-top: 5px;
    border:solid 1px rgba(255, 255, 255, 0.164);
}

.btn-proCal,
.btn-proCal:hover,
.btn-proCal:focus {
    background-color: #492AF9 !important;
}

.lblProyecto{
    font-weight: 700;
    margin-left: 10px;
    font-size: 12pt;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 65px;
}



.ant-popover{
    z-index: 3000 !important;
}

.events{
    padding: 0;
}

.crm-card {
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 10px;
}

.crm-card.card-ventas-totales{
    background: linear-gradient(275.68deg, #39D0BB -41.13%, #00e431 100.89%);
}

.crm-card.card-ventas-cobrar{
    background: linear-gradient(275.72deg, #7B39D0 15.67%, #AD00FF 100.87%);

}

.crm-card.card-cobrado{
    background: linear-gradient(275.68deg, #5404FF -41.13%, #00A3FF 100.89%);

}

.crm-card.card-montos-vender{
    background: linear-gradient(275.68deg, #C50F9D -41.13%, #FF00C7 100.89%);

}

.crm-card .crm-stats .ant-statistic-title{
    color: white;
    font-size: 22px;
    font-weight: bold;
}


.crm-card .ant-card-head{
    border: none !important;
    font-weight: 600;
    font-size: 25px;
    line-height: 24px;
    color: #858997;
    margin-top: 0.5em;
}

.pInfo.document{


    height: 90px;
    min-width: 200px;
    overflow: auto;
    /* border-left: 1px solid rgba(0,0,0,0.2);
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.2); */
    padding-left: 11px;

}

.link-document{
    display: block;
    margin-bottom: 5px;
    font-size: 13px!important;
    letter-spacing: 1px;
    font-weight: 400;

}

.icon-element {
    position: relative;
    left: 10px;
    top: 5px;
}

.icon-element svg{
    width: 14px!important;
 
}

.btn-add-propiedad,
.btn-add-propiedad:hover,
.btn-add-propiedad:focus{
    background: #096DD9;
    border: 1px solid #096DD9;
    box-sizing: border-box;
    border-radius: 2px;
    color:  white;
}

.btn-add-propiedad *{
    color:  white;
}



@media (min-width: 1200px) and (max-width: 1420px) {

    .card-proyeccionesDetalle .pInfo {
        font-size: 12px !important;
        letter-spacing: 1px !important;
    }    
}



.dropzone-element {
    width: 104px;
    height: 104px;
    background-color: #FAFAFA;
    margin: 0 8px 8px 0;
    padding: 8px;

    position: relative;
}


.dropzone-element.dropzone-child {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.dropzone-element .ant-avatar {
    display: block;
    /*margin: 0px auto;*/
    width: 86px;
    height: 86px;
}


.dropzone-element.main-dropzone {
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    border: 1px dashed #D9D9D9;


    border-radius: 4px;

}


.dropzone-element.main-dropzone:active,
.dropzone-element.main-dropzone:focus {
    outline: none !important;
    border: 1px dashed #1890FF !important;
    cursor: pointer;
}


.dropzone-element.main-dropzone .main-dropzone-title {
    font-weight: 400;
    margin-top: calc(50% - 30px);
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 1.8em;
}


.dropzone-element.main-dropzone:hover {
    border: 1px dashed #1890FF !important;
    cursor: pointer;
}


.dropzone-element .thumb-menu {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 86px;
    height: 86px;
    background: rgba(0, 0, 0, 0);
    transition: all 0.2s ease-in;
}

.opacity-button{
    opacity: 0;
    padding: 5px;
}

.dropzone-element .thumb-menu:hover {
    background: rgba(0, 0, 0, 0.55);
}

.dropzone-element .thumb-menu:hover > .ant-space .opacity-button{
    opacity: 1;
    transition: all 0.2s ease-in;

}
.dropzone-element .thumb-menu .ant-space{
    width: 86px;
    justify-content: center;
    margin-top: calc(50% - 20px);
}

.modal-form .closable-button{
    left: 30px;
    top: 30px;
}






/********* CSS DE LISTA***********/

.file-list{
    width: 100%;
}

.file-list .ant-list-item {
    padding: 4px 0;
}

.file-list .ant-list-item-meta-title{
    text-align: start;
}


.file-list .file-name{
    text-decoration: underline;
}

/*vertical-align: middle;*/


.btn-deleteFile,
.btn-deleteFile:active,
.btn-deleteFile:hover,
.btn-deleteFile:focus
{
    color: red;
    float: right;
    top: -30px;
}
/*
	CSS DE CARDS.JS
*/

.PM-card-dashboard,
.PM-card-progress{
	border-radius: 10px;
	border: none;
	box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
}

.hover-effect:hover {
	background: #d7d7d7;
	cursor: pointer;
}

.hover-effect:hover *{
	color: white !important;
}


.PM-card-dashboard .ant-card-body{
	padding-top: 0.5rem;
	padding-bottom: 0.6rem;
}

.PM-card-dashboard .PM-card-title{
	color: white;
	margin-bottom: 1.5rem;
	font-size: 20px;
	font-weight: bold;
}

.PM-card-dashboard .PM-card-body{
	color: white;
	text-align: center;
	font-size: 20px;
}

.PM-card-progress .card-progress-header{
	min-height: 80px;
}

.PM-card-progress .card-progress-title{
	margin-bottom: 0px;
	font-size: 12px;
	color: #858997;

}

.PM-card-progress .card-progress-name{
	margin-bottom: 0px;
	font-size: 20px;
	font-weight: 600;
	color: #0A0A0A;

}

.PM-card-progress .card-progress-dates-col{
	display: flex;
	justify-content: center;
	padding: 6px 15px;
}

.PM-card-progress .card-progress-dates{
	font-size: 12px;
	color:#858997;
}

.PM-card-progress .card-progress-bar{
	padding: 6px 15px;
}

.PM-card-progress .card-progress-avatar{
	display: flex;
	justify-content: center;
	align-items: center;
}

.PM-card-activity .card-activity-main
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.PM-card-activity{
	margin-bottom: 1rem;
	border-radius: 10px;
}

.PM-card-activity .activty-tag{
	font-size: 12px;
	color: #0047FF;
	border-radius: 10px;
	min-height: 14px;
	min-width: 60px;
	text-align: center;
}

.PM-card-activity .card-activity-main{
	padding: 5px;
}

.PM-card-activity .card-activity-name{
	font-size: 16px;
	font-weight: 400;
	color: #141821;
	margin: 0px;
}

.PM-card-activity .card-activity-date{
	font-size: 14px;
	font-weight: 400;
	color: #858997;
	margin: 0px;
}

/*CSS DEL COMPONENTE CardInfo y CardProgressBar*/
.PM-card-info,
.PM-card-bar{
	min-height: 100px;
	height: 100%;
	border-radius: 10px;
	background: white;
	padding: 0.5rem;

}

.PM-card-info.hoverable:hover{
	background: rgb(191,191,191)!important;
}


.PM-card-info .card-info-titles,
.PM-card-bar .card-bar-main{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 30px;
	font-size: 11px;
	text-align: center;
}

.PM-card-info .card-info-infos,
.PM-card-bar .card-progress-bar{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 60px;
	text-align: center;
}

.PM-card-info .card-info-title,
.PM-card-bar .card-bar-title{
	color: #858997;
}

.PM-card-info .card-progress-name{
	color: #0A0A0A;
	font-weight: 600;
}

.PM-card-info .card-progress-name a{
	color: #0A0A0A;
	font-weight: 600;
}


/*CSS DEL COMPONENTE CardProgressBar*/
.PM-card-bar{
	height: 100%;
	border-radius: 10px;
}

.PM-card-bar .card-progress-bar{
	padding: 0px 24px;
}

/*CSS DEL COMPONENTE CardProject*/
.PM-card-project{
	min-height: 200px;
}


.PM-card-dashboard .quantity-title{
	text-align: center;
	color: white;

}

.PM-card-project .card-progress-header{
	min-height: 120px;
	padding-top: 0.5rem;
}
.PM-card-project .button-delete-project{

	position: absolute;
    right: -9px;
    top: -9px;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 100%;
    background: red;
    color: white;
    font-weight: bold;
    border: none;


}

.center-info{
	position: absolute;
	top: 25%;
	left: 65%;
}


.btn-admin{
	background: #19E08D;
	width: 118px;
	height: 28px;
	border-radius: 13px;
	border: none;
	font-weight: 500;
}


/*----------------------------------CARD DE PROVVEDORES------------------------------------------*/

.card-proveedores{
	border-radius: 10px;
	border: none;
	box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
	height: 100%;
	margin-bottom: 0.5rem;
}

.card-proveedores .ant-card-body{
	padding: 1rem 1.5rem;
	display: flex;
    flex-flow:column;
    height: 100%;
}

.card-proveedores .content-title{
	flex: 1 1 auto;
	min-height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5rem;
}

.card-proveedores .card-title{
	font-weight: 600;
	font-size: 12px;
	color: #858997;
}

.card-proveedores .title-proveedor{
	font-weight: 600;
	font-size: 20px;
	color: #0A0A0A;
}

.card-proveedores .text-data-title{
	font-weight: bold;
	font-size: 12px;
	color: #858997;
	display: block;
	text-align: center;
}

.card-proveedores .text-data{
	font-weight: 300;
	font-size: 12px;
	color: #858997;
	display: block;
	text-align: center;
}

.card-proveedores .badge-facturas{
	border-radius: 10px;
	font-size: 10px;
	height: 14px;
	line-height: 12px;
	font-weight: bold;
}

.card-proveedores .text-concepto{
	font-size: 16px;
	line-height: 24px;
	color: #141821;
	display: block;
}
.card-proveedores .text-proveedor{
	font-size: 16px;
	line-height: 24px;
	color: #141821;
	font-weight: 300;
	display: block;
}

.card-proveedores .text-monto{
	font-size: 14px;
	color: #858997;
}

.download-btn-card{
	position: absolute;
	top: 0;
	right: 6px;
}

.download-btn-card-2{
	position: absolute;
	top: 0;
	right: 40px;
}


/*----------------------------------CARD TRANSACCIONES SIN CLASIFICAR---------------------------------------------*/
.card-transacciones{
	border-radius: 10px;
	border: none;
	height: 100%;
}
.card-transacciones .ant-card-body{
	padding: 0.5rem 1rem;
}

.card-transacciones .text-concepto,
.card-transacciones .text-proveedor{
	display: block;
}

/*----------------------------------CARD EMPRESA--------------------------------------------*/

.card-project .ant-card-head,
.card-task-progress .ant-card-head,
.card-address .ant-card-head,
.card-utilidad .ant-card-head {
    padding: 0rem 1rem;
    border-bottom: 0;
    height: 20px;
}

.card-project .ant-card-body,
.card-task-progress .ant-card-body,
.card-address .ant-card-body {
    padding: 0 1rem 1rem 1rem;
}

.card-project {
    overflow: auto;
    margin-right:1rem ;
    border-radius: 10px;
}

.card-project .ant-card-head-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #121212;
}


.card-rs-blue{
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #2D348C;
}

.card-rs-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #121212;
}

.card-rs-cantidad{
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #121212;
    margin-top: 4px;
}

.texto {
	font-size: 10px;
}


.card-proyecto .ant-card-body{
	display: grid;
}
.PM-card-dashboard .PM-card-title {
    margin-bottom: 0 !important;
}



.statistic-financial{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-60%);
            transform: translateY(-60%)
}


.statistic-financial.net-worth{

    text-align: center;
    position: absolute;
    top: 55%;
    left: 18%;
    /*transform: translateY(-50%) translateX(-50%);*/
}

.statistic-financial.net-worth .ant-statistic-content-value,
.statistic-financial.net-worth .ant-statistic-content-prefix,
.statistic-financial.net-worth .ant-statistic-content-suffix {
    color: white !important;
    font-size: 30px !important;
}




.financial-health .PM-card-dashboard{
    height: 100%;
}

/*CSS DEL HEADER DE NOMINAS*/
.row-encabezado .input-picker {
    background: none;
}

.row-encabezado .input-picker svg {
    color: white;
}

.dropdown-nominas{
    max-height: 300px;
    overflow-x: auto; 
}

.btn-transparent{
    background: transparent;
    border-color: #fff;
    color: #fff;
}
.dropdown-transparent{
    border: none;
    background: transparent;
    color: #fff;
}
.dropdown-transparent:hover,
.dropdown-transparent:focus,
.dropdown-transparent:active{
    border: none;
    background: transparent;
    color: #fff;

}
.row-encabezado .input-picker input {
    color: white;
}

.row-encabezado .input-picker .ant-picker-clear {
    background: none;
}

.row-encabezado .input-picker input::-webkit-input-placeholder {
    color: white;
}

.row-encabezado .input-picker input::placeholder {
    color: white;
}

.text-gray{
    color: #858997!important;
}
/** CSS DE LA CARTAS **/
/*Card general*/
.content .content-card {
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 10px;
    position: relative;
    height: 100%;
    min-height: 135px;
}

/*Titulo de las cartas pequeño*/
.card-auth-text{
     color: #0A0A0A;
     width: 100%;
     line-height: 1rem;
}
.content-tiny-title{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.card-monto-title {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #858997;
    display: block;
    font-size: 24px;
    margin-bottom: 16px;
}
.card-monto-monto{
    padding: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
    text-align:center;
    display: block;
    /* font-size: 20px  */
}

.monto-red{
    color: #FD0707!important;
}
.monto-green{
    color: #00CC14!important;
}
/*Boton de "Administrar"*/
.content .content-card .content-button-manage {
    border-radius: 200px;
    color: #00CD98;
    width: 100%;
    border-color: #00CD98;
}

/*Boton de "ir a"*/
.content .content-card .button-link-to {
    position: absolute;
    top: 15px;
    right: 5px;
}

/*Elementos de la lista !**/
.content .list .list-item {
    margin-top: 5px;
    border: none;
    border-radius: 10px;
    padding: 10px 10px 0px 10px;
}


/*Titulo de los elementos de la ista*/
.content .list .item-title {
    color: #141821;
}

/*Descripcion de los elementos de la insta*/
.content .list .item-description {
    color: #858997;
}


/*AdministrarNomina.js*/

.card-cargar{
    text-align: center;
    position: relative;
}

.card-cargar .tag-cargar-1,
.card-cargar .tag-cargar-2{
    position:  absolute;
    right: 0.5rem;
    width: 50px;
    border-radius: 5px;
    padding: 1px;
    font-size: 14px;
}

.card-cargar .tag-cargar-1 svg,
.card-cargar .tag-cargar-2 svg{
    height: 12px;
    margin-right: 4px;
}

.card-cargar .tag-cargar-1{
    top: 0.5rem;
}

.card-cargar .tag-cargar-2{
    top: 2.5rem;
}

/*Carta de Nomina de la seccion*/
.content .content-card-payroll {
    background:   rgba(16 83 255 / 0.73);
}

/*Texto de la carta de nomina del grupo*/
.content .content-card-payroll * {
    color: white !important;
}

.content .content-card-payroll.authorized{
    opacity: 0.7;
}

.content .content-card-payroll.authorized:hover{
    opacity: 1 !important;
}


/*Botones de las cartas de grupos*/
.content .button-group-card {
    height: 40px;
    border-radius: 10px;
    vertical-align: middle;
}

/*Buton de Registrar*/
.content .button-group-card.button-register {
    background: #9131F0;
}


/*Buton de Registrar*/
.content .button-group-card.button-unregistered{
    background: #86827e;
}


/*Buton de Registrar*/
.content .button-group-card.button-unregistered svg{
    position: relative;
    top: 3px
}

.content .text-money-card{
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    height: 40px;
}


.content .button-group-card.button-register span,
.content .button-group-card.button-unregistered span{
    position: relative;
    bottom: 0px;
}


/*Icono del boton*/
.content .button-authorize .icon-button {
    color: white;
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
    right: 5px;
}

.content .button-group-card.button-authorize {
    background: #40F031;
}


.content .button-group-card.button-no-authorize {
    background: #d4d824;
}
/*Buton de revisar*/
.content .button-group-card.button-review {
    background: #C3C6D1;
}


/** RevisionDeGrupo.js  */
/*Heder de la informacion del grupo*/
.content .content-card .information-content {
    padding-top: 4px;
}


/*Para el header de la seccion de reivsar grupo*/
.content .content-card .information-content .information-content-title {
    display: inline;
    margin-right: 1em;
    color: #A4A4A4;
    font-size: 18px;
    font-weight: 400;
    float: right;
}

/*Avatar de la seccion de informacion del grupo*/
.content .content-card .information-content .information-content-avatar {
    position: relative;
    bottom: 6px;
    float: right;
}


/*Seccion de informacion especifica del grupo, la que estaá abajo del titulo,.*/
.content .content-card .information-widgets-content {
    margin-top: 2em
}

/*Titulo de la informacion especifca del gruopo*/
.content .content-card .information-widgets-content .information-widgets-content-title {
    margin-bottom: 2em;
    color: #A4A4A4;
}

/*Desrcipion de la información especifca del grupo*/
.content .content-card .information-widgets-content .information-widgets-content-description {
    margin-top: 1em;
    color: #000000;
    font-weight: bold;
    letter-spacing: 2px;
}


/*Esto es el elemento ListItem, nada mas es espacio entre los rows*/
.content .list-container .list-employees .list-employees-item-content {
    padding: 6px 0;
    position: relative;
}


/*Este ya es el row del la lista de empleados*/
.content .list-container .list-employees .list-employees-item-content .list-employee-item {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10px 10px 10px 30px;
    box-shadow: 0px 5px 15px rgba(209, 213, 223, 0.2);
}



.content .list-container .list-employees .list-employees-item-content .list-employee-item.list-employee-financial-item > .ant-col{
    width: 20%!important;
    max-width: 20%;
}

/* PARA LOS INPUTS FANTASMONES*/
.input-ghost {
    color: #222222;
    border: none;
}


/*GRUPOS.js*/

/* SOLAMENTE PARA LA ANIMACION DEL ICONO*/

/*.group-collapse .plus_icon {
    top: -88px;
}*/
.plus_icon {
    position: relative;
    width: 5px;
    height: 5px;
   /* margin: 100px auto;*/
    z-index: 400;
}

.plus_icon:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon:after {
    content: "";
    position: absolute;
    margin-top: -5px;
    margin-left: 5px;
    width: 2px;
    height: 12px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon.open:after {
    margin-top: 0px;
    width: 2px;
    height: 2px;
}

.plus_icon:before,
.plus_icon:after {
    transition: all 0.3s ease;
}

.group-collapse {
    background: none;
}

.group-collapse .group-collapse-panel {
    background: rgba(183, 203, 232, 0.34);
    border-radius: 8px !important;
    margin-bottom: 1em;
}

.information-destajo .ant-col-4 {
    min-width: 20%;
}

.group-collapse .group-collapse-panel .ant-collapse-content {
    background: white;
}

/*--------------BOTONES DE LA LISTA ------------------------------------------------*/

.content .group-collapse .button{
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0;
    padding-top: 5px;

}

.content .group-collapse .button.button-people {
    background: #C345FF;
    border-radius: 5px;
   /* position: relative;
    top: 2px;*/
}
/*
.content .group-collapse .button.button-people svg{
    position: relative;
    top: 3px;
}*/

.content .group-collapse .button.button-plus {
    background: #ADFF45;
    border-radius: 5px;
}

.content .group-collapse .button.button-edit {

    background: #456EFF;
    border-radius: 5px;


}

.content .group-collapse .button.button-copy-group{
    background: #ffd954;
    border-radius: 5px;

}

.content .group-collapse .button.button-delete {
    background: #FF547D;
    border-radius: 5px;
}

/*------------------CONTENIDO DE LA LISTA------------------*/
.content .group-collapse .center-left{
    display: flex;
    align-items: center;
    min-height: 35px;
}

.content .group-collapse .group-title{
    font-size: 16px;
    color: #272727;
    margin: 0;

}

.content .group-collapse .group-description{
    font-size: 14px;
    color: #0047FF;
    margin:0;
}
.content .group-collapse .list-employees{
    position: relative;
    left: 44px;
}

.content .group-collapse .tag-element{
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
}

.content .group-collapse .tag-element:hover{
    cursor: pointer;
}



.btn-save-financial{
    display:block;
    position:fixed;
    bottom:10px;
    right:10px;
    z-index: 1;
    border-radius: 100px;



    background: linear-gradient(126.63deg, #A074FF 11.98%, #000AFF 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);




    width: 80px;
    height: 80px;
}


.project-selection {
    min-width: 200px;
}

.btn-save-financial:hover{
    background: linear-gradient(126.63deg, #A074FF 11.98%, #000AFF 83.35%)!important;
}


.number-element{
    position: absolute;
    padding-top: 1px;
    top: 30%;
    left: -10px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background: #ff8080;
    text-align: center;
    color: white;
}

/*position: relative;*/
/*left: 40px;*/
/*}*/

/* Boton para cambiar tipo de modalidad  */
.toogle-nominas-checkbox .ant-radio-button-wrapper{
    background: transparent;
    border: 1px solid #fff;
    color: #fff;

}
.toogle-nominas-checkbox .ant-radio-button-wrapper:hover{
    background: #fff;
    color: #000 !important;
}
.toogle-nominas-checkbox .ant-radio-button-wrapper-checked{
    background: #fff ;
    color: #000000;
}


.image-preview{
    width: 150px;
    margin-right: 10px;
    border-radius: 5px;
}

.image-preview-empleado{
    width: 100%;
    margin-right: 10px;
    border-radius: 5px;
}


.slider-element .ant-carousel {
    max-width: 100%;
}


.image-previewer{
    margin-top: 10px;
    width: 100%!important;
    max-width: 1000px;
}

.slick-dots-bottom li {
    box-shadow: 2px 2px 5px 0px rgba(199,199,199,1);
    position: relative;
    top: 40px
}

.image-previewer.empleado .ant-modal-body{
    position: relative;

}

.carousel-button{
    border-radius: 100%;
    box-shadow: 3px 3px 15px 0px rgba(209,209,209,1);
    opacity: 0.4;

    border: 1px solid white!important;

    position: absolute;
    top: calc(50% - 20px);
    background: none!important;
}

.carousel-button:hover{
    opacity: 1;
    border: 1px solid white;
}

.carousel-button.left-control{
    left: 40px;
}

.carousel-button.right-control{
    right: 40px;
}



.spinner-element .ant-spin-nested-loading{
    width: 100%;
}


.nomina-selected{
    background-color: rgba(255, 255, 255, 0.2) !important;
}


/* NOMINAS V3 */

.list-inmuebles-revision{
    width: 100%;
}
.list-inmuebles-revision  .ant-list-header{
    padding: 0;
    margin: 0;
}

.list-inmuebles-revision .ant-list-item{
    padding: 16px 4px;
}

.title-row{
    text-transform: uppercase;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #222222;


}
.borderless{
    border: none!important;
    margin: 0;
}
.title-inmueble-revision{
    padding: 8px;
}
.border-radius{
    border-radius: 10px!important;
}
.item-render{
    padding:5px
}
.item-tag{
    background: #A906F5;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
  
}
.item-tag .title-row{
    color: #fff;
}
.item-row{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;


}

@media screen and (min-width:576px){
    .item-tag{
        width: 80%;
    }
}

/* Revisión Preeliminar */
.finish-nomina {
    position: fixed;
    bottom: 0;
    right: 4em;
    font-size: 1.4em;
    border-radius: 8px 8px 0px 0px;
    
    background: linear-gradient(180deg, #2bd600 0%, #00de59 100%);
    
    box-shadow: 0 -.125rem .25rem rgba(0,0,0,0.075)!important;
    font-weight: bold;
    border: none !important;
}

.finish-nomina:hover {
    background: linear-gradient(180deg, #36F905 0%, #00FF66 100%) !important;
}

.finish-nomina:focus {
    background: linear-gradient(180deg, #2bd600 0%, #00de59 100%) !important;
}

.dropzone-element {
    width: 104px;
    height: 104px;
    background-color: #FAFAFA;
    margin: 0 8px 8px 0;
    padding: 8px;

    position: relative;
}


.dropzone-element.dropzone-child {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.dropzone-element .ant-avatar {
    display: block;
    /*margin: 0px auto;*/
    width: 86px;
    height: 86px;
}


.dropzone-element.main-dropzone {
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    border: 1px dashed #D9D9D9;


    border-radius: 4px;

}


.dropzone-element.main-dropzone:active,
.dropzone-element.main-dropzone:focus {
    outline: none !important;
    border: 1px dashed #1890FF !important;
    cursor: pointer;
}


.dropzone-element.main-dropzone .main-dropzone-title {
    font-weight: 400;
    margin-top: calc(50% - 30px);
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 1.8em;
}


.dropzone-element.main-dropzone:hover {
    border: 1px dashed #1890FF !important;
    cursor: pointer;
}


.dropzone-element .thumb-menu {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 86px;
    height: 86px;
    background: rgba(0, 0, 0, 0);
    transition: all 0.2s ease-in;
}

.opacity-button{
    opacity: 0;
    padding: 5px;
}

.dropzone-element .thumb-menu:hover {
    background: rgba(0, 0, 0, 0.55);
}

.dropzone-element .thumb-menu:hover > .ant-space .opacity-button{
    opacity: 1;
    transition: all 0.2s ease-in;

}
.dropzone-element .thumb-menu .ant-space{
    width: 86px;
    justify-content: center;
    margin-top: calc(50% - 20px);
}

.modal-form .closable-button{
    left: 30px;
    top: 30px;
}





/*vertical-align: middle;*/

.modal-form{
    border-radius: 10px;
    max-width: 600px ;
}

.modal-form .modal-title{
    /*padding-bottom: 30px !important;
    position: relative;*/
}

.modal-sub-title{

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #A4A4A4;
    text-align: center;

    margin-top: 1em;
    margin-bottom: 3em!important;
}

.buttons-selection{
    width: 100%;
    justify-content: center;
    margin: 0px !important;
}


.button-select{
    width: 100%;
    padding-bottom: 95%;
    position: relative;
    display: flex;
    justify-content: center;
    border: 2px solid #00CD98!important;
    box-sizing: border-box;
    border-radius: 20px!important;


}

.button-select-content{
    position: absolute;
    top: 15%;
}
.border-none{
    border: none!important;
}

.transition{
    transition: all 0.5s ease;
}


.buttons-selection-group{
    width: 100%;
}


.image-content{
    display: block;
    margin: 30px auto;
}


.continue,.continue:focus, .continue:hover{


    background: #0047FF;
    border: none;
    color: white!important;
    border-radius: 8px;
    padding: 6px 15px;
    margin: 4em auto 0 auto;
    display: block;
    outline: none!important;
    transition: all 1s ease-in-out;
    cursor: pointer;
}

.continue a{
    color: white!important;
}

.text-content{
    text-align: center;
    font-size: 18px;
}


.form-input{
    background: #E1E4ED;
    border-radius: 8px;

}
.label-question{
    width: 100%;
    text-align: center;


    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    color: #A4A4A4;

    padding-top: 2em;


}

.back-button{
    position: absolute;
    left: 16%;
    top: 2%;
}


.input-answer{
    width: calc(100% - 1.3em);
}


.input-answer-delete{
    font-size: 1.3em;
    margin-left: 0.5em;
    position: relative;
    top:  0.7em;
}

.icon{
    margin-top:30%;
}

/*.continue:hover{*/

/*    background: linear-gradient(90deg, #9A4F91 0%, #76236C 102.04%);*/
/*    border: none;*/
/*    color: white;*/
/*    border-radius: 8px;*/

/*}*/

.modal-form .modal-form-content .ant-select-selection-item,
.modal-form .modal-form-content .ant-select-selection-placeholder
{
    margin-top: 5px;
}

.modal-form .modal-form-content .arrow-select{
    position: relative;
    top: 15px !important;
}


.modal-form  .slick-list{
    height: auto!important;    
}
.table-deudas .ant-table-body{
    min-height: 150px;
}
/*/*
	CSS QUE CORRESPENDE A EL MODULO DE PROJECT MANAGER Y SUS COMPONENTES
*/

/*--------------------------MODULO----------------------------*/
.carousel-pm {
	margin-bottom: 1rem;
}

.carousel-pm .col-carousel{
	padding: 0px 8px 0px 8px;

}

.next-activities-col .next-activities-header
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.next-activities-col .next-activities-header{
	margin-bottom: 1rem;
}

.next-activities-col .next-activities-title{
	color: #858997;
	font-size: 18px;
	font-weight: 600;
}

.btn-go,
.btn-go:focus,
.btn-go:hover{
	color: white;
	height: 30px;
	width: 30px;
	background: #0047FF;
	border-radius: 7px;
	padding: 5px 7px;
	border: none;
}

/*CSS DEL TITULO DE LISTA*/
.list-title{
	color: #0047FF;
	font-size: 20px;
	font-weight: 700;
	margin-left: 1rem;
	padding-bottom: 1rem;
}

/*CSS para la lista de Areas*/
.component-list-item{
	padding: 0 0 11px 0 !important;
}

.component-list-item * {
	/* color: #222222; */
}

.card-list{
	width: 100%;
	text-align: center;
	border-radius: 10px;
	font-size: 13px;
	margin: 0;
}

.card-list .ant-card-body{
	padding: 1rem;
}


/*CSS PARA LA TABLA GANTT*/
.card-table{
	border-radius: 10px;
	height: 98%;
}

.card-table .card-table-header{
	display: flex;
	justify-content: space-between;;
	align-items: center;
	margin-bottom: 1rem;
}

.card-table .card-table-header .card-table-title{
	font-weight: 700;
	font-size: 24px;
	color: #858997;
}

.card-table .card-table-body{
	min-height: 500px;
}

.title-list-inmuebles{
	text-align: center;
}

.avatar-group-card-manager span{
	margin-top: 10px;
}


.ant-breadcrumb-link .ant-avatar-string{
	margin-top: 0px !important;
}

.ant-breadcrumb-link .ant-avatar{
	margin-right: 5px !important;
	border: none !important;
}

/*Para que no abarque mas espacio en responsive*/
@media (max-width: 992px) {
  	.card-table .card-table-body{
		min-height: 100%;
	}
}

.actividades-table th{
	text-transform: lowercase !important;
}

.actividades-table th::first-letter{
	text-transform: uppercase !important;
}



/**  CSS de la ponderación * */
.ponderacion-cantidad .ant-card-body{
	padding: 12px 8px;
}

.ponderacion-cantidad .ant-card-body .input-ghost{
	width: 100%;
}

.ponderacion-cantidad .ant-input-number-input{
	text-align: center;
}

.ponderacion-cantidad.error-porcentaje{
	box-shadow: 4px 3px 9px 0px #FF76A0;

}

.lista-materiales .ant-progress .anticon svg{
	display: none !important;
	visibility: hidden !important;
}


.PM-card-info .ant-progress-circle .ant-progress-text {
    font-size: 0.9em;
}

/*css para poner un scroll en las listas*/
@media (max-width: 992px) {
	.scroll{
		overflow-x: scroll;
		z-index: 5;
		position: relative;
 			
	}
}


.scroll .title-list-inmuebles,
.scroll .component-list,
.scroll .group-collapse {
	min-width: 1000px;
}

.scroll.component-list{
	overflow: hidden;
	overflow-x: auto;
}

.scroll.component-list .component-list-item-small,
.scroll.component-list .component-list-item{
	min-width: 1000px;
}

.scroll .group-collapse-act {
	min-width: 80px;
}





.filter-element{

	color: white;
	position: relative;
	top: 12px;
}

.popover-filter{
	max-height: 300px;
	max-width: 225px;
}

.input-filter{
	position: relative;
	top: 10px;
}



@media (max-width: 576px) { 
	.header-list{
		display: none !important;
	}
}




.color-picker-hover .twitter-picker {
    box-shadow: none!important;
}

.color-picker-hover .twitter-picker > div:nth-child(0) {
    display: none;
}

.color-picker-hover .twitter-picker > div:nth-child(1) {
    display: none;
}

.color-picker-hover .twitter-picker > div:nth-child(2) {
    display: none;
}

.color-picker-hover .ant-popover-inner-content{

    padding: 6px 8px!important;

}

.component-list-video{
    float: left;
    margin:10px;
    width: 100%;
    height: 90% !important;
    max-height: 95% !important;
 
}


.component-list-video .ant-list-items{
    display: list-item;
    width: 100%;
    float: left;
    height: 100% !important;
    max-height: 90% !important;
    overflow: auto;
    
}


.video-react{
    min-width: 220px !important;
    margin:10px;
}

.row-video{
    float: left;
    max-width: 30%;
    margin: 1.66%;
    min-width: 180px;
    padding: 10px;
    max-height: 225px !important;
    display: inline-grid;
    float: left;
}

.row-video .ant-image {
    margin: auto;
}

.modal-videos{
    width: 80% !important;
    max-width: 1200px !important;

    height: 90% !important;
    max-height: 85vh;
}


 

.modal-videos  .ant-spin-nested-loading{
    height: 100%;
    max-height: 90% !important;
}


.modal-videos  .ant-spin-container{
    height: 100%;
    max-height: 100% !important;
}

 


.item-image img{
    height: 150px !important;
    width: auto !important;
}
.container {
    position: relative;
}

.tooltip {
    position: absolute;
    color: #000 !important;
    padding: 10px;
    background: rgba(255,255,255,0.8) !important;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    min-width: 200px;
}

.tooltip-white{
    background: rgba(255,255,255,0.8) !important;
    color: #000 !important;

}


.cardPlano .TituloCardPlano{
font-weight: bold;
line-height: 43px;
color: #061079;
font-size: 20pt !important;
margin-bottom: 5px !important;
}

.cardPlano{
    background: transparent !important;
    float: left;
}

.cardVentasGenerales{
    width: 100%;
    float: right;
    max-width: 700px;
}


.cardEstadisticasGenerales{
    width: 100%;
    float: left;
    max-width: 480px;
}

.cardPlano .divInfo{
    margin-bottom: 0px;
}
 

.divEstadisticas  .divInfo{
    margin-top: 5px !important;
    float: left;
    width: 100%;
    max-width: 440px;
}


.cardPlano .spnVendidas,
.cardPlano .spnApartadas,
.cardPlano .spnDisponibles
{
    border-radius: 5px;
    width:  30px;
    height: 30px;
    float: left;
    margin: 4px 10px 0px 0px;
}


.cardPlano .spnVendidas {background: #00FF19;}
.cardPlano .spnApartadas{background: #EB00FF;}
.cardPlano .spnDisponibles { background: #0075FF;} 



.cardPlano .cardInfo  h1,
.cardPlano .cardInfo  h2,
.cardPlano .cardInfo  h4  {
    width: auto;
    float: left;
    margin-right: 5px !important;
    margin-top: 5px !important;
} 


.cardPlano .cardInfo  h2,
.cardPlano .cardInfo  h4  {
    margin-top: 8px !important;
    margin-left: 5px !important;
} 


.cardPlano .cardInfo  h4  {
    text-align: right !important; 
    float: right !important;
} 

.cardPlano .spnEntradas  , 
.cardPlano .spnSinEntradas
{
    width:  50px;
    height: 50px;
}

.cardPlano .spnEntradas   {background: #FF008A;}
.cardPlano .spnSinEntradas{background: #000;}

.cardPlano .div-entradas h1 {
    font-size: 14pt;
    font-weight: bold !important;
    margin-top: 15px !important;
}


.cardPlano .div-entradas {
    width: 100%;
    max-width: 300px !important;
    float: left;
}


.cardPlano h1 ,
.cardPlano h2 ,
.cardPlano h4 {
    color: #061079;
    font-weight: normal;
    margin:0px !important;
    padding: 0px !important;
}


.cardPlano h1 {
    font-size: 13pt !important;
}

.cardPlano h2 {
    font-size: 11pt !important;
    font-weight: 600;
}

.cardPlano h4 {
    font-size: 11pt !important;
    font-weight: bold;
}

 
.div-ventasGenerales{
    height: 120px;
    background: white !important;
    float: left;
    border-radius: 10px;
    margin: 0px !important;
    margin-bottom: 20px !important;
    padding: 5px 12px;
    box-shadow: 0px 10px 15px rgba(25, 29, 56, 0.233);
    min-width: 250px !important;
    width: 60% !important;
    margin-right: 10px !important;
    max-width: 320px !important;
    
}


.elem-uploader {
    cursor: pointer !important;
    border :solid 1px red !important;
    position: relative;
    display: initial !important;
    min-width:  100px !important;
    min-height: 100px !important;
}


.txtAviso{
    width: 100%;
    text-align: center;
    margin-top: 12%;
     
}

.cardPlano .flex-left-column{
    margin-left: 15px;
}



.div-ventasGenerales .ant-col{
    /*display: inline-block;*/
}

.div-ventasGenerales .ant-progress {
    /*margin-right: 15px;
    top: -10px !important;
    display: block;
    padding:5px !important; */
}

.div-ventasGenerales h1{
   /* margin-top: 10px !important;*/
}





/*CSS PARA DASHBOARD DE VENTAS --------------------------------------------------------------------------*/

.ventas-dashboard-ctn .card-bienvenido{
	background: transparent;
	background-image: url("/images/bgBienvenido.png"); 
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 25px;
}


.ventas-dashboard-ctn .card-bienvenido .ant-card-body{
    padding: 0px;
}

.ventas-dashboard-ctn .card-bienvenido .main-dashboard-title{
	font-weight: bold;
	font-size: 30px;
	color: #333539;
}

.ventas-dashboard-ctn .card-bienvenido .card-text{
	font-weight: 600;
	font-size: 15px;
	color: #333539;
    margin-bottom: 1rem;
}


.ventas-dashboard-ctn  .sub-title{
	margin: 0px;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 0.166667px;
	color: #333539;
	margin: 1rem 0;
}

.ventas-dashboard-ctn .btn-verMas{
	height: 30px;
	width: 100px;
	background: #A9C6FF;
	border-radius: 9px;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
}

.ventas-dashboard-ctn .btn-verMas span{
	color: white;
	margin-right: 5px;
	margin-bottom: 2px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.062857px;
}

.ventas-dashboard-ctn .card-pie,
.ventas-dashboard-ctn .card-calendario-sm{
	border-radius: 10px;
	padding: 0.5rem;
}

.ventas-dashboard-ctn .card-calendario-sm .card-title{
	margin: 0;
	font-weight: bold;
	font-size: 20px;
	line-height: 16px;
	letter-spacing: 0.146429px;
	color: #333539;
}

.cardCalendario .badge-calendario,
.cnt-calendario .badge-calendario{
    position: absolute;
    top: 16px;
    height: 10px;
    left: 40%;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-radio-group{
    display: none;
}

.card-calendario-sm  .ant-picker-calendar-header {
   padding: 0 !important;
   border-radius: 10px;
   background: #A9C6FF;
   margin: 0.5rem 0.5rem 1rem 0.5rem;
   display: flex;
   justify-content: center;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-select{
    background: #456EFF;
    height: 32px;
    width: 110px;
    border: none;
    border-radius: 10px;
    color: white;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-select .ant-select-selection-item{
    text-align: center;
    line-height: 29px !important;
    padding: 0 !important;
}

.card-calendario-sm  .ant-picker-calendar-header .ant-select .ant-select-selector{
    background: transparent !important;
    height: 31px !important;
    min-height: unset !important;
    border: none;
}

.card-calendario-sm  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background: #456EFF;
    border-radius: 5px;
}

.card-calendario-sm  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border-radius: 5px;
    border-color: #456EFF;
}

.card-calendario-sm .ant-select-arrow{
	color: white;
}

/*CSS DE LOS CARD DE EVENTOS*/
.card-evento{
    border-radius: 10px;
    margin-bottom:5px ;
    margin :0 0 0.5rem 0;
}
.card-evento.error{
    border-left: solid 10px #E8523F;
}
.card-evento.warning{
    border-left: solid 10px #faad14;
}
.card-evento.success{
    border-left: solid 10px #52c41a;
}

.card-evento h2{
    margin:0px;
    padding: 0px;
    margin-bottom: 10px;
    
}

.card-evento .btn-opcion-menuDot,
.card-evento .btn-opcion-menuDot:hover,
.card-evento .btn-opcion-menuDot:active,
.card-evento .btn-opcion-menuDot:focus
{
    color:#4D4D4D;
    margin-right: 10px;
    border: none;
}

.card-evento p{
    margin:0px;
    padding: 0px;
    margin-bottom: 6px;
}

.card-evento .ant-card-body
{
    padding: 10px;
}


/*CSS PARA CRUD DE PROSPECTOS --------------------------------------------------------------------------*/


.span-estatus,
.span-proyecto-blue,
.span-proyecto{
    min-width: 120px;
    padding: 1px 8px;
    border-radius: 20px;
    color: white;
    line-height: 22px;
}

.span-proyecto{
    background-color: #18BF3D; 
}

.span-proyecto-blue{
    background-color: #0047FF;
    margin-left: 1rem;
}


/*CSS PARA MODAL DE PROSPECTOS --------------------------------------------------------------------------*/
.form-info-prospectos label,
.form-info-prospectos .text-label{
    font-size: 13px;
    color: #A4A4A4;
}

.form-info-prospectos .text-label-bold{
    font-weight: bold;
    font-size: 13px;
    color: #A4A4A4;
}

.form-info-prospectos .text-info{
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

/*CSS PARA LA VISTA DE LOS MENSAJES DE WHATSAPP --------------------------------------------------------------------------*/

.mensajes-ctn .radio-chat{
    width: 100%;
    margin: 0 1rem 1.5rem 0;
}

.radio-chat  {
    width: 65%;
    max-width: 400px;
    margin: auto;
    margin: 20px auto;
}

.radio-chat .ant-radio-button-wrapper:first-child{
    border-radius: 10px 0px 0px 10px;
}
.radio-chat .ant-radio-button-wrapper:last-child{
    border-radius: 0px 10px 10px 0px;
}
 
.radio-chat .ant-radio-button-wrapper{
    width: 50%;
    text-align: center;
    height: 40px;
    padding-top: 3px;
    font-size: 12pt;
    background: #f4f8fb;
    border: solid 1.5px #CAD7DF;;
}


.radio-chat .ant-radio-button-wrapper:hover{
    color: #737588;


}


.radio-chat .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    background: #fff;
    color: #555;
    border-color: #CAD7DF;
}

.radio-chat .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #CAD7DF;
}

.radio-chat .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    border-color: #CAD7DF !important;
}

.radio-chat  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color : #CAD7DF !important;
}

.mensajes-ctn .col-users .div-user-cards{
    max-height: 700px;
    overflow-y: auto;
    padding-left: 1rem; 
}

.mensajes-ctn .card-mensaje .ant-card-body{
    padding: 0px;
}

.mensajes-ctn .card-mensaje{
    border-radius: 10px;
    margin: 0 1rem 1.5rem 0;
    box-shadow: 0px 15px 28px rgba(25, 29, 56, 0.16);
}

.mensajes-ctn .card-mensaje .row-top{
    margin: 1rem 1rem 0.5rem 1rem;
    height: 50px;
}

.mensajes-ctn .card-mensaje .row-bottom{
    margin: 0.5rem 1rem 1rem 1rem;
    height: 50px;
}

.mensajes-ctn .card-mensaje .card-mensaje-br{
    background-color: #E6EDF1;
    border-top: solid 1px #E6EDF1;
    width: 100%;
}

.mensajes-ctn .card-chat .ant-card-body {
    padding: 1rem;
}

.mensajes-ctn .card-chat{
    border-radius: 10px;
    height: 700px;
}

.mensajes-ctn .card-chat .chat-title{
    font-weight: 600;
    font-size: 22px;
    color: #161A35;
}

.mensajes-ctn  .card-chat .col-body-chat{
    height: 520px;
    overflow-y: auto; 
}

.mensajes-ctn .comment-chat .ant-comment-content-author{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 0 12.5% 
}

.mensajes-ctn .comment-chat .col-avatar{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
}

.mensajes-ctn .comment-chat .ant-comment-content-author-name{
    font-weight: bold;
    font-size: 14px;
    color: #737588;
}

.mensajes-ctn .comment-chat .ant-comment-content-author-time{
    font-weight: normal;
    font-size: 12px;
    text-align: right;
    color: #737588;
}

.mensajes-ctn .mensajes-opciones{
    height: 70px;
    margin: 1rem 2rem;
    border-radius: 10px;
    box-shadow: 0px 15px 28px rgba(25, 29, 56, 0.16);
}

.mensajes-ctn .mensajes-opciones .mensajes-opciones-btns{
    display: flex;
    align-items: center;
    justify-content: center;
}

.mensajes-ctn .mensajes-opciones .ant-input{
    border: none;
}

.mensajes-ctn .mensajes-opciones .mensajes-opciones-btns .ant-btn{
    width: 60px;
    margin-left: 0.2rem;
    border: none;
}


/*---------------------------------RESPONSIVE-----------------------------------*/
@media(max-width: 576px)   {
    .mensajes-ctn .mensajes-opciones{
        margin: 1rem 0;
    }
}
/**
 * 
 * CSS QUE AFECTA LOS ESTILOS EN EL MODULO DE COMPRAS (REQUESICIONES)
 * 
 * */
 

.list-header{
	background: gray;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 230px;
	height: 34px;
	border-radius: 12px 12px 0 0;
	font-size: 16px;
	font-weight: bold;
	color: white;
}  

.list-header.list-header-estatus-0,
.tag-estatus-0{
	background: #B706F5
}
.list-header.list-header-estatus-1,
.tag-estatus-1{
	background: #EDF10A
}
.list-header.list-header-estatus-2,
.tag-estatus-2{
	background: #F57906
}
.list-header.list-header-estatus-3,
.tag-estatus-3{
	background: #F50606
}
.list-header.list-header-estatus-4,
.tag-estatus-4{
	background: #6106F5
}  
.list-header.list-header-estatus-5,
.tag-estatus-5{
	background: #F506B2
}
.list-header.list-header-estatus-6,
.tag-estatus-6{
	background: #08E453
}


/**
 * css que modifica los collaps de las cotizacion 
 * */
.cotizacion-collapse,
.cotizacion-collapse .ant-collapse-item{
	border:  none !important;
	background: transparent;
}
.cotizacion-collapse .ant-collapse-header{
	line-height: 20px;
	border-radius: 8px;
	border: none;
	margin: 0 0 4px 0;
}

.cotizacion-collapse .ant-collapse-header .ant-collapse-expand-icon{
	line-height: 42px !important;
}

.collapse-estatus-0 .ant-collapse-header{
	background-color: #F7E5FE;
}
.collapse-estatus-1 .ant-collapse-header{
	background-color: #FCFDC9;
}
.collapse-estatus-2 .ant-collapse-header{
	background-color: #F7C394;
}
.collapse-estatus-3 .ant-collapse-header{
	background-color: #FFD0D0;
}
.collapse-estatus-4 .ant-collapse-header{
	background-color: #E7D8FF;
}
.collapse-estatus-5 .ant-collapse-header{
	background-color: #FED7F3;
}
.collapse-estatus-6 .ant-collapse-header{
	background-color: #D9FFE6;
}

.cotizacion-collapse .form-collapse .ant-form-item{
	margin: 8px 0;
}

.cotizacion-collapse .form-collapse .ant-form-item .btn-green{
	background: #5BF000;
	border-radius: 6px;
	border: none;
	
}


.cotizacion-collapse .btn-red{
	background: #F50606;
	border-radius: 3px;
	border: none;
	color: white;
	min-height: 24px;
	min-width: 24px;
}

.cotizacion-collapse .btn-blue{
	background: #0047FF;
	border-radius: 3px;
	border: none;
	color: white;
	height: 30px;
	width: 30px;
}

.cotizacion-collapse .btn-purple{
	background: #B200F0;
	border-radius: 3px;
	border: none;
	color: white;
	padding-top: 3px;
	/*height: 30px;
	width: 30px;*/
}

.form-collapse{
	position: relative;
}

.form-collapse .icon-checked{
	position: absolute;
	top: 35%;
	left: -8px;
}

.form-collapse .ant-input-number-affix-wrapper,
.form-collapse .ant-input-number-input-wrap{
	background-color: #f0f0f0ad;;
}

.form-collapse .ant-col{
	padding: 0 2px;
}

.upload-sm .ant-upload-list-item-name{
	max-width: 140px !important;
}

.panel .ant-collapse-extra{
	width: 100%;
}

.btn-permission {
	padding: 0 !important;
	border:  none;
	cursor: pointer;
}
.btn-permission:hover {
	cursor: pointer;
}

.btn-permission svg{
	margin: 0 !important;
	color: #c6c6c6;
}

.btn-permission.active svg{
	margin: 0 !important;
	color: #01ccfe;
}


.row-requisicion{
	border-radius: 10px;
	color:  white;
	cursor: pointer !important;
	padding: 4px;
}

.row-requisicion:hover{
	background-color: grey !important;
}

.row-requisicion .folio,
.row-requisicion .fecha{
	display: inline-flex;
	width: 50%;
}

.row-requisicion .fecha{
	justify-content: end;
}

.row-requisicion .producto{
	font-weight: bold;
}


.row-requisicion *{
	color:  white;
}
.sec-mensajes {
    min-height: 90vh;
}

.sec-mensajes>.ant-row>.ant-col {
    /*padding:10px;*/
}

.card-msg {
    /*margin-bottom: 1.5rem*/
}

.abstract-msg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.radioMensajeTipo {
    width: 65%;
    max-width: 400px;
    margin: auto;
    margin: 20px auto;
}

.radioMensajeTipo .ant-radio-button-wrapper:first-child {
    border-radius: 10px 0px 0px 10px;
}

.radioMensajeTipo .ant-radio-button-wrapper:last-child {
    border-radius: 0px 10px 10px 0px;
}

.radioMensajeTipo .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
    height: 40px;
    padding-top: 3px;
    font-size: 12pt;
    background: #f4f8fb;
    border: solid 1.5px #cad7df;
}


.radioMensajeTipo .ant-radio-button-wrapper:hover {
    color: #66bd79;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    background: #fff;
    color: #555;
    border-color: #03e033;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #03e033;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    border-color: #03e033 !important;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #03e033 !important;
}


.cnt-card-mensaje {
    width: 100% !important;
    margin-bottom: 14px;
}

.cnt-card-mensaje .ant-card {
    background: #FFFFFF;
    box-shadow: 0px 24px 48px rgba(25, 29, 56, 0.10);
    border-radius: 8px;
}

.cnt-card-mensaje .ant-card .ant-card-body .ant-comment-avatar {
    display: block;
    margin: auto;
    padding: 5px;
}
.cnt-card-mensaje .ant-card .ant-card-body{
   padding: 0px 8px;
}

.cnt-card-mensaje .ant-badge .ant-card .ant-card:hover {
    box-shadow: 0px 24px 48px rgba(25, 29, 56, 0.2);
}

.cnt-card-mensaje.selected .ant-badge .ant-card {
    border: 1px solid rgba(143, 167, 194, 0.239);
    background: rgba(143, 167, 194, 0.239);
}

.cnt-card-mensaje .ant-card-head {
    padding: 0px 15px;
}


.cnt-card-mensaje .ant-card-head-title {
    padding: 5px 0px;
}

.cnt-card-mensaje .ant-card-head-title>div {
    width: 100%;
    margin-bottom: 10px;
}

.cnt-card-mensaje .ant-card-head-title h2 {
    padding: 0px;
    margin: 0px;
    margin-top: 15px;
    font-size: 14pt;

}

.cnt-card-mensaje .ant-card-head-title h3 {
    padding: 0px;
    margin: 0px;
    margin-top: 0px;
    font-size: 11pt;
    font-weight: normal;
    font-style: normal;
    color: #737588;
}

.cnt-card-mensaje p {
    color: #737588;
    font-size: 12pt;
}


.cnt-Mensajes {
    border-radius: 8px;
    color: #191D38;
    background: linear-gradient(#fff 80%, rgba(255, 255, 255, 0.98)) !important;
    min-height: 500px;
    max-width: 100%;
}

.cnt-Mensajes-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 10px 15px rgba(51, 51, 51, 0.205);
}

.cnt-Mensajes-header h2,
.cnt-Mensajes-header h5 {
    margin: 0 0 0 14px;
    font-size: 18pt;
}


.cnt-Mensajes-header .ant-avatar {
    background: #03E033;
    margin-Top: 10px;
    width: 55px;
    height: 55px;
    margin-Left: 25px;
    margin-bottom: 20px;
}


.colTime {
    text-align: right;
}


.cnt-msj {
    width: 100% !important;
    border: none;
    border-bottom: solid 1px #6bb67d3b;
}

.customer .cnt-msj .ant-card-body {
    padding: 0.5rem;
}

.card-msj .ant-avatar {
   
    margin: 0px !important;
    padding: 0px;
}

.msj-emp .ant-avatar {
    background: #03E033;
}

.card-msj h3 {
    color: #9798a3;
    font-size: 10pt;
    font-weight: normal;
    font-style: normal;
}

.card-msj p {
    line-height: 2;
}

.btn-downloadFile {
    background: #B165E9;
    mix-blend-mode: normal;
    border-radius: 5px;
    border: none;
    width: 45px;
    height: 45px;
    padding: 0px;
    color: white;
    margin: 0px;
}

.btn-downloadFile:hover,
.btn-downloadFile:focus,
.btn-downloadFile:active {
    background: #bb7ae9;
    border: none;
    color: white;
}

.cnt-Mensajes-body {
    min-height: 758px;
    max-height: 758px;
    overflow: auto;
}

.cnt-Mensajes-body .cnt-msj {
    min-height: 758px;
}

.cnt-Mensajes-opciones {
    display: inline-flex;
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
    background: white;
    box-shadow: 0px 10px 15px rgba(51, 51, 51, 0.205);
    width: 100%;
}

.customer .cnt-Mensajes-opciones {
    display: flex;
    flex-direction: column;
}

.cnt-Mensajes-opciones .ant-avatar {
    width: 45px !important;
    height: 45px !important;
    margin-right: 10px;
}


.cnt-Mensajes-opciones .ant-input {
    width: auto !important;
    max-height: 40px !important;
    min-width: calc(100% - 172px);
    background: transparent;
    padding-top: 10px !important;
}

.cnt-Mensajes-opciones .ant-input::-webkit-input-placeholder {
    color: #666;
}

.cnt-Mensajes-opciones .ant-input::placeholder {
    color: #666;
}

.cnt-msj-opciones {
    width: 120px;
    padding-top: 6px;
    float: right;
}

.customer .cnt-msj-opciones {
    width: 100%;
    padding-top: 6px;
    display: flex;
    justify-content: space-between;
}

.cnt-msj-opciones .ant-btn {
    width: 30px;
    height: 30px;
    padding: 0px;
    padding-top: 2px;
    background: rgba(255, 255, 255, 0.178);
    margin-left: 9px;
    border: none;
}

.customer .cnt-msj-opciones .ant-btn {
    margin-left: 0px;
}


.owner .ant-comment-avatar {
    margin-left: auto;
    order: 2;
    
}

.owner .ant-comment-content-author {

    justify-content: flex-end;
}


.owner .ant-comment-content-author-name {
    order: 2;
}

.owner .ant-comment-content-detail {
    position: relative;
    right: 8px;
    text-align: end;
}

.avatarPE {
    margin: 0px !important;
    padding: 0px !important;
}

.avatarPE img {
    width: 55px;
    height: 55px;
    margin: 0px !important;
    padding: 0px;
}



.filesList {
    padding: 1.7em 1em 1em 1em;
    margin-top: 0.7em;
    border-top: 1px solid #e6e6e6;

}

.filesList .file-container {
    margin-left: 1em;
    display: inline-block;
}

.filesList .file {
    text-align: center;
    max-width: 230px;
    /*height: 100px;*/
    border: 1px solid #dedede;
    border-radius: 10px;
    position: relative;
}


.filesList .file>svg {
    font-size: 35px;
    text-align: center;
    position: relative;
    top: calc(50% - 15px);
    color: #b5a9a9;

}

.filesList .file .buttom-close {
    margin: 0;
    padding: 0;
    width: 25px !important;
    height: 25px !important;
    color: white;
    border: none;
    background: #dc3545 !important;
    border-radius: 100%;

    position: absolute;
    right: -8px;
    top: -8px;
    /*     top: -10px: */

}

.filesList .file .buttom-close svg {
    font-size: 18px;
    position: relative;
    top: 2px;
}




.ant-comment-content-author,
.ant-comment-content-author .ant-comment-content-author-name,
.ant-comment-content-author .ant-comment-content-author-time {
    width: 100% !important;
    float: left !important;
}


.owner .ant-comment-content-author,
.owner .ant-comment-content-author .ant-comment-content-author-name,
.owner .ant-comment-content-author .ant-comment-content-author-time {
    float: right !important;
    display: block;
    text-align: right;
}



.ant-comment-content-author-name h1,
.ant-comment-content-author-name h2 {
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
    display: inline-block !important;

}

.ant-comment-content-author-name h2 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 10px;
}

.card-update{
    width: 100%;
    text-align: center;
    font-size: 12px;
    border-radius: 12px;
    background-color: #cdf6a1;
    color:  black;
}

.card-update .ant-col{
    margin-bottom: 0.2rem;
    text-align: left;
}

.mentions--singleLine{
    margin-bottom: 1.5rem;
}

.mentions--singleLine .mentions__input{
    padding-left: 8px;
}
.tabs-proveedor .ant-tabs-tab-active{
	font-weight: bold !important;
}

.tabs-proveedor .ant-tabs-tab-btn{
    font-size: 20px;
    color: #0047FF !important;
    font-weight: lighter !important;
}

.scroll-proveedores{
	max-height: 80vh;
	overflow-x: auto;
	margin-top: 0px !important;
}

.tag-proyecto{
	border-radius: 10px;
	padding: 0 10px;
	color:  white;
	max-width: 100%;
	margin: 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.tag-proyecto-small{
	font-size: 9px !important;
	border-radius: 10px;
	padding: 0 10px;
	color:  white;
}

.div-row{
	display: flex;
}

.div-obj{
	/*padding: 0 5px 10px 5px;*/
	
}

.div-row .div-key{
	width: 25%;
	border-bottom: solid 1px black;
	background-color: #90A7F8;
	border-right: solid 1px black;
	border-left: solid 1px black;
	padding-left: 0.5rem;
	min-width: 120px;
}

.div-title{
	width: 100%;
	background-color:#0047FF;
	color:  white;
	border-bottom: solid 1px black;
	padding-left: 1rem;
}

.div-string{
	border-bottom: solid 1px black;
	border-right: solid 1px black;
	width: 75%;
	padding-left: 1rem;
	min-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-height: 200px;
}

.factura-xml-table{
	border-left: solid 1px black;
	border-right: solid 1px black;
	border-bottom: solid 1px black;
}

.table-container{
	width: 100%;
	overflow: auto;
	max-height: 500px;
}
.table-container thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; }

.factura-xml-table th{
	text-transform: capitalize;
	padding-right: 2rem;
	border-right: solid 1px black;
	border-bottom: solid 1px black;
	background-color: #cacaca;
}

.factura-xml-table td{
	text-transform: capitalize;
	border-right: solid 1px black;
	border-bottom: solid 1px black;
}

.hr{
	border-bottom: solid 1px #dbdbdb;
}

.dropdown-filtro {
	color: #0047ff;
	margin: 0;
}

.dropdown-filtro:hover{
	cursor: pointer;
}

.dropdown-filtro svg {
	fill:  #0047ff;
	margin: 0 0 2px 10px;
}

.select-filtro .ant-select-selector{
	border-color: #426bd7 !important;
	min-width: 200px;

}

.select-filtro .ant-select-selection-placeholder,
.select-filtro .ant-select-arrow{
	color:  #426bd7 !important;
}

/*-----------------CSS PARA EL MODALFACTURA-----------------------------*/

.radio-group-modal{
	width: 100%;
	margin-bottom: 5rem;
	padding-top: 100px;
}

.radio-group-modal .radio-btn-modal{
	height: 200px;
	width: 200px;
	border-radius: 20px !important;
	border:solid 3px #909091; 
}

.radio-group-modal .radio-btn-inner{
	display: flex !important;
	flex-direction: column !important;
	align-items: center;
	justify-content: center;
	height: 200px;
	width: 200px;
	margin-left: -17px;
	position: relative;
	padding-bottom: 2rem;
}

.radio-group-modal .radio-btn-inner .radio-btn-text{
	position: absolute;
	bottom: 8%;
	font-weight: 600;
	font-size: 16px;
	color: #909091;
}

.radio-group-modal .ant-radio-button-wrapper-checked{
	background: linear-gradient(180deg, #0047FF 0%, rgba(0, 71, 255, 0.71) 100%);
	border:  none !important;
}

.radio-group-modal .ant-radio-button-wrapper-checked .radio-btn-inner svg,
.radio-group-modal .ant-radio-button-wrapper-checked .radio-btn-inner span{
	fill: white;
	color: white !important;
	
}
.scroll-modal{
	height: 552px;
	overflow-y: auto;
}


.btn-reportes{
	padding: 0 !important;
	color: white !important;
	background: transparent !important;
	width: 130px;
	height: 40px;
	line-height: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;

}
.btn-reportes img{
	margin-left: 10px;
}

.menu-dropdown{
	padding-top: 0px;
	padding-bottom: 20px;
	min-width: 230px;
	border-radius: 5px;
}

.menu-dropdown .header-dropdown{
	min-height: 100px;
	padding-top: 0.5rem;
	background-color: rgba(0, 71, 255, 0.07);
}

.menu-dropdown .header-dropdown .ant-typography{
	font-weight: bold;
	color: #0047ff;
}

.menu-dropdown .header-dropdown .ant-btn{
	font-weight: bold;
	background-color: #0047ff;
	color: white;
	border: none;
	
	border-radius: 10px;
}

.menu-dropdown .ant-dropdown-menu-submenu-title{
	display: flex;
	justify-content: center;
	padding:22px 12px 0 12px;
}

.menu-dropdown .ant-dropdown-menu-submenu-expand-icon{
	display: none;
}

.menu-dropdown  .ant-dropdown-menu-title-content{
	background: rgba(0, 71, 255, 0.07);
	max-width: 150px !important;
	display: block;
	border-radius: 10px;
	text-align: center;
	color: #0047ff;
	height: 25px;
	line-height: 25px;
}
.print-view{
	display: none;
}

@media print {

	body{
		-webkit-print-color-adjust: exact; /*chrome*/
		color-adjust: exact; /*firefox*/
	}

	.print-view{
		display: block;
		width: 100%;
	}

	.report-header{
		background-color: #E6E7E9;
		display: flex;
		justify-content: space-between;
		height: 300px;
	}

	.report-header.black{
		background-color: black !important;
	}

	.report-logo{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.report-header .header-left,
	.report-header .report-logo,
	.report-header	.header-rigth{
		width: 30%;
	}

	.report-header .header-left{
		padding-left: 3rem;
		padding-bottom: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
	}

	.report-header .header-left.top{
		justify-content: flex-start;
		padding-top: 4rem;
	}

	.report-header .header-rigth{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 4rem;
		padding-bottom: 1rem;
	}

	.report-header.black .div-direction{
		margin-bottom: 6rem;
	}

	.header-rigth .div-blue,
	.header-rigth .div-black{
		height: 42px;
		text-align: center;
		line-height: 42px;
		font-size: 14px;
		letter-spacing: 0.1rem;
		font-weight: bold;
		width: 100%;
	}

	.div-blue{
		color: white;
		background-color: #0826FB !important;
	}

	.div-black{
		color: black !important;
		background-color: white;
	}

	.header-left .text-proveedor,
	.header-rigth .text-proveedor{
		font-weight: 900;
		font-size: 18px;
		letter-spacing: 0.1em;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.72);
	}

	.report-header.black .header-rigth .text-proveedor,
	.report-header.black .header-left .text-proveedor{
		color: white !important;
	}



	.header-left .text,
	.header-rigth .text{
		font-weight: 300;
		font-size: 11px;
		line-height: 18px;
		letter-spacing: 0.1em;
		color: rgba(0, 0, 0, 0.72)
	}

	.report-title{
		width: 100%;
		margin-top: 1.5rem;
		font-weight: 900;
		font-size: 32px;
		text-align: center;
		letter-spacing: 0.2em;
		color: #0826FB;
	}

	.factura-table{
		width: 92% !important;
		text-align: center;
		margin: 3rem 2rem 2rem 2rem;
	}

	.factura-table thead{
		font-weight: bold;
		font-size: 9px;
		letter-spacing: 2px;
		color: #404041;
	}

	.factura-table thead tr{
		height: 50px;

	}

	.factura-table tbody{
		font-weight: bold;
		font-size: 10px;
		letter-spacing: 2px;
		color: #000000;
	}

	.factura-table tbody tr{

		min-height: 40px !important;
		height: 60px;
		border:  solid black !important;
		border-width: 0 0 1px 0 !important;

	}

	.factura-table tfoot tr{
		height: 104px;

	}

	.factura-table tbody tr td{
		border:  solid rgba(0, 0, 0, 0.4) !important;
		border-width: 0px  0px 0.5px 0px !important;

	}

/*-------------------------------------------------*/
	.report-logo-lg{
		padding: 4rem;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.totales-container{
		width: 60%;

	}

	.div-totales{
		display: flex;
		color: #646465;
		padding: 1rem 0 1rem 1.5rem;

	}

	.div-totales .totales-title{
		width: 40%;
		font-weight: bold;
		font-size: 11px;
		letter-spacing: 2px;
	}

	.div-totales .totales-monto{
		width: 30%;
		text-align: right;
		font-weight: bold;
		font-size: 11px;
		letter-spacing: 2px;
	}

	.div-totales .totales-moneda{
		width: 20%;
		text-align: center;
		font-weight: bold;
		font-size: 11px;
		letter-spacing: 2px;
	}

	.totales-monto.red,
	.totales-moneda.red{
		color: #fa1e1e;
	}

	.totales-monto.yellow,
	.totales-moneda.yellow{
		color: #e1c634;
	}

	.div-balance{
		margin-top: 1.5rem;
		display: flex;
		background-color: #0826FB;
		height: 70px;
	}

	.div-balance.black{
		background-color: black !important;
	}

	.div-balance .balance-title{
		width: 40%;
		height: 70px;
		padding-left: 2rem ;
		font-weight: 900;
		font-size: 13px;
		line-height: 40px;
		letter-spacing: 0.2em;
		color: #FFFFFF;
	}

	.div-balance .balance-monto{
		height: 70px;
		padding-left: 1rem ;
		font-weight: 900;
		font-size: 18px;
		line-height: 70px;
		letter-spacing: 0.2em;
		color: #FFFFFF !important;
	}

	 .pagebreak { page-break-before: always; }


}
/*CSS PARA LOS CARDS DE EL DASHBOARD DE PRESUPUESTOS*/
.presupuesto-ctn {
	/*overflow-x: auto;
	scroll-behavior: smooth;*/
}

.presupuesto-ctn .ant-table-has-fix-left{
	width: -webkit-max-content;
	width: max-content;
}

.presupuesto-ctn .card{
	box-shadow: 0px 5px 25px rgba(25, 29, 56, 0.16);
}

.presupuesto-ctn .card.med{
	min-height: 150px;
	text-align: center;
}

.presupuesto-ctn .card.sm{
	min-height: 66px;
}

.presupuesto-ctn .card img{
	width: 100%;
}

.presupuesto-ctn .card.med .card-title{
	font-weight: 600;
	font-size: 24px;
	color: #858997;
	display: block !important;
}
.presupuesto-ctn .card.med .card-data{
	font-weight: bold;
	font-size: 20px;
	color: #FFB800;
	display: block !important;
	padding: 1.4rem 1rem 1rem 1rem;
}

.presupuesto-ctn .card.sm .ant-row{
	width: 100%;
}

.presupuesto-ctn .card.sm .card-title{
	font-weight: normal;
	font-size: 17px;
	color: #858997;
}
.presupuesto-ctn .card.sm .card-data{
	font-weight: normal;
	font-size: 17px;
	color: #BD30FF;
}

.presupuesto-ctn .card-grafica{
	min-height: 60vh;
}


/*CSS PARA LA TABLA DE PRESUPUESTOS*/

.presupuesto-table .ant-table{
	background: transparent;	
}

.super-scroll{
	min-width: 1000px;
}

.text-blue,
.presupuesto-ctn .text-blue,
.presupuesto-proforma-ctn .text-blue{
	color: #0047FF !important;
	font-weight: bold;
}
.presupuesto-ctn .text-green,
.presupuesto-proforma-ctn .text-green{
	color: #009420;
	font-weight: bold;
}

.presupuesto-ctn .text-greenLigth,
.presupuesto-proforma-ctn .text-greenLigth{
	color: #00FF75;
}

.presupuesto-ctn .text-yellow,
.presupuesto-proforma-ctn .text-yellow,
.presupuesto-proforma-ctn .card input{
	color: #9C9F0C;
}

.presupuesto-ctn .text-purple,
.presupuesto-proforma-ctn .text-purple{
	color: #9E00FF;
}

.presupuesto-table .ant-table-thead > tr > th, 
.presupuesto-table .ant-table-cell-fix-left, 
.presupuesto-table .ant-table-cell-fix-right{
	background: #f0f2f5
}

.presupuesto-table .ant-table-thead > tr > th {
	border: none; 
}

.presupuesto-table .ant-table-cell{
	padding: 0;
}

.presupuesto-table .ant-table-tbody{
	font-weight: 500;
	font-size: 16px;
}

.presupuesto-table .ant-table-cell span{
	text-transform: none;
	display: block;
	background: white;
	border-radius: 5px;
	padding: 5px 7px;
	margin: 3px 7px;
	text-align: center;
	min-height: 35px;
}

/*FOOTER TABLA*/
.presupuesto-table .ant-table-footer{
	padding: 0;
	background: none;
}

.presupuesto-ctn .table-footer{
	min-height: 30px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.presupuesto-ctn .table-footer span{
	background: white;
	border-radius: 5px;
	padding: 5px 7px;
	margin: 3px 7px;
	text-align: center;
	min-height: 35px;
	font-size: 16px;
	box-shadow: 0px 5px 25px rgba(25, 29, 56, 0.16);
}

/*CCS PARA PRESUPUESTO PROFIRMA*/

.presupuesto-proforma-ctn .card{
	padding: 5px 7px;
	height: 35px;
	margin:5px 7px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

}

.presupuesto-proforma-ctn .card-img{
	padding: 5px 7px;
	padding: 1rem 0;
	text-align: center;
	min-height: 35px;
	margin:3px 7px;
	background: white;
	border-radius: 10px;
}

.presupuesto-proforma-ctn .footer-list .card{
	height: 40px;
	font-size: 20px;
}

.presupuesto-proforma-ctn .ant-row{
	min-width: 700px;
}

.presupuesto-proforma-ctn .scrll{
	overflow: auto;
	scroll-behavior: smooth;
}

.presupuesto-proforma-ctn .card input{
	text-align: center;
}

.presupuesto-proforma-ctn .ant-input-number{
	border: none;
}

.hover-effect-all:hover * {
	background: #bfbfbf !important;
	cursor: pointer;
}

.hover-effect-all:hover *{
	color: white !important;
}

.hover-effect-all-2:hover *{
	background: #bfbfbf !important;
	color: white !important;
}

.presupuesto-ctn .hover-info{
	border: solid 1px red;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.ordenes-dashboard .PM-card-dashboard .PM-card-title{
    margin-bottom: 0.5em;
    margin-top: 0.25em;
}

.ordenes-dashboard .info-card{
    height: 100%;
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 10px;
}

.ordenes-dashboard .info-card .ant-card-body{
    padding: 12px;
}


.ordenes-dashboard .title-info-card{
    margin-top: 5% !important;
    text-align: center;
    color: #858997;
}

.ordenes-dashboard .title-info-card.info{
    color: #000000 !important;
}



.ordenes-dashboard .component-list{
    width: 100%;
}

.ordenes-dashboard .title {
    color: #858997;

    margin-top: 1em !important;
    margin-bottom: 1em !important;
}

.ordenes-dashboard .status-tag{
    border-radius: 100px;
}

.project-add-plus{
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.20), 0px 2px 2px rgba(0, 0, 0, 0.15);
}


.project-add-plus svg{
    color: white !important;
}

.dshb-lista-ordenes .ant-list-items{
    max-height: 580px;
    overflow: auto;
}



.title-blue
{
    padding: 0;
}


.title-blue,
.title-blue .ant-page-header-heading-title
{
    color: #0047FF !important;
    font-size: 22px !important;
    line-height: 30px !important;
    letter-spacing: 3px !important;
    font-weight: bold;
}

.button-new-material{
    background-color: #0047FF;
    position: relative;
    right: 2em;
    /* left: 2em; */
}

.button-new-material:hover{
    background-color: #0047FFAA;
}

.btn-truck,
.btn-truck:hover,
.btn-truck:focus{
    background: #B454FF;
    padding-top: 3px;
}
.collapse-almacenes .ant-collapse-item{
    margin-bottom: 0.5rem;
}

.collapse-almacenes .ant-collapse-header {
   display: flex;
    background: #0047ff;
    border: 0px;
    border-radius: 8px!important;
    color: #FFFFFF;
    
}
.collapse-almacenes .ant-collapse-item .ant-collapse-content{
    background: #FFFFFF;
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    font-weight: 600;
}

.collapse-almacenes .header-collapse{
    height: 40px;
}

.collapse-almacenes .header-collapse span {
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF ;


}

.collapse-text-big {
    font-style: normal;
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}

.collapse-almacenes .ant-collapse-item{
    border:  none !important;
}

.tabs-inventario .ant-tabs-nav-list{
    background: white;
    padding: 0.8rem;
    border-radius: 10px;
}

.tabs-inventario .ant-tabs-tab-active{
    background: #deddf9 !important;
    color: #5C54E0 !important;
    border: #FFFFFF;
    border-radius: 30px;
    padding: 0 10px;
    width: 100px;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
}
.tabs-inventario .ant-tabs-tab{
    width: 120px;
    margin: 0 !important;
    justify-content: center;
    text-align: center;
}

.tabs-inventario .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #5C54E0 !important;
}

.tabs-inventario .ant-tabs-ink-bar{
    display: none;
}

.lista-vmi .ant-spin-container{
    min-height: 200px !important;
}
.tag-sm{
    color: white !important;
    border:  none;
    border-radius: 6px;
}

.tag-sm:hover{
    cursor: pointer;
    background-color: grey;
}

.tag-estatus{
    color:  white;
    border: none;
    border-radius: 8px;
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
}
.button-syncfy{
    width: 14px!important;
    height: 14px!important;
    min-width: unset !important;
    position: absolute !important;


}
.button-syncfy .icon {
    font-size: 10px !important;
    position: relative!important;
    bottom: 6px!important;
}

.button-delete-syncfy{
    right: 10px!important;
    top: 7px!important;
}

.button-update-syncfy{
    right: 30px!important;
    top: 7px!important;
}


.ant-select-selector .button-syncfy {
    display: none;
}

.collapse-productos .ant-collapse-item{
    margin-bottom: 0.5rem;
}

.collapse-productos .ant-collapse-header {
    display: flex;
    background: #0047ff;
    border: 0px;
    border-radius: 8px!important;
    color: #FFFFFF;
    
}





/* margin-left: 1em; */
.collapse-productos .ant-collapse-item .ant-collapse-content{
    background: #FFFFFF;
    
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    line-height: 50px;
    color: #FFFFFF ;
    font-weight: 600;
}

.collapse-productos .card-list{
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}
.collapse-productos .card-list .ant-card-body{
    padding: 0.5rem !important;
}

.header-collapse span {
    
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF ;
    margin-left: 1.2em;
}

.collapse-text-big {
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}

.collapse-productos .ant-collapse-item{
    border:  none !important;
}


.icon-pro{

    position: absolute;

    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

    color: white;


}

.option-actions:hover svg{ 
    color: gray !important;
}

.select-actions .ant-select-selection-item button{
    display: none;
}


.historial-precio .card .text-grey{
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #858997;
}

.historial-precio .card .text-blue{
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #0047FF;
    margin-bottom: 0.5rem;
}

.historial-precio .card .text-green{
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #00EE0A;
}

.historial-precio .card .text-red{
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #F00D0D;
}

.historial-precio .card .text-proveedor{
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #858997;
}

.historial-precio .card .text-alias{
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #858997;
}

.historial-precio .card .text-grey-lg{
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #858997;
}
.budgeting .text-gray-lg {
	font-weight: 300;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #858997;
	margin-bottom: 0.4rem;
}

.budgeting .text-black-lg {
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #000000;
}


.budgeting .text-gray {
	font-weight: 300;
	font-size: 13px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #858997;
	margin-bottom: 0.5rem;
}


.budgeting .text-black {
	font-weight: 300;
	font-size: 14px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #000000;
	margin-bottom: 0.5rem;
}

.budgeting .row-blue {
	margin: 1rem 0;
	background: #0047FF;
	box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
	border-radius: 10px;
	min-height: 55px;
	font-size: 18px;
	font-weight: 800;
}

.budgeting .row-blue .ant-col {
	display: flex;
	justify-content: center;
	align-items: center;
}


.budgeting .row-blue .ant-typography {
	color: white;
}


.budgeting-collapse .ant-collapse-header {
	width: 100%;
}

.budgeting .ant-collapse-header {
	background: white;
}


.budgeting .budgeting-collapse .ant-collapse-header-text {
	width: 100%;
}

.budgeting .budgeting-collapse-header span {
	font-size: 14px !important;
	margin: 0px !important;
}

.budgeting span.ant-progress-text {
	margin-left: 8px !important;

}

.budgeting .collapse-productos .text-ligth {
	font-weight: 275;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #000000;
	font-style: italic;
}

.custom-collapse,
.custom-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    border: none !important;

}

.custom-collapse .ant-collapse-item,
.custom-collapse .ant-collapse-item {
    margin-top: 0.5em;
    margin-bottom: 5px;
    overflow: hidden;
    background: #fff;
    border: none !important;
    border-radius: 2px;
}

.progressbar{
	width: 75% !important;
}
