/*/*
	CSS QUE CORRESPENDE A EL MODULO DE PROJECT MANAGER Y SUS COMPONENTES
*/

/*--------------------------MODULO----------------------------*/
.carousel-pm {
	margin-bottom: 1rem;
}

.carousel-pm .col-carousel{
	padding: 0px 8px 0px 8px;

}

.next-activities-col .next-activities-header
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.next-activities-col .next-activities-header{
	margin-bottom: 1rem;
}

.next-activities-col .next-activities-title{
	color: #858997;
	font-size: 18px;
	font-weight: 600;
}

.btn-go,
.btn-go:focus,
.btn-go:hover{
	color: white;
	height: 30px;
	width: 30px;
	background: #0047FF;
	border-radius: 7px;
	padding: 5px 7px;
	border: none;
}

/*CSS DEL TITULO DE LISTA*/
.list-title{
	color: #0047FF;
	font-size: 20px;
	font-weight: 700;
	margin-left: 1rem;
	padding-bottom: 1rem;
}

/*CSS para la lista de Areas*/
.component-list-item{
	padding: 0 0 11px 0 !important;
}

.component-list-item * {
	/* color: #222222; */
}

.card-list{
	width: 100%;
	text-align: center;
	border-radius: 10px;
	font-size: 13px;
	margin: 0;
}

.card-list .ant-card-body{
	padding: 1rem;
}


/*CSS PARA LA TABLA GANTT*/
.card-table{
	border-radius: 10px;
	height: 98%;
}

.card-table .card-table-header{
	display: flex;
	justify-content: space-between;;
	align-items: center;
	margin-bottom: 1rem;
}

.card-table .card-table-header .card-table-title{
	font-weight: 700;
	font-size: 24px;
	color: #858997;
}

.card-table .card-table-body{
	min-height: 500px;
}

.title-list-inmuebles{
	text-align: center;
}

.avatar-group-card-manager span{
	margin-top: 10px;
}


.ant-breadcrumb-link .ant-avatar-string{
	margin-top: 0px !important;
}

.ant-breadcrumb-link .ant-avatar{
	margin-right: 5px !important;
	border: none !important;
}

/*Para que no abarque mas espacio en responsive*/
@media (max-width: 992px) {
  	.card-table .card-table-body{
		min-height: 100%;
	}
}

.actividades-table th{
	text-transform: lowercase !important;
}

.actividades-table th::first-letter{
	text-transform: uppercase !important;
}



/**  CSS de la ponderación * */
.ponderacion-cantidad .ant-card-body{
	padding: 12px 8px;
}

.ponderacion-cantidad .ant-card-body .input-ghost{
	width: 100%;
}

.ponderacion-cantidad .ant-input-number-input{
	text-align: center;
}

.ponderacion-cantidad.error-porcentaje{
	-webkit-box-shadow: 4px 3px 9px 0px #FF76A0;
	box-shadow: 4px 3px 9px 0px #FF76A0;

}

.lista-materiales .ant-progress .anticon svg{
	display: none !important;
	visibility: hidden !important;
}


.PM-card-info .ant-progress-circle .ant-progress-text {
    font-size: 0.9em;
}

/*css para poner un scroll en las listas*/
@media (max-width: 992px) {
	.scroll{
		overflow-x: scroll;
		z-index: 5;
		position: relative;
 			
	}
}


.scroll .title-list-inmuebles,
.scroll .component-list,
.scroll .group-collapse {
	min-width: 1000px;
}

.scroll.component-list{
	overflow: hidden;
	overflow-x: auto;
}

.scroll.component-list .component-list-item-small,
.scroll.component-list .component-list-item{
	min-width: 1000px;
}

.scroll .group-collapse-act {
	min-width: 80px;
}





.filter-element{

	color: white;
	position: relative;
	top: 12px;
}

.popover-filter{
	max-height: 300px;
	max-width: 225px;
}

.input-filter{
	position: relative;
	top: 10px;
}



@media (max-width: 576px) { 
	.header-list{
		display: none !important;
	}
}

