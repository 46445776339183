.button-syncfy{
    width: 14px!important;
    height: 14px!important;
    min-width: unset !important;
    position: absolute !important;


}
.button-syncfy .icon {
    font-size: 10px !important;
    position: relative!important;
    bottom: 6px!important;
}

.button-delete-syncfy{
    right: 10px!important;
    top: 7px!important;
}

.button-update-syncfy{
    right: 30px!important;
    top: 7px!important;
}


.ant-select-selector .button-syncfy {
    display: none;
}