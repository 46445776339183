.dropzone-element {
    width: 104px;
    height: 104px;
    background-color: #FAFAFA;
    margin: 0 8px 8px 0;
    padding: 8px;

    position: relative;
}


.dropzone-element.dropzone-child {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.dropzone-element .ant-avatar {
    display: block;
    /*margin: 0px auto;*/
    width: 86px;
    height: 86px;
}


.dropzone-element.main-dropzone {
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    border: 1px dashed #D9D9D9;


    border-radius: 4px;

}


.dropzone-element.main-dropzone:active,
.dropzone-element.main-dropzone:focus {
    outline: none !important;
    border: 1px dashed #1890FF !important;
    cursor: pointer;
}


.dropzone-element.main-dropzone .main-dropzone-title {
    font-weight: 400;
    margin-top: calc(50% - 30px);
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 1.8em;
}


.dropzone-element.main-dropzone:hover {
    border: 1px dashed #1890FF !important;
    cursor: pointer;
}


.dropzone-element .thumb-menu {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 86px;
    height: 86px;
    background: rgba(0, 0, 0, 0);
    transition: all 0.2s ease-in;
}

.opacity-button{
    opacity: 0;
    padding: 5px;
}

.dropzone-element .thumb-menu:hover {
    background: rgba(0, 0, 0, 0.55);
}

.dropzone-element .thumb-menu:hover > .ant-space .opacity-button{
    opacity: 1;
    transition: all 0.2s ease-in;

}
.dropzone-element .thumb-menu .ant-space{
    width: 86px;
    justify-content: center;
    margin-top: calc(50% - 20px);
}

.modal-form .closable-button{
    left: 30px;
    top: 30px;
}





/*vertical-align: middle;*/
