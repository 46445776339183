/**
 * 
 * CSS QUE AFECTA LOS ESTILOS EN EL MODULO DE COMPRAS (REQUESICIONES)
 * 
 * */
 

.list-header{
	background: gray;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 230px;
	height: 34px;
	border-radius: 12px 12px 0 0;
	font-size: 16px;
	font-weight: bold;
	color: white;
}  

.list-header.list-header-estatus-0,
.tag-estatus-0{
	background: #B706F5
}
.list-header.list-header-estatus-1,
.tag-estatus-1{
	background: #EDF10A
}
.list-header.list-header-estatus-2,
.tag-estatus-2{
	background: #F57906
}
.list-header.list-header-estatus-3,
.tag-estatus-3{
	background: #F50606
}
.list-header.list-header-estatus-4,
.tag-estatus-4{
	background: #6106F5
}  
.list-header.list-header-estatus-5,
.tag-estatus-5{
	background: #F506B2
}
.list-header.list-header-estatus-6,
.tag-estatus-6{
	background: #08E453
}


/**
 * css que modifica los collaps de las cotizacion 
 * */
.cotizacion-collapse,
.cotizacion-collapse .ant-collapse-item{
	border:  none !important;
	background: transparent;
}
.cotizacion-collapse .ant-collapse-header{
	line-height: 20px;
	border-radius: 8px;
	border: none;
	margin: 0 0 4px 0;
}

.cotizacion-collapse .ant-collapse-header .ant-collapse-expand-icon{
	line-height: 42px !important;
}

.collapse-estatus-0 .ant-collapse-header{
	background-color: #F7E5FE;
}
.collapse-estatus-1 .ant-collapse-header{
	background-color: #FCFDC9;
}
.collapse-estatus-2 .ant-collapse-header{
	background-color: #F7C394;
}
.collapse-estatus-3 .ant-collapse-header{
	background-color: #FFD0D0;
}
.collapse-estatus-4 .ant-collapse-header{
	background-color: #E7D8FF;
}
.collapse-estatus-5 .ant-collapse-header{
	background-color: #FED7F3;
}
.collapse-estatus-6 .ant-collapse-header{
	background-color: #D9FFE6;
}

.cotizacion-collapse .form-collapse .ant-form-item{
	margin: 8px 0;
}

.cotizacion-collapse .form-collapse .ant-form-item .btn-green{
	background: #5BF000;
	border-radius: 6px;
	border: none;
	
}


.cotizacion-collapse .btn-red{
	background: #F50606;
	border-radius: 3px;
	border: none;
	color: white;
	min-height: 24px;
	min-width: 24px;
}

.cotizacion-collapse .btn-blue{
	background: #0047FF;
	border-radius: 3px;
	border: none;
	color: white;
	height: 30px;
	width: 30px;
}

.cotizacion-collapse .btn-purple{
	background: #B200F0;
	border-radius: 3px;
	border: none;
	color: white;
	padding-top: 3px;
	/*height: 30px;
	width: 30px;*/
}

.form-collapse{
	position: relative;
}

.form-collapse .icon-checked{
	position: absolute;
	top: 35%;
	left: -8px;
}

.form-collapse .ant-input-number-affix-wrapper,
.form-collapse .ant-input-number-input-wrap{
	background-color: #f0f0f0ad;;
}

.form-collapse .ant-col{
	padding: 0 2px;
}

.upload-sm .ant-upload-list-item-name{
	max-width: 140px !important;
}

.panel .ant-collapse-extra{
	width: 100%;
}

.btn-permission {
	padding: 0 !important;
	border:  none;
	cursor: pointer;
}
.btn-permission:hover {
	cursor: pointer;
}

.btn-permission svg{
	margin: 0 !important;
	color: #c6c6c6;
}

.btn-permission.active svg{
	margin: 0 !important;
	color: #01ccfe;
}


.row-requisicion{
	border-radius: 10px;
	color:  white;
	cursor: pointer !important;
	padding: 4px;
}

.row-requisicion:hover{
	background-color: grey !important;
}

.row-requisicion .folio,
.row-requisicion .fecha{
	display: inline-flex;
	width: 50%;
}

.row-requisicion .fecha{
	justify-content: end;
}

.row-requisicion .producto{
	font-weight: bold;
}


.row-requisicion *{
	color:  white;
}