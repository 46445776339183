.div_contenido{
    width: 100%;
}






.avatar-uploader > .ant-upload {
    width: 128px;
    /*height: 128px;*/
}

.avatar-uploader.ant-upload-picture-card-wrapper{
    display: flex;
    justify-content: center;
}
