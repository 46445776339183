.card-proyecciones{
    margin:10px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 20px;
}

.btn-bag, .btn-sales{
    border: solid 1px rgba(255, 255, 255, 0.5) !important;
    height: 50px;
    width:  50px;
    border-radius: 100px;
    padding: 0px;
    float: right;
}

.btn-bag{
    padding-top: 8px;
    background: #A233F9 ;
}

.btn-sales{
    padding-top: 5px;
    background: #00FF57;
}

.btn-bag:active,.btn-bag:focus, .btn-bag:hover {
    background: #c482f7 ;
}

.btn-sales:active,.btn-sales:focus, .btn-sales:hover {
    background: #77f8a2;
}


.card-proyecciones h4 {
    width: calc(100% - 40px);
    color:#858997;
}

.card-proyecciones .IconMenuDot{
    background: #FFF;
    border: none;
    width: 30px;
    height: 25px;
    padding: 0px;
    display: block;
}

.card-proyecciones h2 {
    width: calc(100% - 55px) !important;
    color:#000;
    font-weight: bolder;
    float: left;
}

.card-proyecciones .ant-row{
    margin:15px
}

.IconArrowBack{
    background:transparent;
    border: none;
    width: 50px;
    height: 30px;
    padding: 0px;
    display: block;
    float: left;
    margin-right: 10px;
}
.IconArrowBack:active,.IconArrowBack:focus, .IconArrowBack:hover{
    background:transparent;
    border: none;
}

.blue-title{
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 3px;
    color: #0047FF;
}

.card-proyeccionesDetalle{
    border-radius: 15px;
}

.card-proyeccionesDetalle h1{
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 3px;
    color: #0047FF;
}

.card-proyeccionesDetalle .ant-col{
    margin: 35px 0px;
}

.card-proyeccionesDetalle .lblTitulo{
    width: 100%;
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #A4A4A4;
    margin-bottom: 10px;
}

.card-proyeccionesDetalle .pInfo{
    
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 3px;
    color: #000000;
    width: auto;
    float: left;
    margin-top: 10px;
    cursor: default;
}

.card-proyeccionesDetalle .ant-avatar{
    float: left;
    margin-right: 20px;
}

.card-proyeccionesDetalle .card-svg-open{
    margin-top: 8px;
    margin-left: 8px;
    cursor: pointer;
}


.ant-table-cell .lblTitulo{
    font-weight: 800 !important;
    color: #000;
}