

.color-picker-hover .twitter-picker {
    box-shadow: none!important;
}

.color-picker-hover .twitter-picker > div:nth-child(0) {
    display: none;
}

.color-picker-hover .twitter-picker > div:nth-child(1) {
    display: none;
}

.color-picker-hover .twitter-picker > div:nth-child(2) {
    display: none;
}

.color-picker-hover .ant-popover-inner-content{

    padding: 6px 8px!important;

}
