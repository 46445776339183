.drawer-reminders .ant-drawer-content-wrapper{
    width: 350px !important;
}


.drawer-reminders .drawer-title{
    width: min-content!important;
    display: inline;
    position: relative;
}


.drawer-reminders .drawer-subtitle{
    position: absolute;
    left: 0;
    bottom: -1.8em;
    font-size: 14px;
}



.drawer-reminders .closable-button{

    position: relative;
    bottom: 8px;
    float: right

}


.drawer-reminders .reminder-item .ant-tag-magenta{
    float: right;
    min-width: 64.5px;
    position: relative;
    left: 7px;
    text-align: center;
}


.icon-button{
    position: absolute;
    top: 3em;
    right: 1.8em;
    margin-left: 3px ;
}

.drawer-reminders .reminder-item .ant-btn{
    margin-left: 4px;
}
.icon-button-container{

    position: absolute;
    top: 3em;
    right: 1.8em;

}

.drawer-reminders .ant-list-item{
    position: relative;
}