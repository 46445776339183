.print-view{
	display: none;
}

@media print {

	body{
		-webkit-print-color-adjust: exact; /*chrome*/
		color-adjust: exact; /*firefox*/
	}

	.print-view{
		display: block;
		width: 100%;
	}

	.report-header{
		background-color: #E6E7E9;
		display: flex;
		justify-content: space-between;
		height: 300px;
	}

	.report-header.black{
		background-color: black !important;
	}

	.report-logo{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.report-header .header-left,
	.report-header .report-logo,
	.report-header	.header-rigth{
		width: 30%;
	}

	.report-header .header-left{
		padding-left: 3rem;
		padding-bottom: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
	}

	.report-header .header-left.top{
		justify-content: flex-start;
		padding-top: 4rem;
	}

	.report-header .header-rigth{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 4rem;
		padding-bottom: 1rem;
	}

	.report-header.black .div-direction{
		margin-bottom: 6rem;
	}

	.header-rigth .div-blue,
	.header-rigth .div-black{
		height: 42px;
		text-align: center;
		line-height: 42px;
		font-size: 14px;
		letter-spacing: 0.1rem;
		font-weight: bold;
		width: 100%;
	}

	.div-blue{
		color: white;
		background-color: #0826FB !important;
	}

	.div-black{
		color: black !important;
		background-color: white;
	}

	.header-left .text-proveedor,
	.header-rigth .text-proveedor{
		font-weight: 900;
		font-size: 18px;
		letter-spacing: 0.1em;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.72);
	}

	.report-header.black .header-rigth .text-proveedor,
	.report-header.black .header-left .text-proveedor{
		color: white !important;
	}



	.header-left .text,
	.header-rigth .text{
		font-weight: 300;
		font-size: 11px;
		line-height: 18px;
		letter-spacing: 0.1em;
		color: rgba(0, 0, 0, 0.72)
	}

	.report-title{
		width: 100%;
		margin-top: 1.5rem;
		font-weight: 900;
		font-size: 32px;
		text-align: center;
		letter-spacing: 0.2em;
		color: #0826FB;
	}

	.factura-table{
		width: 92% !important;
		text-align: center;
		margin: 3rem 2rem 2rem 2rem;
	}

	.factura-table thead{
		font-weight: bold;
		font-size: 9px;
		letter-spacing: 2px;
		color: #404041;
	}

	.factura-table thead tr{
		height: 50px;

	}

	.factura-table tbody{
		font-weight: bold;
		font-size: 10px;
		letter-spacing: 2px;
		color: #000000;
	}

	.factura-table tbody tr{

		min-height: 40px !important;
		height: 60px;
		border:  solid black !important;
		border-width: 0 0 1px 0 !important;

	}

	.factura-table tfoot tr{
		height: 104px;

	}

	.factura-table tbody tr td{
		border:  solid rgba(0, 0, 0, 0.4) !important;
		border-width: 0px  0px 0.5px 0px !important;

	}

/*-------------------------------------------------*/
	.report-logo-lg{
		padding: 4rem;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.totales-container{
		width: 60%;

	}

	.div-totales{
		display: flex;
		color: #646465;
		padding: 1rem 0 1rem 1.5rem;

	}

	.div-totales .totales-title{
		width: 40%;
		font-weight: bold;
		font-size: 11px;
		letter-spacing: 2px;
	}

	.div-totales .totales-monto{
		width: 30%;
		text-align: right;
		font-weight: bold;
		font-size: 11px;
		letter-spacing: 2px;
	}

	.div-totales .totales-moneda{
		width: 20%;
		text-align: center;
		font-weight: bold;
		font-size: 11px;
		letter-spacing: 2px;
	}

	.totales-monto.red,
	.totales-moneda.red{
		color: #fa1e1e;
	}

	.totales-monto.yellow,
	.totales-moneda.yellow{
		color: #e1c634;
	}

	.div-balance{
		margin-top: 1.5rem;
		display: flex;
		background-color: #0826FB;
		height: 70px;
	}

	.div-balance.black{
		background-color: black !important;
	}

	.div-balance .balance-title{
		width: 40%;
		height: 70px;
		padding-left: 2rem ;
		font-weight: 900;
		font-size: 13px;
		line-height: 40px;
		letter-spacing: 0.2em;
		color: #FFFFFF;
	}

	.div-balance .balance-monto{
		height: 70px;
		padding-left: 1rem ;
		font-weight: 900;
		font-size: 18px;
		line-height: 70px;
		letter-spacing: 0.2em;
		color: #FFFFFF !important;
	}

	 .pagebreak { page-break-before: always; }


}