.PM-card-dashboard .PM-card-title {
    margin-bottom: 0 !important;
}



.statistic-financial{
    position: relative;
    top: 50%;
    transform: translateY(-60%)
}


.statistic-financial.net-worth{

    text-align: center;
    position: absolute;
    top: 55%;
    left: 18%;
    /*transform: translateY(-50%) translateX(-50%);*/
}

.statistic-financial.net-worth .ant-statistic-content-value,
.statistic-financial.net-worth .ant-statistic-content-prefix,
.statistic-financial.net-worth .ant-statistic-content-suffix {
    color: white !important;
    font-size: 30px !important;
}




.financial-health .PM-card-dashboard{
    height: 100%;
}
