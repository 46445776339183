.budgeting .text-gray-lg {
	font-weight: 300;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #858997;
	margin-bottom: 0.4rem;
}

.budgeting .text-black-lg {
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #000000;
}


.budgeting .text-gray {
	font-weight: 300;
	font-size: 13px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #858997;
	margin-bottom: 0.5rem;
}


.budgeting .text-black {
	font-weight: 300;
	font-size: 14px;
	line-height: 25px;
	letter-spacing: -0.018em;
	color: #000000;
	margin-bottom: 0.5rem;
}

.budgeting .row-blue {
	margin: 1rem 0;
	background: #0047FF;
	box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
	border-radius: 10px;
	min-height: 55px;
	font-size: 18px;
	font-weight: 800;
}

.budgeting .row-blue .ant-col {
	display: flex;
	justify-content: center;
	align-items: center;
}


.budgeting .row-blue .ant-typography {
	color: white;
}


.budgeting-collapse .ant-collapse-header {
	width: 100%;
}

.budgeting .ant-collapse-header {
	background: white;
}


.budgeting .budgeting-collapse .ant-collapse-header-text {
	width: 100%;
}

.budgeting .budgeting-collapse-header span {
	font-size: 14px !important;
	margin: 0px !important;
}

.budgeting span.ant-progress-text {
	margin-left: 8px !important;

}

.budgeting .collapse-productos .text-ligth {
	font-weight: 275;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #000000;
	font-style: italic;
}

.custom-collapse,
.custom-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    border: none !important;

}

.custom-collapse .ant-collapse-item,
.custom-collapse .ant-collapse-item {
    margin-top: 0.5em;
    margin-bottom: 5px;
    overflow: hidden;
    background: #fff;
    border: none !important;
    border-radius: 2px;
}

.progressbar{
	width: 75% !important;
}