.sec-carousel {
    background: repeating-linear-gradient();
    min-width: 200px;
    width: auto;
    max-width: 100%;
}

.sec-carousel .ant-col {
    /*padding: 0px 1rem 0px 0px !important;*/
}

/*Mas ajustes tamaños*/
.react-multi-carousel-item {
    min-width: 177px !important;
}

.sec-carousel .react-multi-carousel-item {
    display: flex;
}

.sec-carousel .ant-card {
    min-width: 165px !important;
    border-radius: 10px;
}

.sec-carousel .ant-card .ant-card-body {
    padding: 0.5rem;
}

.sec-carousel .divInfo {
    float: left;
    width: calc(100% - 25px);
}

.sec-carousel .divInfo h2,
.sec-carousel .divInfo h3,
.sec-carousel .divInfo h4 {
    float: left;
    width: auto;

}


.sec-carousel .divInfo h4 {
    margin-left: 10px;
    margin-top: 11px;
    font-size: 9pt;
}

.sec-carousel .anticon-ellipsis {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.sec-carousel .noCuenta {
    /*margin: 5px 0px 25px 0px;*/
    font-size: 10pt;
    font-weight: 600;
    color: #979797;
}

.card-saldo {
    min-height: 241px !important;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.sec-carousel .card-saldo .text-banco {
    font-weight: 600;
}

.card {
    background: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
}

.div-titulo {
    padding: 0px 0px 15px 0px;
    /*float: left;*/
}

.spnProyecto {
    background: #c4c4c4;
    padding: 10px 15px;
    border-radius: 50px;
}

.tbl-opciones {
    display: inline-flex;
    float: left;
}

.detTable-icon {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    padding: 0px;
    padding-top: 5px;
    margin-left: 5px;

}


.ant-popover {
    margin-right: 20px;
}

.ant-popover-title {
    font-size: 15pt;
    font-weight: 700;
}

.div-menuplus {
    display: inline-grid;
}


.div-menuplus .btn-azul {
    width: auto;
    height: 40px;
    background: #0047FF;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.div-menuBack .btn-azul {
    width: 100%;
    height: 35px;
    background: #0047FF;
    border-radius: 10px;
    font-weight: 200;
    font-size: 14px;
    line-height: 10px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.readOnlyFiles .ant-btn {
    display: none;
    visibility: hidden;
}

.readOnlyFiles .ant-upload-list {
    display: flex;
}

.frm-transacciones .titulo-divCont {
    font-size: 10pt;

}

.frm-transacciones .lblTitulo {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #A4A4A4;
    width: 100%;

    display: block;
}


.frm-transacciones p {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 2px;
    color: #000000;
}




.frm-transacciones .row-transaccion h2 {
    margin: 2px;
    font-weight: 700;
    font-size: 13pt;
}


.frm-transacciones .row-transaccion p {
    margin-top: 9px;
    font-size: 8pt;
}

.item-divider {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #C4C4C4;

}

.readOnlyFiles .lblTitulo {
    margin-bottom: 0px;
}

.modalEncOpciones {
    width: auto !important;
    float: right;
    margin-top: -46px;
}

.project-span {
    border-radius: 5px;
    background: red;
}

.readOnlyFiles .ant-upload-list.ant-upload-list-text {
    display: block;
}

.readOnlyFiles .ant-upload-list.ant-upload-list-text>* {
    display: block;

}

form#formulario-transacciones {
    position: relative;
    left: 5px;
}

.modal-transaccion .modal-form .ant-col {
    margin: unset;
}

.modal-transaccion .modal-form .arrow-select {
    position: relative;
    top: 13px;
}

.modal-transaccion .content-uploader {
    display: flex;
    justify-content: center;
}

.modal-transaccion .avatar-uploader {
    display: flex;
    justify-content: center;
    margin: 1em auto;
}

.modal-transaccion .avatar-uploader>.ant-upload {
    height: auto !important;
}





/**
Hay una discrepancia en los filtros. Agregué esta regla para controlarla
*/
@media only screen and (max-width: 1599px) and (min-width: 1200px) {

    .row-filter:nth-child(0) {
        width: 20%;
    }
}

/*row-filter*/

.flex-left .ant-statistic-content-prefix,
.flex-left .ant-statistic-content-value {
    margin: 0px !important;
}

.table-transacciones .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-header table thead tr th {
    font-size: 12px;
}

.table-transacciones-row td {
    font-size: 12px !important;
}

.table-transacciones-column {
    font-size: 15px;
    padding: 12px !important;
}

@media (max-width: 550px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 550px) and (max-width: 600px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.035rem !important;
    }

    .titleMxn {
        font-size: 0.65rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 600px) and (max-width: 650px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.15rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 650px) and (max-width: 750px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.3rem !important;
    }

    .titleMxn {
        font-size: 0.7rem !important;
        margin-top: 3px;
    }
}




@media (min-width: 770px) and (max-width: 850px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.035rem !important;
    }

    .titleMxn {
        font-size: 0.65rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 850px) and (max-width: 950px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.1rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.3rem !important;
    }

    .titleMxn {
        font-size: 0.7rem !important;
        margin-top: 3px;
    }
}




@media (min-width: 1200px) and (max-width: 1250px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.035rem !important;
    }

    .titleMxn {
        font-size: 0.65rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 1250px) and (max-width: 1350px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.1rem !important;
    }

    .titleMxn {
        font-size: 0.6rem !important;
        margin-top: 3px;
    }
}

@media (min-width: 1350px) and (max-width: 1450px) {

    .flex-left .ant-statistic-content-prefix,
    .flex-left .ant-statistic-content-value {
        font-size: 1.3rem !important;
    }

    .titleMxn {
        font-size: 0.7rem !important;
        margin-top: 3px;
    }
}

/*-------------------------------------------------------------------------------------*/
/*---------------------------------CSS PARA FINZNAS BELVO------------------------------*/
/*-------------------------------------------------------------------------------------*/
.component-list .component-list-item-small {
    padding: 0 0 4px 0 !important;
}

.card-list-small {
    width: 100%;
    border: none !important;
    margin: 0.3rem 0;
    border-radius: 8px;
}

.card-list-small .ant-card-body {
    padding: 0.4rem !important;
}

.card-cuenta {
    min-height: 213px;
    height: 100%;
}


.card-cuenta .ant-card-body {
    padding: 10px 0.9rem !important;
}

.card-cuenta .tag-transaccionesSC {
    padding: 4px 5px 4px 2px;
    border: none;
    font-size: 10px;
    background: #A634FF;
    border-radius: 5px;
    color: white;
    width: 100%;
    text-align: right;
    position: relative;
}

.card-cuenta .tag-transaccionesSC svg {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    left: 5px;
    top: 2px;
}

.card-cuenta .tag-transaccionesSC-y {
    padding: 4px 2px;
    border: none;
    font-size: 10px;
    background: #F59606;
    border-radius: 5px;
    color: white;
    width: 100%;
    text-align: center;
    position: relative;
}


.card-cuenta .tag-transaccionesSC-y svg {
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    left: 5px;
    top: 4px;
}


/* Botones de los cards */
.btn-container {
    width: 100%;
}

.btn-container .btn-transacciones {
    width: 100%;
    background: #A906F5 !important;
    border: none !important;
    margin-bottom: 0.5em;
}

.btn-container .btn-belvo-link {
    width: 100%;
    border: none !important;
    margin-bottom: 0.5em;
}

.btn-container .btn-belvo-link.btn-transacciones.clasificar {
    background: #a906f5af !important;
}

.btn-container .btn-belvo-link.btn-transacciones.sin-clasificar {
    background: #A906F5 !important;
}

.btn-container .btn-belvo-link.btn-facturas.clasificar {
    background: #f595069f !important;
}

.btn-container .btn-belvo-link.btn-facturas.sin-clasificar {
    background: #F59606 !important;
}




/* sin-clasificar */

/* clasificar */

.filter-purple {
    background: #a906f5 !important;
    border-radius: 10px 10px 0 0;
    border: none;
    color: white;
    width: 100%;
    max-width: 250px;
    ;
    line-height: 25px;
    text-align: center;
    padding: 0 15px;
}



.filter-purple span {
    margin-right: 5px;
}

.filter-purple .btn-icon {
    float: right;
}



.title-divider {
    display: inline;
    border-left: 1px solid white;
    margin-left: 5px;
    float: right;
}

.title-divider svg {
    position: relative;
    top: 3px;
}



.filter-yellow {
    background: #F59606 !important;
    border-radius: 10px 10px 0 0;
    border: none;
    color: white;
    min-width: 60%;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}


.filter-yellow span {
    margin-right: 5px;
}

.filter-yellow .btn-transparent {
    background: #F59606 !important;
    border: none;
    width: 20px;
    margin-right: 7px;
    padding: 7px 4px
}

.divider {
    width: 2px;
    border-right: solid 0.5px white;
    height: 25px;
    display: inline-block;
}

.float-right {
    float: right;
}

.radio-purple .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(0deg, #BD00FF, #BD00FF) !important;
    border: none;
}

.radio-purple .ant-radio-inner {
    box-shadow: inset 3px 3px 5px rgba(213, 230, 245, 0.4), inset 2px 2px 3px rgba(79, 98, 120, 0.9);
}

.radio-purple .ant-radio-checked .ant-radio-inner::after {
    background: linear-gradient(0deg, #BD00FF, #BD00FF);
    top: 0px;
    left: 0px;
    width: 14px;
    height: 14px;
}

.radio-purple .ant-radio-checked .ant-radio-inner {
    border: none !important;
}

.radio-yellow .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(25.48deg, #FF9A00 41.93%, #FF9A00 64.11%) !important;
    border: none;
}

.radio-yellow .ant-radio-inner {
    box-shadow: inset 3px 3px 5px rgba(213, 230, 245, 0.4), inset 2px 2px 3px rgba(79, 98, 120, 0.9);
}

.radio-yellow .ant-radio-checked .ant-radio-inner::after {
    background: linear-gradient(25.48deg, #FF9A00 41.93%, #FF9A00 64.11%);
    top: 0px;
    left: 0px;
    width: 14px;
    height: 14px;
}

.radio-yellow .ant-radio-checked .ant-radio-inner {
    border: none !important;
}

.text-grey-sm {
    font-size: 12px;
    color: #858997;
}

.text-money {
    font-size: 20px;
    color: #0A0A0A;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.text-money small {
    font-size: 13px;
    color: #0A0A0A;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.card-cuenta .text-banco {
    font-weight: bold;
    font-size: 19px;
}

.modal-divider span{
    font-style: normal;
    font-weight: 600!important;
    font-size: 22px!important;
    line-height: 18px!important;
    color: #0047FF!important;


}