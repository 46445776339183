/*CSS DEL HEADER DE NOMINAS*/
.row-encabezado .input-picker {
    background: none;
}

.row-encabezado .input-picker svg {
    color: white;
}

.dropdown-nominas{
    max-height: 300px;
    overflow-x: auto; 
}

.btn-transparent{
    background: transparent;
    border-color: #fff;
    color: #fff;
}
.dropdown-transparent{
    border: none;
    background: transparent;
    color: #fff;
}
.dropdown-transparent:hover,
.dropdown-transparent:focus,
.dropdown-transparent:active{
    border: none;
    background: transparent;
    color: #fff;

}
.row-encabezado .input-picker input {
    color: white;
}

.row-encabezado .input-picker .ant-picker-clear {
    background: none;
}

.row-encabezado .input-picker input::placeholder {
    color: white;
}

.text-gray{
    color: #858997!important;
}
/** CSS DE LA CARTAS **/
/*Card general*/
.content .content-card {
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 10px;
    position: relative;
    height: 100%;
    min-height: 135px;
}

/*Titulo de las cartas pequeño*/
.card-auth-text{
     color: #0A0A0A;
     width: 100%;
     line-height: 1rem;
}
.content-tiny-title{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.card-monto-title {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #858997;
    display: block;
    font-size: 24px;
    margin-bottom: 16px;
}
.card-monto-monto{
    padding: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
    text-align:center;
    display: block;
    /* font-size: 20px  */
}

.monto-red{
    color: #FD0707!important;
}
.monto-green{
    color: #00CC14!important;
}
/*Boton de "Administrar"*/
.content .content-card .content-button-manage {
    border-radius: 200px;
    color: #00CD98;
    width: 100%;
    border-color: #00CD98;
}

/*Boton de "ir a"*/
.content .content-card .button-link-to {
    position: absolute;
    top: 15px;
    right: 5px;
}

/*Elementos de la lista !**/
.content .list .list-item {
    margin-top: 5px;
    border: none;
    border-radius: 10px;
    padding: 10px 10px 0px 10px;
}


/*Titulo de los elementos de la ista*/
.content .list .item-title {
    color: #141821;
}

/*Descripcion de los elementos de la insta*/
.content .list .item-description {
    color: #858997;
}


/*AdministrarNomina.js*/

.card-cargar{
    text-align: center;
    position: relative;
}

.card-cargar .tag-cargar-1,
.card-cargar .tag-cargar-2{
    position:  absolute;
    right: 0.5rem;
    width: 50px;
    border-radius: 5px;
    padding: 1px;
    font-size: 14px;
}

.card-cargar .tag-cargar-1 svg,
.card-cargar .tag-cargar-2 svg{
    height: 12px;
    margin-right: 4px;
}

.card-cargar .tag-cargar-1{
    top: 0.5rem;
}

.card-cargar .tag-cargar-2{
    top: 2.5rem;
}

/*Carta de Nomina de la seccion*/
.content .content-card-payroll {
    background:   rgba(16 83 255 / 0.73);
}

/*Texto de la carta de nomina del grupo*/
.content .content-card-payroll * {
    color: white !important;
}

.content .content-card-payroll.authorized{
    opacity: 0.7;
}

.content .content-card-payroll.authorized:hover{
    opacity: 1 !important;
}


/*Botones de las cartas de grupos*/
.content .button-group-card {
    height: 40px;
    border-radius: 10px;
    vertical-align: middle;
}

/*Buton de Registrar*/
.content .button-group-card.button-register {
    background: #9131F0;
}


/*Buton de Registrar*/
.content .button-group-card.button-unregistered{
    background: #86827e;
}


/*Buton de Registrar*/
.content .button-group-card.button-unregistered svg{
    position: relative;
    top: 3px
}

.content .text-money-card{
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    height: 40px;
}


.content .button-group-card.button-register span,
.content .button-group-card.button-unregistered span{
    position: relative;
    bottom: 0px;
}


/*Icono del boton*/
.content .button-authorize .icon-button {
    color: white;
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
    right: 5px;
}

.content .button-group-card.button-authorize {
    background: #40F031;
}


.content .button-group-card.button-no-authorize {
    background: #d4d824;
}
/*Buton de revisar*/
.content .button-group-card.button-review {
    background: #C3C6D1;
}


/** RevisionDeGrupo.js  */
/*Heder de la informacion del grupo*/
.content .content-card .information-content {
    padding-top: 4px;
}


/*Para el header de la seccion de reivsar grupo*/
.content .content-card .information-content .information-content-title {
    display: inline;
    margin-right: 1em;
    color: #A4A4A4;
    font-size: 18px;
    font-weight: 400;
    float: right;
}

/*Avatar de la seccion de informacion del grupo*/
.content .content-card .information-content .information-content-avatar {
    position: relative;
    bottom: 6px;
    float: right;
}


/*Seccion de informacion especifica del grupo, la que estaá abajo del titulo,.*/
.content .content-card .information-widgets-content {
    margin-top: 2em
}

/*Titulo de la informacion especifca del gruopo*/
.content .content-card .information-widgets-content .information-widgets-content-title {
    margin-bottom: 2em;
    color: #A4A4A4;
}

/*Desrcipion de la información especifca del grupo*/
.content .content-card .information-widgets-content .information-widgets-content-description {
    margin-top: 1em;
    color: #000000;
    font-weight: bold;
    letter-spacing: 2px;
}


/*Esto es el elemento ListItem, nada mas es espacio entre los rows*/
.content .list-container .list-employees .list-employees-item-content {
    padding: 6px 0;
    position: relative;
}


/*Este ya es el row del la lista de empleados*/
.content .list-container .list-employees .list-employees-item-content .list-employee-item {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10px 10px 10px 30px;
    box-shadow: 0px 5px 15px rgba(209, 213, 223, 0.2);
}



.content .list-container .list-employees .list-employees-item-content .list-employee-item.list-employee-financial-item > .ant-col{
    width: 20%!important;
    max-width: 20%;
}

/* PARA LOS INPUTS FANTASMONES*/
.input-ghost {
    color: #222222;
    border: none;
}


/*GRUPOS.js*/

/* SOLAMENTE PARA LA ANIMACION DEL ICONO*/

/*.group-collapse .plus_icon {
    top: -88px;
}*/
.plus_icon {
    position: relative;
    width: 5px;
    height: 5px;
   /* margin: 100px auto;*/
    z-index: 400;
}

.plus_icon:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon:after {
    content: "";
    position: absolute;
    margin-top: -5px;
    margin-left: 5px;
    width: 2px;
    height: 12px;
    background: #0047FF;
    display: inline-block;
}

.plus_icon.open:after {
    margin-top: 0px;
    width: 2px;
    height: 2px;
}

.plus_icon:before,
.plus_icon:after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.group-collapse {
    background: none;
}

.group-collapse .group-collapse-panel {
    background: rgba(183, 203, 232, 0.34);
    border-radius: 8px !important;
    margin-bottom: 1em;
}

.information-destajo .ant-col-4 {
    min-width: 20%;
}

.group-collapse .group-collapse-panel .ant-collapse-content {
    background: white;
}

/*--------------BOTONES DE LA LISTA ------------------------------------------------*/

.content .group-collapse .button{
    border: 0;
    width: 29px;
    height: 29px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0;
    padding-top: 5px;

}

.content .group-collapse .button.button-people {
    background: #C345FF;
    border-radius: 5px;
   /* position: relative;
    top: 2px;*/
}
/*
.content .group-collapse .button.button-people svg{
    position: relative;
    top: 3px;
}*/

.content .group-collapse .button.button-plus {
    background: #ADFF45;
    border-radius: 5px;
}

.content .group-collapse .button.button-edit {

    background: #456EFF;
    border-radius: 5px;


}

.content .group-collapse .button.button-copy-group{
    background: #ffd954;
    border-radius: 5px;

}

.content .group-collapse .button.button-delete {
    background: #FF547D;
    border-radius: 5px;
}

/*------------------CONTENIDO DE LA LISTA------------------*/
.content .group-collapse .center-left{
    display: flex;
    align-items: center;
    min-height: 35px;
}

.content .group-collapse .group-title{
    font-size: 16px;
    color: #272727;
    margin: 0;

}

.content .group-collapse .group-description{
    font-size: 14px;
    color: #0047FF;
    margin:0;
}
.content .group-collapse .list-employees{
    position: relative;
    left: 44px;
}

.content .group-collapse .tag-element{
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
}

.content .group-collapse .tag-element:hover{
    cursor: pointer;
}



.btn-save-financial{
    display:block;
    position:fixed;
    bottom:10px;
    right:10px;
    z-index: 1;
    border-radius: 100px;



    background: linear-gradient(126.63deg, #A074FF 11.98%, #000AFF 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);




    width: 80px;
    height: 80px;
}


.project-selection {
    min-width: 200px;
}

.btn-save-financial:hover{
    background: linear-gradient(126.63deg, #A074FF 11.98%, #000AFF 83.35%)!important;
}


.number-element{
    position: absolute;
    padding-top: 1px;
    top: 30%;
    left: -10px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background: #ff8080;
    text-align: center;
    color: white;
}

/*position: relative;*/
/*left: 40px;*/
/*}*/

/* Boton para cambiar tipo de modalidad  */
.toogle-nominas-checkbox .ant-radio-button-wrapper{
    background: transparent;
    border: 1px solid #fff;
    color: #fff;

}
.toogle-nominas-checkbox .ant-radio-button-wrapper:hover{
    background: #fff;
    color: #000 !important;
}
.toogle-nominas-checkbox .ant-radio-button-wrapper-checked{
    background: #fff ;
    color: #000000;
}


.image-preview{
    width: 150px;
    margin-right: 10px;
    border-radius: 5px;
}

.image-preview-empleado{
    width: 100%;
    margin-right: 10px;
    border-radius: 5px;
}


.slider-element .ant-carousel {
    max-width: 100%;
}


.image-previewer{
    margin-top: 10px;
    width: 100%!important;
    max-width: 1000px;
}

.slick-dots-bottom li {
    -webkit-box-shadow: 2px 2px 5px 0px rgba(199,199,199,1);
    -moz-box-shadow: 2px 2px 5px 0px rgba(199,199,199,1);
    box-shadow: 2px 2px 5px 0px rgba(199,199,199,1);
    position: relative;
    top: 40px
}

.image-previewer.empleado .ant-modal-body{
    position: relative;

}

.carousel-button{
    border-radius: 100%;
    -webkit-box-shadow: 3px 3px 15px 0px rgba(209,209,209,1);
    -moz-box-shadow: 3px 3px 15px 0px rgba(209,209,209,1);
    box-shadow: 3px 3px 15px 0px rgba(209,209,209,1);
    opacity: 0.4;

    border: 1px solid white!important;

    position: absolute;
    top: calc(50% - 20px);
    background: none!important;
}

.carousel-button:hover{
    opacity: 1;
    border: 1px solid white;
}

.carousel-button.left-control{
    left: 40px;
}

.carousel-button.right-control{
    right: 40px;
}



.spinner-element .ant-spin-nested-loading{
    width: 100%;
}


.nomina-selected{
    background-color: rgba(255, 255, 255, 0.2) !important;
}


/* NOMINAS V3 */

.list-inmuebles-revision{
    width: 100%;
}
.list-inmuebles-revision  .ant-list-header{
    padding: 0;
    margin: 0;
}

.list-inmuebles-revision .ant-list-item{
    padding: 16px 4px;
}

.title-row{
    text-transform: uppercase;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #222222;


}
.borderless{
    border: none!important;
    margin: 0;
}
.title-inmueble-revision{
    padding: 8px;
}
.border-radius{
    border-radius: 10px!important;
}
.item-render{
    padding:5px
}
.item-tag{
    background: #A906F5;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
  
}
.item-tag .title-row{
    color: #fff;
}
.item-row{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;


}

@media screen and (min-width:576px){
    .item-tag{
        width: 80%;
    }
}

/* Revisión Preeliminar */
.finish-nomina {
    position: fixed;
    bottom: 0;
    right: 4em;
    font-size: 1.4em;
    border-radius: 8px 8px 0px 0px;
    
    background: linear-gradient(180deg, #2bd600 0%, #00de59 100%);
    
    box-shadow: 0 -.125rem .25rem rgba(0,0,0,0.075)!important;
    font-weight: bold;
    border: none !important;
}

.finish-nomina:hover {
    background: linear-gradient(180deg, #36F905 0%, #00FF66 100%) !important;
}

.finish-nomina:focus {
    background: linear-gradient(180deg, #2bd600 0%, #00de59 100%) !important;
}
