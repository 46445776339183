.container {
    position: relative;
}

.tooltip {
    position: absolute;
    color: #000 !important;
    padding: 10px;
    background: rgba(255,255,255,0.8) !important;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 10;
    min-width: 200px;
}

.tooltip-white{
    background: rgba(255,255,255,0.8) !important;
    color: #000 !important;

}


.cardPlano .TituloCardPlano{
font-weight: bold;
line-height: 43px;
color: #061079;
font-size: 20pt !important;
margin-bottom: 5px !important;
}

.cardPlano{
    background: transparent !important;
    float: left;
}

.cardVentasGenerales{
    width: 100%;
    float: right;
    max-width: 700px;
}


.cardEstadisticasGenerales{
    width: 100%;
    float: left;
    max-width: 480px;
}

.cardPlano .divInfo{
    margin-bottom: 0px;
}
 

.divEstadisticas  .divInfo{
    margin-top: 5px !important;
    float: left;
    width: 100%;
    max-width: 440px;
}


.cardPlano .spnVendidas,
.cardPlano .spnApartadas,
.cardPlano .spnDisponibles
{
    border-radius: 5px;
    width:  30px;
    height: 30px;
    float: left;
    margin: 4px 10px 0px 0px;
}


.cardPlano .spnVendidas {background: #00FF19;}
.cardPlano .spnApartadas{background: #EB00FF;}
.cardPlano .spnDisponibles { background: #0075FF;} 



.cardPlano .cardInfo  h1,
.cardPlano .cardInfo  h2,
.cardPlano .cardInfo  h4  {
    width: auto;
    float: left;
    margin-right: 5px !important;
    margin-top: 5px !important;
} 


.cardPlano .cardInfo  h2,
.cardPlano .cardInfo  h4  {
    margin-top: 8px !important;
    margin-left: 5px !important;
} 


.cardPlano .cardInfo  h4  {
    text-align: right !important; 
    float: right !important;
} 

.cardPlano .spnEntradas  , 
.cardPlano .spnSinEntradas
{
    width:  50px;
    height: 50px;
}

.cardPlano .spnEntradas   {background: #FF008A;}
.cardPlano .spnSinEntradas{background: #000;}

.cardPlano .div-entradas h1 {
    font-size: 14pt;
    font-weight: bold !important;
    margin-top: 15px !important;
}


.cardPlano .div-entradas {
    width: 100%;
    max-width: 300px !important;
    float: left;
}


.cardPlano h1 ,
.cardPlano h2 ,
.cardPlano h4 {
    color: #061079;
    font-weight: normal;
    margin:0px !important;
    padding: 0px !important;
}


.cardPlano h1 {
    font-size: 13pt !important;
}

.cardPlano h2 {
    font-size: 11pt !important;
    font-weight: 600;
}

.cardPlano h4 {
    font-size: 11pt !important;
    font-weight: bold;
}

 
.div-ventasGenerales{
    height: 120px;
    background: white !important;
    float: left;
    border-radius: 10px;
    margin: 0px !important;
    margin-bottom: 20px !important;
    padding: 5px 12px;
    box-shadow: 0px 10px 15px rgba(25, 29, 56, 0.233);
    min-width: 250px !important;
    width: 60% !important;
    margin-right: 10px !important;
    max-width: 320px !important;
    
}


.elem-uploader {
    cursor: pointer !important;
    border :solid 1px red !important;
    position: relative;
    display: initial !important;
    min-width:  100px !important;
    min-height: 100px !important;
}


.txtAviso{
    width: 100%;
    text-align: center;
    margin-top: 12%;
     
}

.cardPlano .flex-left-column{
    margin-left: 15px;
}



.div-ventasGenerales .ant-col{
    /*display: inline-block;*/
}

.div-ventasGenerales .ant-progress {
    /*margin-right: 15px;
    top: -10px !important;
    display: block;
    padding:5px !important; */
}

.div-ventasGenerales h1{
   /* margin-top: 10px !important;*/
}
