
.collapse-productos .ant-collapse-item{
    margin-bottom: 0.5rem;
}

.collapse-productos .ant-collapse-header {
    display: flex;
    background: #0047ff;
    border: 0px;
    border-radius: 8px!important;
    color: #FFFFFF;
    
}





/* margin-left: 1em; */
.collapse-productos .ant-collapse-item .ant-collapse-content{
    background: #FFFFFF;
    
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    line-height: 50px;
    color: #FFFFFF ;
    font-weight: 600;
}

.collapse-productos .card-list{
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}
.collapse-productos .card-list .ant-card-body{
    padding: 0.5rem !important;
}

.header-collapse span {
    
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF ;
    margin-left: 1.2em;
}

.collapse-text-big {
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}

.collapse-productos .ant-collapse-item{
    border:  none !important;
}


.icon-pro{

    position: absolute;

    top: 50%;
    transform: translateY(-50%);

    color: white;


}

.option-actions:hover svg{ 
    color: gray !important;
}

.select-actions .ant-select-selection-item button{
    display: none;
}


.historial-precio .card .text-grey{
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #858997;
}

.historial-precio .card .text-blue{
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #0047FF;
    margin-bottom: 0.5rem;
}

.historial-precio .card .text-green{
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #00EE0A;
}

.historial-precio .card .text-red{
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #F00D0D;
}

.historial-precio .card .text-proveedor{
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #858997;
}

.historial-precio .card .text-alias{
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #858997;
}

.historial-precio .card .text-grey-lg{
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #858997;
}