/**
Codigo CSS de Manuel

*/
.logo{
    height: 90px;
}
.header{
    display: flex;
    width: 100vw !important;
    max-width: 100% !important;
    height: 96px;
}

.header-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    min-height: 50px;
}

.header-logo{
    background: #0047ff !important;
    height: 110px;
    /*display: inline-table;*/
}

.header-logo .logo{
  /*  float: left;*/
    margin: 0px;
    height: auto;
    max-width: 350px;
}

.header-search
{
width: 50%;
max-width: 600px;
}
.search-header   .ant-input-group ,.search-header .ant-input-affix-wrapper{
    height: 100% !important;
}

.search-header .ant-input-affix-wrapper{
    margin-top: 5px;
}

.search-header,
.search-header:hover,
.search-header:active,
.search-header:focus
{
    width: 100% !important;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.54px;
    color: #757994;
    mix-blend-mode: normal;
    border-radius: 15px;
    background: #EFF3FB;
    margin-left: 5%;
}

.search-header input{
    height: 100%;

}
.search-header .ant-input-group-addon .ant-input-search-button{
    mix-blend-mode: normal;
    border-radius: 10px !important;
    width: 40px;
    height: 40px;
    float: left;
    margin-left: -10px;
    background: #d3dff7;
    border-color: transparent!important;
    color: #6672FB;
    font-size: 11.5pt;
}
.search-header .ant-input-group-addon .ant-input-search-button:hover,
.search-header .ant-input-group-addon .ant-input-search-button:focus{
    /* border-color: transparent!important; */
    background: #d3dff784;
}
.search-header .ant-input-group-addon span{
    color:#6672FB
}
.search-header .ant-input-suffix{
    display: none;
}

.header-options{
    display: flow-root;
    width: 250px;
    margin-right: 20px;
    align-items: center;
    justify-content: space-around;
    text-align: right;
}

.header-options div{
    width: auto !important;
    display: inline-block;
}

.header-options .icon-Notification
{

    color:#cccccc;
    font-size: 17pt;
    margin-bottom: -7PX;
}

.header-options .ant-badge-count{
    background-color: #5e45d7;
}

.avatar-user{
    margin-right: 10px;
}

.header-user-drop{
    color: #000000 !important;
    font-weight:bold;
    font-size: 10pt;
    letter-spacing: 1px;
}

.btnSearchClear,
.btnSearchClear:active,
.btnSearchClear:hover{
    mix-blend-mode: normal;
    border-radius: 10px;
    background: #d3dff7;
    color: #6672FB;
    margin-left: 0px;
    float: right;
    width: 70px;
    height: 40px;
    margin-top: -40px;
    margin-right: -24px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 10pt;
    display: none;
}


/* xs */
@media (max-width: 480px){
    .logo{
        height: 60px!important;
        margin-top: 13px;
    }
    .header-logo{
        background: #0047ff !important;
        width: 100%;
        height: auto;
        position: relative;
    } 

    .header-logo .logo{
        display:block;
        margin:auto;
    }

    .header-logo .responsive-menu-button{
        position: absolute;
        left:0;
        top: 35% !important; 
        transform: translateY(-40%);
        font-size: 1.5rem;
    }

    .header-content{
        display:flex;
        flex-flow: column;
        align-items: flex-end;
        height: auto !important;
        padding-top: 1rem;
    }

    .header-content .header-search{
        order: 2;
        width: 100%;
        padding: 1rem 2.5rem 1rem 0;
    }

    .header-content .header-options{
        order: 1;
    }
}

/* sm  */
@media (max-width: 768px){
    .logo{
        height: 80px!important;
        margin-top: 13px;
    }
    .header-logo{
        background: #0047ff !important;
        width: 100%;
        height: auto;
        position: relative;
    } 

    .header-logo .logo{
        display: block;
        margin:auto;
    }

    .header-logo .responsive-menu-button{
        position: absolute;
        left:0;
        top: 35% !important; 
        transform: translateY(-40%);
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .header{
        display: contents !important;
    }
    .header-content{
        height: 80px;
        margin-top: 0px !important;
    }
}

@media (max-width: 1200px) and (min-width: 768px){
    .header .header-logo{
        /*max-width: 250px!important;*/
        transition-duration: 1s !important;
    }

    .header .header-logo .logo{
        max-width: 250px;
        transition-duration: 1s !important;
    }
}

@media (max-width: 1200px) and  (min-width: 769px) {
    .header .header-logo .logo{
        margin-top: 2em;
    }
}
