/*
	CSS DE CARDS.JS
*/

.PM-card-dashboard,
.PM-card-progress{
	border-radius: 10px;
	border: none;
	box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
}

.hover-effect:hover {
	background: #d7d7d7;
	cursor: pointer;
}

.hover-effect:hover *{
	color: white !important;
}


.PM-card-dashboard .ant-card-body{
	padding-top: 0.5rem;
	padding-bottom: 0.6rem;
}

.PM-card-dashboard .PM-card-title{
	color: white;
	margin-bottom: 1.5rem;
	font-size: 20px;
	font-weight: bold;
}

.PM-card-dashboard .PM-card-body{
	color: white;
	text-align: center;
	font-size: 20px;
}

.PM-card-progress .card-progress-header{
	min-height: 80px;
}

.PM-card-progress .card-progress-title{
	margin-bottom: 0px;
	font-size: 12px;
	color: #858997;

}

.PM-card-progress .card-progress-name{
	margin-bottom: 0px;
	font-size: 20px;
	font-weight: 600;
	color: #0A0A0A;

}

.PM-card-progress .card-progress-dates-col{
	display: flex;
	justify-content: center;
	padding: 6px 15px;
}

.PM-card-progress .card-progress-dates{
	font-size: 12px;
	color:#858997;
}

.PM-card-progress .card-progress-bar{
	padding: 6px 15px;
}

.PM-card-progress .card-progress-avatar{
	display: flex;
	justify-content: center;
	align-items: center;
}

.PM-card-activity .card-activity-main
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.PM-card-activity{
	margin-bottom: 1rem;
	border-radius: 10px;
}

.PM-card-activity .activty-tag{
	font-size: 12px;
	color: #0047FF;
	border-radius: 10px;
	min-height: 14px;
	min-width: 60px;
	text-align: center;
}

.PM-card-activity .card-activity-main{
	padding: 5px;
}

.PM-card-activity .card-activity-name{
	font-size: 16px;
	font-weight: 400;
	color: #141821;
	margin: 0px;
}

.PM-card-activity .card-activity-date{
	font-size: 14px;
	font-weight: 400;
	color: #858997;
	margin: 0px;
}

/*CSS DEL COMPONENTE CardInfo y CardProgressBar*/
.PM-card-info,
.PM-card-bar{
	min-height: 100px;
	height: 100%;
	border-radius: 10px;
	background: white;
	padding: 0.5rem;

}

.PM-card-info.hoverable:hover{
	background: rgb(191,191,191)!important;
}


.PM-card-info .card-info-titles,
.PM-card-bar .card-bar-main{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 30px;
	font-size: 11px;
	text-align: center;
}

.PM-card-info .card-info-infos,
.PM-card-bar .card-progress-bar{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 60px;
	text-align: center;
}

.PM-card-info .card-info-title,
.PM-card-bar .card-bar-title{
	color: #858997;
}

.PM-card-info .card-progress-name{
	color: #0A0A0A;
	font-weight: 600;
}

.PM-card-info .card-progress-name a{
	color: #0A0A0A;
	font-weight: 600;
}


/*CSS DEL COMPONENTE CardProgressBar*/
.PM-card-bar{
	height: 100%;
	border-radius: 10px;
}

.PM-card-bar .card-progress-bar{
	padding: 0px 24px;
}

/*CSS DEL COMPONENTE CardProject*/
.PM-card-project{
	min-height: 200px;
}


.PM-card-dashboard .quantity-title{
	text-align: center;
	color: white;

}

.PM-card-project .card-progress-header{
	min-height: 120px;
	padding-top: 0.5rem;
}
.PM-card-project .button-delete-project{

	position: absolute;
    right: -9px;
    top: -9px;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 100%;
    background: red;
    color: white;
    font-weight: bold;
    border: none;


}

.center-info{
	position: absolute;
	top: 25%;
	left: 65%;
}


.btn-admin{
	background: #19E08D;
	width: 118px;
	height: 28px;
	border-radius: 13px;
	border: none;
	font-weight: 500;
}


/*----------------------------------CARD DE PROVVEDORES------------------------------------------*/

.card-proveedores{
	border-radius: 10px;
	border: none;
	box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
	height: 100%;
	margin-bottom: 0.5rem;
}

.card-proveedores .ant-card-body{
	padding: 1rem 1.5rem;
	display: flex;
    flex-flow:column;
    height: 100%;
}

.card-proveedores .content-title{
	flex: 1 1 auto;
	min-height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5rem;
}

.card-proveedores .card-title{
	font-weight: 600;
	font-size: 12px;
	color: #858997;
}

.card-proveedores .title-proveedor{
	font-weight: 600;
	font-size: 20px;
	color: #0A0A0A;
}

.card-proveedores .text-data-title{
	font-weight: bold;
	font-size: 12px;
	color: #858997;
	display: block;
	text-align: center;
}

.card-proveedores .text-data{
	font-weight: 300;
	font-size: 12px;
	color: #858997;
	display: block;
	text-align: center;
}

.card-proveedores .badge-facturas{
	border-radius: 10px;
	font-size: 10px;
	height: 14px;
	line-height: 12px;
	font-weight: bold;
}

.card-proveedores .text-concepto{
	font-size: 16px;
	line-height: 24px;
	color: #141821;
	display: block;
}
.card-proveedores .text-proveedor{
	font-size: 16px;
	line-height: 24px;
	color: #141821;
	font-weight: 300;
	display: block;
}

.card-proveedores .text-monto{
	font-size: 14px;
	color: #858997;
}

.download-btn-card{
	position: absolute;
	top: 0;
	right: 6px;
}

.download-btn-card-2{
	position: absolute;
	top: 0;
	right: 40px;
}


/*----------------------------------CARD TRANSACCIONES SIN CLASIFICAR---------------------------------------------*/
.card-transacciones{
	border-radius: 10px;
	border: none;
	height: 100%;
}
.card-transacciones .ant-card-body{
	padding: 0.5rem 1rem;
}

.card-transacciones .text-concepto,
.card-transacciones .text-proveedor{
	display: block;
}

/*----------------------------------CARD EMPRESA--------------------------------------------*/

.card-project .ant-card-head,
.card-task-progress .ant-card-head,
.card-address .ant-card-head,
.card-utilidad .ant-card-head {
    padding: 0rem 1rem;
    border-bottom: 0;
    height: 20px;
}

.card-project .ant-card-body,
.card-task-progress .ant-card-body,
.card-address .ant-card-body {
    padding: 0 1rem 1rem 1rem;
}

.card-project {
    overflow: auto;
    margin-right:1rem ;
    border-radius: 10px;
}

.card-project .ant-card-head-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.3px;
    color: #121212;
}


.card-rs-blue{
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #2D348C;
}

.card-rs-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: #121212;
}

.card-rs-cantidad{
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #121212;
    margin-top: 4px;
}

.texto {
	font-size: 10px;
}


.card-proyecto .ant-card-body{
	display: grid;
}