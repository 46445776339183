.main, .main-content {
    width: 100%;
}

.main-dashboard{
    position: relative;
    /* background: url(/images/dashboard_background.jpg) center top no-repeat fixed rgb(24, 126, 236); */
    /* background-image: url(/images/dashboard_background.jpg);*/

}

.main-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position-x: 180px;
    background-position-y: center;
    background-size: 100% auto;
    background-attachment: fixed;
    background-origin: initial;
    background-clip: initial; 
    filter: blur(5px);

    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}


.main-content {
    padding: 2em;
}


.title {
    text-align: left;
    margin-bottom: 40px !important;
}

.sample-data {
    text-align: center;
}

.sample-data .sample-data-title {
    font-size: 12px;
    color: #222222;
}

.sample-data .sample-data-content {
    font-size: 30px;
    margin-bottom: 0;
}

.colour1 {
    color: #9013FE;
}

.colour2 {
    color: #FF0165;
}

.colour3 {
    color: #1630F4;
}

.sample-data-card {
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.0296649);
    border-radius: 12px;
}


.sample-data-card .ant-card-body {
    padding: 12px !important;
}


.Encuestas {
    height: 100px;
}


.sample-data-card2 {
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.0296649);
    border-radius: 12px;
}

.sample-data2 {
    text-align: center;
}

.encuesta {

    height: 30px;
}

.Report1 {
    height: 30px;

}

.simple-data-linea {


    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.1px;

    color: #222222;

    mix-blend-mode: normal;
    opacity: 0.6;

}

.sample-data-table {
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.0296649);
    border-radius: 12px;
    color: #9013FE;

}

/*-------CARDS DASHBOARD------*/
.div_contenido {
    background: #FFF;
    border-radius: 10px;
    padding: 10px;
   
}

.card-ingresos{
    background: linear-gradient(153.59deg, #23C496 21.59%, #28C771 67.61%);
    border-radius: 10px;
    min-height: 150px;
}

.card-egresos{
    margin-top: 1rem;
    background: linear-gradient(180deg, #007CE4 17.29%, #0267BC 100%);
    border-radius: 10px;
    min-height: 150px;
}

.card-saldos{
    background: white;
    border-radius: 10px;
    padding: 0.5rem;
}

.card-transacciones-d,
.card-revenue,
.card-last-transactions{
    background: white;
    border-radius: 10px;
    padding: 0.5rem;
}

.card-revenue{
    height: 100%;
}

.card-last-transactions{
    float: left;
    width: 100%;
    min-height: 100%;
}

.gf-Egresos {
    color:'#FFFFFF' !important;
      background: linear-gradient(108.42deg, #d855d8 -16.67%,  #df4efc 78.2%);
      background: linear-gradient(180deg, #007CE4 17.29%, #0267BC 100%);

}

.div-titulo {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.titulo-divCont {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #858997;
    float: left;
    width: calc(100% - 60px) !important;
}


.div-transaccion {
    display: flex;
}

.div-transaccion .ant-col {
    padding: 10px 5px;

}

.div-transaccion .ant-avatar {
    width: 50px;
    height: 50px;
    margin-top: 0px;
}

.div-transaccion h2 {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 0.95rem;
    width: calc(100% - 60px);
}

.div-transaccion span {
    font-weight: 600;
    font-size: 1rem;
    margin-top: 10px;
    display: block;
}


.div-transaccion .div-info {
    min-width: calc(100% - 200px);
}

.div-info-tran{margin-bottom: 10px !important;}

.div-info-tran .ant-avatar-string{
    margin-top: 6px;
}

.spn-red {
    color: #FF0000;
}

.spn-green {
    color: #00FF19;
}


.btn-goto {
    background: #0047ff;
    color: #FFF;
    border-radius: 10px;
    font-weight: 600;
    font-size: 15pt;
    width: 40px;
    padding-left: 10px;
    float: right;
}

.div_transacciones {
    float: left !important;
    width: 100%;
    display: block;
}

.statistic .ant-statistic-content-prefix,
.statistic .ant-statistic-content-value,
.statistic .ant-statistic-content-value-int,
.statistic .ant-statistic-content-value-decimal
{
    display: unset;
}


.color-balance-red span{
    color: red !important;
}

.color-balance-green span{
    color: green !important;
}

.record{
    width: 100% ;
    padding: 3px 10px;
    text-align: center;

    font-weight: bold;


    border-radius: 150px;
    -webkit-box-shadow: 2px 2px 3px 0px rgba(201,201,201,1);
    -moz-box-shadow: 2px 2px 3px 0px rgba(201,201,201,1);
    box-shadow: 2px 2px 3px 0px rgba(201,201,201,1);

}


.arrow{
    position: relative;
    left: 0.3em;
    bottom: 2px
}

.filter-element{
    display: block;
    margin: 5px auto;
    text-align: center;
    color: white;
    cursor: pointer;
}

.mt10{
    margin-top: 1rem;
}
.pr10{
    padding-right: 0.5rem;
}


/** NUEVAS CARTAS TRANSPARENTES **/

.card-dashboard{
    width: 100%;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.7);
    border: none ;
    max-height: 500px;
    height: 100%;
}

.card-dashboard{
    width: 100%;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.7);
    border: none ;

    max-height: 450px;
}


.card-dashboard .select-project .ant-select-selector{
    background: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.list-projects{
    overflow: auto;
    height: 350px; 
}

/*
* Pone un borde a un elemento para separar del resto
*/
.border-bottom{
    border-bottom:solid 1px rgba(0, 0, 0, 0.1);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}

/*
*  limita la altura de un div para que haga scroll
*/
.card .scroll-body{
    max-height: 80%;
    overflow: hidden;
    overflow-y: auto;
}

/*
*  Estilo del titulo de un row dentro de los card
*/
.card .col-title{
    font-size: 18px;
    font-weight: bold;
    color: black;
}

/*
*  Estilo standar para los tags de los cards
*/
.card .status-tag{
    border-radius: 12px;
    padding: 0 9px;
    color:  black;
}
