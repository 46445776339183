.mdlFrm-logo {
    display: inline-block;
}


.mdlFrm-logo .ant-form-item-label {
    text-align: center;
}

.mdlFrm-logo   > .ant-form-item-label{
    width: 100% !important;
}
.mdlFrm-logo .ant-upload.ant-upload-select-picture-card{
    text-align: center;
}

.modal-rubro{
    max-width: 500px !important;
}

.modal-empleado-tajos{
    max-width: 700px !important;
}

.txt-descripcion{
    min-height: 120px !important;
}
.info-empleado-pago{
    position: relative !important;
    bottom: 5px !important;
}


.frm-cliente .ant-row > .ant-col{
    margin-top: 30px;
}

.frm-cliente .ant-form-item-label,
.frm-cliente .ant-form-item-control
{
    margin: 0px !important;
    padding: 0px !important;
    margin-bottom: 10px !important;
}

.modal-form .arrow-select{
    position: relative;
    top: 0px;
}

.modal-form .ant-input-group-addon{
    border: none !important;
    width: 43%;
    text-align: left;
    background-color: transparent;
    padding-top: 4px;
}

.modal-form .ant-input-group-addon .ant-select{
    width: 115%;
}

.modal-form .ant-input-group-addon .ant-select-selector{
    padding-right: 0px;
    width:100%;
}

.modal-form .ant-input-group-addon .ant-select-selection-item{
    min-width: 90px;
    padding-right: 0px;
}



.modal-recordatorio .lblTitulo {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #A4A4A4;
}

.modal-recordatorio .pInfo {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 3px;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    color: #000000;
}


.modal-recordatorio .pUser {
    margin-top: 25px !important;
    margin-bottom: 15px !important;
    font-size: 15px;
}

.modal-detCalendario .ant-modal-header,
.modal-detCalendario .ant-modal-close,
.modal-detCalendario .ant-modal-footer
{
    display: none;
    visibility: hidden;
}


.modal-detCalendario .closable-button{
    left: 12px !important;
}

.modal-detCalendario .modal-title  {
     left: 50px;
     width: calc(100% - 100px);
     margin:0px;
     padding-bottom: 15px;
}


.modal-recordatorio,
.modal-cliente,
.modal-detCalendario
{
    width: 90% !important;
    max-width: 1200px !important;
    z-index: 100 !important;
}

#formulario-recibo .ant-col {
    margin-bottom: 25px;
}


.modal-form .lblTitulo{
    margin-bottom: 6px;
}

#formulario-recibo .lblTitulo {
    color: #AAA;
}


#formulario-recibo .pInfo {
    margin-top: 30px !important;
    margin-bottom: 50px;
    font-weight: bold;
}

#formulario-recibo  .ant-form-item-control {
    margin-bottom: 0px;
}

.modal-recibo   .modal-title {
    width: 78%;
    margin-left: 70px;
}

/* --------------------MODAL PARA GRUPOS POR NOMINA------------------------- */

.modal-grupos .modal-header{
    display: flex;
}

.modal-grupos .modal-header .modal-title{
    display: block;
    margin: auto;
}

.modal-grupos .modal-header .closable-button{
    border:solid 1px red;
    height: 80%;
    border:none;
}

.modal-grupos .modal-info-responsable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}
.modal-grupos .modal-row-header{
    background-color: #cccccc;
    text-align: center;
    border-radius: 8px;
    margin-top: 15px;
    padding-top: 0.5rem;
}

.modal-grupos .modal-row-body{
    text-align: center;
    margin-top: 15px;
}

.modal-grupos .modal-row-footer{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.modal-grupos hr{
    border:none;
    border-top: solid 1px #aaabbb;
}

/* --------------------MODAL ADMINISTRAR TAJOS------------------------- */

.modal-form-large {
    max-width: 900px !important;
}

/* --------------------MODAL  PONDERACION ------------------------------ */

.modal-form .row .ant-form-item-control-input-content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-form .row .ant-col{
    margin:0;
}

.modal-form  .form-select .ant-select-selection-item{
   padding-top: 5px !important;
}

.modal-form  .form-select .ant-select-selection-item{
    max-height: 70px;
}

.modal-form  .form-select.sm .ant-select-selection-item{
    max-height: 50px;
}

.modal-form  .form-select .ant-select-selector{
    max-height: 40px !important;
}

.modal-form .form-select .ant-select-selection-placeholder{
    z-index: 100;
}

.modal-form .tiny-input{
    max-width: 40%;
    margin-right: 1rem;
}

.modal-form .modal-hr-blue{
    border-color: #0047ff;
    width: 80%
}

.modal-tajo .ant-modal-content{
    max-width: 600px;
    display: block;
    margin: 0px auto;
}
#formulario-tajo{
    position: relative;
    left: 8px
}


.modal-obras{
    animation-duration: 0.45s;
    
}


/*Modal QR*/
.modal-qr .modal-header{
    margin-bottom: 25px !important;
}

.modal-qr .modal-title{
    width: 300px;
    margin: auto;
    margin-top: -40px !important;
    margin-bottom: 10px !important;
}

.modal-qr .ant-result-success{
    padding-top: 0px;
}
.modal-qr .ant-result-icon{
    margin: 30px !important;
}
.modal-qr .ant-result-subtitle{
    font-size: 15pt !important;
    width: 80%;
    margin: auto;
    margin-bottom: 30px !important;
}

.modal-qr .btn-azul{
    height: 40px !important;
    font-size: 13pt !important;
}




/*Modal ordenes material*/
.modal-ordenMaterial{
    /* max-width: 1000px !important; */
}

.modal-ordenMaterial .title-date{
     float: left;    
     margin-right: 10px;
}

.modal-ordenMaterial .col-date strong{
    margin-top: 3px;
    float: left;
    font-size: 13pt;
}

.modal-ordenMaterial .component-list .ant-typography-secondary{
    color:#777 !important;
    font-size: 12pt !important;
    font-weight: bolder;
}


.modal-ordenMaterial .row-itemList-ordenesMaterial{
    padding-top: 5px;
}

.modal-ordenMaterial .ant-modal-body{
    height: auto;
    overflow: auto;
    padding-bottom: 0px;
}

.modal-ordenMaterial{
    background: white;
    padding-bottom: 0px !important;
}

.modal-ordenMaterial .ant-modal-content{
    border-radius: 5px !important;
    padding-bottom: 5px;
}

.modal-ordenMaterial .ant-list-items{
    max-height: 580px !important;
    overflow: auto !important;
}

/*MODAL CARROUSEL DE TRANSACCIONES BELVO*/

.modal-form .btn-carrousel{
    width: 231px;
    height: 38px;
    background: linear-gradient(91.52deg, rgba(0, 71, 255, 0.62) 27.94%, rgba(0, 70, 250, 0.62) 92.2%);
    box-shadow: 0px 10px 30px rgba(15, 19, 26, 0.3);
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
}

.modal-form .btn-carrousel .ant-typography{
    color: white;
    font-weight: bold;
}

.modal-form .btn-carrousel .btn-arrow{
    background: transparent;
    padding-left: 4px;
    padding-right: 4px;
    color: white;
    border: none;
}

.modal-form .btn-carrousel .ant-carousel{
    height: 38px;
    width: 130px;
    text-align: center;
    padding-top: 0.5rem;
}

.pb-w-sync_modal-container{
    z-index: 10000000 !important;
}


.btn-minusModal {
margin-top: 40px !important;    
margin-left: 10px;
}

.btn-minusModal svg{
    color: #FF547D !important;
    width:  20px;
    height: 20px;
    float: right;
    right: 0;
    display: block;
    position: relative;
}

/*--------------------------------------------MODAL REQUESISION DETALLE (CHAT)-----------------------------------------------------*/
.modal-form.modal-detalle-chat{
    max-width: 1000px;
}

.modal-form.modal-detalle-chat .ant-modal-content{
    border-radius: 22px;
}

.modal-form.modal-detalle-chat .ant-modal-body{
    padding: 0;
    min-height: 400px;

}

.modal-form.modal-detalle-chat .chat-side{
   height: 100%;
   border: solid 2px #0246F4;
   border-radius: 0 22px 22px 0;
   overflow: hidden;
}

.modal-form.modal-detalle-chat .chat-side .title-chat{
   background: #0246F4;
   border-radius: 0 15px 0 0;
   padding: 18px 0;
   color:  white;
}

.modal-form.modal-detalle-chat .col-text{
    padding: 2rem;   
}

.modal-form.modal-detalle-chat .col-text .ant-col{
    margin-bottom: 10px !important;   
}

.modal-form.modal-detalle-chat .col-text .tag{
    min-width: 114px;
    height: 34px;
    text-align: center; 
    border-radius: 11px;
    line-height: 33px;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 16px;
}

.modal-form.modal-detalle-chat .col-text .modal-title{
    font-weight: 500;
    font-size: 20px;
}

.modal-form.modal-detalle-chat .col-text .ant-form-item-label{
    margin-bottom: 0px !important;
    padding-bottom: 0px;
}

.modal-form.modal-detalle-chat .col-text .ant-form-item-label label{
    font-weight: 400;
    font-size: 12px;
    color: #696969;
}

.modal-form.modal-detalle-chat .col-text .ant-form-item-control-input-content .ant-typography{
    font-weight: 500;
    font-size: 14px;
   
}
