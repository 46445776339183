.ordenes-dashboard .PM-card-dashboard .PM-card-title{
    margin-bottom: 0.5em;
    margin-top: 0.25em;
}

.ordenes-dashboard .info-card{
    height: 100%;
    box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5);
    border-radius: 10px;
}

.ordenes-dashboard .info-card .ant-card-body{
    padding: 12px;
}


.ordenes-dashboard .title-info-card{
    margin-top: 5% !important;
    text-align: center;
    color: #858997;
}

.ordenes-dashboard .title-info-card.info{
    color: #000000 !important;
}



.ordenes-dashboard .component-list{
    width: 100%;
}

.ordenes-dashboard .title {
    color: #858997;

    margin-top: 1em !important;
    margin-bottom: 1em !important;
}

.ordenes-dashboard .status-tag{
    border-radius: 100px;
}

.project-add-plus{
    background: linear-gradient(126.63deg, #74BCFF 11.98%, #000AFF 83.35%);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.20), 0px 2px 2px rgba(0, 0, 0, 0.15);
}


.project-add-plus svg{
    color: white !important;
}

.dshb-lista-ordenes .ant-list-items{
    max-height: 580px;
    overflow: auto;
}


