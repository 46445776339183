.title-blue
{
    padding: 0;
}


.title-blue,
.title-blue .ant-page-header-heading-title
{
    color: #0047FF !important;
    font-size: 22px !important;
    line-height: 30px !important;
    letter-spacing: 3px !important;
    font-weight: bold;
}

.button-new-material{
    background-color: #0047FF;
    position: relative;
    right: 2em;
    /* left: 2em; */
}

.button-new-material:hover{
    background-color: #0047FFAA;
}

.btn-truck,
.btn-truck:hover,
.btn-truck:focus{
    background: #B454FF;
    padding-top: 3px;
}