.button-syncfy{
    width: 14px!important;
    height: 14px!important;
    min-width: unset !important;
    position: absolute;


}
.button-syncfy .icon {
    font-size: 10px;
    position: relative;
    bottom: 6px;
}

.button-delete-syncfy{
    right: 10px;
    top: 7px;
}

.button-update-syncfy{
    right: 30px;
    top: 7px;
}


.ant-select-selector .button-syncfy {
    display: none;
}

/* .button-delete-syncfy {

} */
